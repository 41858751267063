import React, { useEffect, useState } from "react";
import "./choose.css";
import Layout from '../layout'
import { Link } from "react-router-dom";
import { Form,  Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from 'react-bootstrap/DropdownButton'
import fee1 from "../../assets/images/duel/fee-1.png";
import fee2 from "../../assets/images/duel/fee-2.png";
import fee3 from "../../assets/images/duel/fee-3.png";
import fee4 from "../../assets/images/duel/fee-4.png";
import fee5 from "../../assets/images/duel/fee-5.png";
import fee6 from "../../assets/images/duel/fee-6.png";
import fee7 from "../../assets/images/duel/fee-7.png";
import fee8 from "../../assets/images/duel/fee-8.png";
import {cmsInstance} from '../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
const ChooseGame = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({});
    useEffect(() => {
      
        getContent();
    }, [])
    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/choosegame';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }
    return (

        <div className="choose-game">

            <div className="choose-game-content">

                <h2>{content.heading1}</h2>


                <Form>


                <Form.Group className="max-300">
                    <Form.Label>{content.label1}</Form.Label>
                    <DropdownButton id="choose-game" title="Choose game">
                        <Dropdown.Item href="/">{content.label2}</Dropdown.Item>
                        <Dropdown.Item href="/">{content.label3}</Dropdown.Item>
                        <Dropdown.Item href="/">{content.label4}</Dropdown.Item>
                    </DropdownButton>
                   
                </Form.Group>


                <Form.Group className="clear">
                    <Form.Label className="text-center">{content.label5}</Form.Label>

                    <div className="entry-fee">

                        <div className="entry-box">
                            <img src={fee1} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                        <div className="entry-box">
                            <img src={fee2} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                        <div className="entry-box">
                            <img src={fee3} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                        <div className="entry-box">
                            <img src={fee4} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                        <div className="entry-box">
                            <img src={fee5} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                        <div className="entry-box">
                            <img src={fee6} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                        <div className="entry-box">
                            <img src={fee7} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                        <div className="entry-box">
                            <img src={fee8} alt="fee" />
                            <span>{content.label6}</span>
                        </div>

                    </div>
                   
                </Form.Group>


                <div className="choose-button">
                    <Button type="submit" className="red-btn" >
                    {content.searchuser}
                    </Button>
                    <Button type="submit" className="blue-btn" >
                    {content.choose_friend}
                    </Button>
                </div>



                </Form>

            </div>
            

        </div>


    );
};

export default ChooseGame;