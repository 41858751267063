import React,{useEffect,useState, useRef} from 'react';
import Layout from '../layout';
import queryString from "query-string";
import { socket } from '../../config/socket';
import { userInstance } from '../../axios/axiosconfig';
import Popup from '../home/popup';
let interval ;

const EnterTournament = () => {
    const Userid = localStorage.getItem("Userid");
    const [redirect, setRedirect] = useState(false);
    const [PayloadData,setPayloadData] = useState(null);
    const [timeLeft,setTimeLeft] = useState(null);
    const [title, setTitle] = useState("");
    const [timerdays, setDays] = useState('');
    const [timerhours, setHours] = useState('');
    const [timerminutes, setMinutes] = useState('');
    const [timerseconds, setSeconds] = useState('');
    const [countDouwnDate, setCountDownDate] = useState(null);
    const [tourid, setTourid] = useState(null);
    const [room , setRoom] = useState(null);
    const [delayed, setDelayed] = useState(false);
    const [msg, setMsg] = useState('Sry ! Tournament Already started. You are late');
    const [isLoading, setIsLoading] = useState(false);
    const [game, setGame] = useState('');
    let obj;
    useEffect(()=>{
        let values = getRoomId();
       let data= check(values);
    },[]);

    useEffect(() =>{
      socket.on('tournamentdata', data => {
        console.log('return socket Data:', data);
       const roomLevelOne = data.roomsLevelOne;
          
           roomLevelOne.forEach(room => {
               let findIn = room.players.filter(player => player.toString() === Userid.toString());
               if(findIn.length){
                 setRoom(room._id);
               }
           })
      }) 
      startTimer();
      return () =>{
        clearInterval(interval)
      }
    })
    
    const startTimer = () => {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const milliseconds = Math.abs(now - countDouwnDate).toString()
        const sec = parseInt(milliseconds / 1000);
        const min = parseInt(sec / 60);
        const h = parseInt(min/60);
        const d = parseInt(h/24);
        if((countDouwnDate - now) < 0)
        {
          clearInterval(interval);
          setDelayed(true);

        }
        else if(milliseconds <= 90000 && milliseconds >= 80000)
        {
          let payload ={
            tournamentId: tourid
          }
          console.log("Payload===>", payload);
          socket.emit('setTournamentPlayer', payload); 
        }
        else if(milliseconds>=0 && milliseconds<=30000)
        {
          clearInterval(interval)
         
          RedirectPage();
          socket.emit("tournamentRoomDetail",obj);
        }
        else{
        setSeconds(parseInt(sec%60));
        setMinutes(parseInt(min)%60);
       setHours(parseInt(h%24));
        setDays(parseInt(d%24));
       

        }
      }, 1000)
    }
   const check = async (values) => {
        const res = await userInstance.get(`/tournamentdetails/${values.tour_id}`);
        console.log("checkResponse==>", res, Userid);
        if (res.data.code === 200) {
          let tournamentDate = res.data.data.tournamentStart;
          setGame(res.data.data.gameType)
          if(res.data.data.tournamentStart){
            setMsg("Sry ! Tournament Already started. You are late")
          }
          if(res.data.data.tournamentEnd){
            setMsg("Tournament Finished.");
          }
         setTitle(res.data.data.title)
         
          let start = new Date(tournamentDate).getTime();
          setCountDownDate(new Date(tournamentDate).getTime());
          let currDate = new Date().toISOString();
          let today = new Date(currDate).getTime()
         
          const timediff = start - today;
          const milliseconds = Math.abs(today - start).toString()
          const seconds = parseInt(milliseconds / 1000);
          const minutes = parseInt(seconds / 60);
          const hours = parseInt(minutes / 60);
          const days = parseInt(hours / 24);
          const time = " " +days + " Days :  " + hours % 24 + " hours :  " + minutes % 60 + " Minutes :  " + seconds % 60 +" Seconds";
          console.log("startend",start,today,timediff,milliseconds)
          //  let obj = { timeDiff:timediff,leftTime:time,}
          setTimeLeft(time);
          obj={
            tournamentId:values.tour_id
   
          }
        // if(milliseconds<=599990 && milliseconds>=0 ){
        //     setRedirect(true);
        //     socket.emit("tournamentRoomDetail",obj);
            
        // }
        setIsLoading(true);
        return obj;
       
        } else if(res.data.code === 400){
          localStorage.clear();
          clearInterval(interval);
          window.location.href = `/login`;
        }
    
      }
     
     
     const getRoomId = () => {
       localStorage.setItem('betAmt', 0);
       localStorage.setItem("opponent", 'Opponent');
        const values = queryString.parse(window.location.search);
        console.log("values from query String==>", values);
        setTourid(values.tour_id);
        return values;
      };
      const RedirectPage=()=>{
        console.log("PayloadData",room);
       let payload ={
         tour_id : tourid
       }
        socket.emit('tournamentStarted', payload);
       socket.emit("tournamentMatch", payload);

       if(room){
        window.location.href=`/${game}?roomId=${room}`
       }else{
          setMsg('U have not joined Tournament');
       }
       
      }
    
    return (
      isLoading && (
        <Layout>
            {
               delayed ? 
                <div style={{
                  width:'50vw', 
                  height:'50vh', 
                  margin: 'auto', 
                  marginTop:'200px', 
                  alignItems:'center', 
                  color: 'white'
                  }}>
                    <h3>{msg}</h3>
                </div>
                :<div style={{
                    width:'50vw', 
                    height:'50vh', 
                    margin: 'auto', 
                    marginTop:'200px', 
                    alignItems:'center', 
                    color: 'white'
                    }}>
                  <h3>Tournament  Title : {title}</h3><br/>
                  Time Left  :  {`${timerdays}  Days :  ${timerhours} Hours :  ${timerminutes} Minutes : ${timerseconds} Seconds`}</div>
            }
        </Layout>)
      );
}
 
export default EnterTournament;