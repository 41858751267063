import React, {useState, useEffect} from 'react';
import { languages } from '../../../function/languages';
import AdminWrapper from '../adminwrapper/wrapper';
import {NotificationManager} from 'react-notifications';
import {cmsInstance, userInstance} from "../../../axios/axiosconfig";
import {Modal} from 'react-bootstrap';

const CMSDellanguage = () => {
    const [addedlanguages, setAddlanguages] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [selectlanguage, setSelectLanguage] = useState(null);
    const [showPages, setShowPages] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);
    const [success, setSuccess] = useState(false);
    const [index, setIndex] = useState(null);
    const [warning, setWarning] = useState(false);
    const handleWarningShow = (i) => {
        setIndex(i);
        setWarning(true);
        console.log("warning===>", warning);
    }
    
    const handleWarningClose = () => {setWarning(false)};
    
    let newArr = [];
    useEffect(() => {
        getTotalLanguages();
    }, []);


    const getTotalLanguages = async() => {
        const res = await cmsInstance.get('/getlanguage');
        if(res.data.code === 200){
            res.data.language.forEach((ele) => {
                let data = languages.filter(el => el.code === ele);
                if(data.length){
                    newArr.push(data[0]);
                    console.log(data[0]);
                }
            })
          setAddlanguages(newArr);
          setisLoading(true);
          console.log(res);
        }
      }

      const handleDelete = async(i) => {
          let payload ={
              language : addedlanguages[i].code
          }
        const res = await cmsInstance.post('/deletelanguage', payload);
        if(res.data.code === 200){
            NotificationManager.success('Deleted Successfully','Message',4000);
            getTotalLanguages();
            setWarning(false);
        }else{
            NotificationManager.error('Some Error Occured','Message',4000);
        }
      }

   console.log(addedlanguages);
    return(
        <AdminWrapper>
            <div className="container">
            <div className='row'>
                <table className='table delete-table'>
                    <tr>
                        <th>Code</th>
                        <th>Added Languages</th>
                        <th>Native Name</th>
                        <th>Action</th>
                    </tr>
              {isLoading && addedlanguages.map((ele,i) => {
                  console.log(ele);
                  return(
                      <tr>
                          <td>{ele.code}</td>
                          <td>{ele.name}</td>
                          <td>{ele.nativeName}</td>
                          <td>
                              <button onClick={()=> handleWarningShow(i)}>Delete</button>
                            </td>
                      </tr>
                  )
              })}
                </table>
                   
            </div>
            </div>
            <Modal dialogClassName="dod-popup delete-popup" show={warning} onHide={handleWarningClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                {isLoading ?<div style={{width:'40vw',height:'40vw', margin:'auto', color:'white'}}>
                    <h3 style={{textAlign:'center'}}>Warning</h3>
                    <h6>Are you sure you want to delete. All page content of selected language will be deleted.</h6>
                    <button onClick={()=>handleDelete(index)}>Delete</button>{" "}
                    <button onClick={()=>handleWarningClose}>Cancel</button>
                </div>: ""}
            </Modal.Body>
      </Modal>
        </AdminWrapper>
    )
}

export default CMSDellanguage;