import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import TransactionList from "./components/transaction-list";
import "./style.css";
import { validateData } from '../../../function/function';
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
export default class WithDrawRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      block: false,
      query: '',
      popup:false,
      targetUser:'',
      transactionId:''
    };
  }

  componentDidMount = () => {
    this.getWithdrawalList();
  }

  getWithdrawalList = async (req, res) => {
    const response = await adminInstance.get('/withdrawalList');
    if (response.data.code == 200) {
      console.log("resdata==>", response);
      const list = response.data.list;
      this.setState({
        list
      });
    }
  }

  doPayout = async (userEmail, amount, transactionId,userId) => {
    const payload = {
      userEmail,
      amount,
      _id: transactionId,
      userId: userId
    }
    const response = await adminInstance.post('/payout', payload);
    console.log('response payout', response);
    if(response.data.code===200){
      const {list}=this.state;
      let index=list.findIndex(ele=>ele._id===transactionId);
      console.log(index);
      let arr=[...list];
      arr.splice(index,1);
      this.setState({list:arr});
      this.notify(userId)

    }
  }
  notify=async(userId)=>{
    let notify = {
      userId: userId,
      msg:"your withdrawal request is approved"
    }
    
     const response = await adminInstance.post("/notify", notify);
     if(response.data.code===200){
      NotificationManager.success("Notification Send","Message",4000);
     }
    else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
    
  }
  handleDecline=(id,transactionId)=>{
    console.log(transactionId)
    this.setState({popup:true,targetUser:id,transactionId:transactionId});
  }
  handlePopup=(obj)=>{
    this.setState({popup:false});
    if(obj)
   { const {list,transactionId}=this.state;
    let index=list.findIndex(ele=>ele._id===transactionId);
    console.log(index);
    let arr=[...list];
    arr.splice(index,1);
    this.setState({list:arr});}
  }


  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-12">
              <div className="card__content">
                <div className="card--has-table card">
                  <SectionHeader title="WithDraw Request List" />
                  <TransactionList
                    {...this.state}
                    doPayout={this.doPayout}
                    handleDecline={this.handleDecline}
                    handlePopup={this.handlePopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
