import {useState} from 'react';
import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { adminInstance } from '../../../axios/axiosconfig';
import { NotificationManager } from 'react-notifications';

const PopUP = ({ popup,Userid,handlePopup }) => {
    const [message,setMessage]=useState('');
    const handleChange=(e)=>{
        setMessage(e.target.value);
    }

    const handleSend=async()=>{
        let payload={userId: Userid,
            msg:message};
            console.log("payload==>",payload)
        const response = await adminInstance.post("/notify", payload);
        if(response.data.code===200){
            NotificationManager.success("Message Sent","Message",5000);
        }
        console.log("res==>",response)
       handlePopup(); 
    }

    return (
        <Modal
            size="lg-sm"
            show={popup}>
            <Modal.Header>
                <Modal.Title id="example-custom-modal-styling-title">
                    Reason for Banned
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form>
                        <Form.Group controlId="formBasicloginone">
                            <Form.Label>Enter Message</Form.Label>
                            <Form.Control
                               as="textarea" rows="3"
                                placeholder="Message"
                                name="message"
                                value={message}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                    <button className="btn btn-primary" onClick={()=>handleSend()}>Send</button>
                </div>
            </Modal.Body>
        </Modal>);
}

export default PopUP;