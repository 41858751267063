import React from "react";

export default class CommissionList extends React.Component {
  render() {
    const Item = this.props.list.map((el, i) => {
      return (
        <tr key={i}>
          <td className="game-player-result__date">{el.tournamentStart}</td>
          <td className="game-player-result__vs">{el.title}</td>
          <td className="game-player-result__vs text-center">
           
            <button className="btn-primary-inverse" data-id={el._id} onClick={this.props.handleUpdate.bind(this,el._id)}>
              <i class="fa fa-edit" aria-hidden="true"></i>
            </button>
            {" "}
            <button className="btn-primary-inverse" data-id={el._id} onClick={this.props.handleDelete.bind(this,el._id)}>
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div className="card__content">
        <div className="table-responsive">
          <table className="table table-hover game-player-result">
            <thead>
              <tr>
                <th className="game-player-result__date text-left">Date</th>
                <th className="game-player-result__vs text-left">Title</th>
                <th className="game-player-result__character text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{this.props.list.length !== 0 ? Item : <tr><td colSpan={3}>No data found</td></tr>}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
