import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mins: 0,
      seconds: 0
    };
    this.Times = 0;
  }

  componentDidMount = () => {
    this.startTimer();
  };

  // componentWillUnmount = () => {
  //   clearInterval(this.Timex);
  //   this.setState({
  //     show: false
  //   })
  // }

  startTimer = () => {
    this.Timex = setInterval(() => {
      this.setState({
        seconds: this.state.seconds + 1
      });
      if (this.state.seconds > 59) {
        this.setState({
          seconds: 0
        });
        this.setState({
          mins: this.state.mins + 1
        });
        if (this.state.mins > 4) {
          this.setState({
            mins: 0
          });
        }
      }
    }, 1000);
    return this.Timex;
  };

  cancel = () => {
    clearInterval(this.Timex);
    this.setState({
      mins: 0,
      seconds: 0
    });
    this.props.socket.emit("cancel", {
      Userid: localStorage.getItem("Userid")
    });
    this.props.handleTimerPopup(`close`);
  };

  style = () => {
    return {
      font: "150px",
      margin: "0 auto",
      width: "1000px",
      margin: "0 auto",
      width: "600px",
      font: "24px"
    };
  };

  render() {
    const { t, i18n } = useTranslation();
    return (
      <button id="timer" className="cust14 btn">
        <span id="mins">{this.state.mins} : </span>
        <span id="seconds">{this.state.seconds}</span>
        <h6>{t("timer.minsec")}</h6>
      </button>
    );
  }
}

export default Timer;
