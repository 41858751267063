export const CANVAS = {
    WIDTH: 900,
    HEIGHT: 650,
    FILL: "#ff0000"
  };
  
  export const PADDLE = {
    WIDTH: 20,
    HEIGHT: 100,
    FILL: "#ff0000"
  };
  
  export const CIRCLE = {
    DIAMETER: 24,
    FILL: "#ff0000"
  };