import React from 'react'
import logo from "../../../assets/images/header/logonew.png";
import './style.css'
export default class Footer extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        return(
            <div className="admin_footer">    
            <div className="footer__section">
              <div className="row">
                <div className="footer_section1 col-lg-6 col-sm-6">
                  <img src={logo} alt="outplayed_logo_image" />                  
                </div>
                <div className="footer_section2 col-lg-6 col-sm-6">
                <ul className="footer_copytext">
                <li>Admin panel</li>
              </ul>            
                </div>
            </div>           
          </div>
          </div>
        )
    }
}