import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import TransactionList from "./components/transaction-list";
import "./style.css";
import { validateData } from '../../../function/function';
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      block: false,
      query: '',
      page: 1,
      pagination: 50,
      countTransaction:0
    };
  }

  componentDidMount = () => {
    this.getPayments(1, 50);
  }

  getPayments = async (page, pagination) => {
    this.setState({
      page: page
    })
    const response = await adminInstance.post('/getTransaction',{page: page, pagination: pagination});
    if (response.data.code == 200) {
      const { transaction, countTransaction} = response.data;
      this.setState({
        list: transaction,
        countTransaction: countTransaction
      });
    }
  }

  // handleChange = (e) => {
  //   let { name, value } = e.target;
  //   this.setState({ [name]: value });
  // }

  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-12">
              <div className="card__content">
                <div className="card--has-table card">
                  <SectionHeader title="Payment List" />
                  <TransactionList
                    {...this.state}
                    // handleBlock={this.handleBlock}
                  />
                  <div className="pagination-section">
                    {this.state.page > 1 ? <button onClick={() => this.getPayments(this.state.page - 1, 50)} className="previous">Previous</button> : ''}
                    {this.state.countTransaction > 50 * this.state.page && <button onClick={() => this.getPayments(this.state.page + 1, 50)} className="next">Next</button>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
