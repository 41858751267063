import React from "react";
import AdminLayout from "../layout";
import { InputLabel } from '../input-label'
import SectionHeader from "../section-header";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "./style.css";
import { validateData } from "../../../function/function";
import { adminInstance } from "../../../axios/axiosconfig";
export default class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }
  handlechange = e => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleLogin = async () => {
    const loginPayload = {
      username: this.state.username,
      password: this.state.password
    }
    const isValid = validateData([loginPayload.username, loginPayload.password]);
    if (isValid) {
      const response = await adminInstance.post('/login', loginPayload);
      if (response.data.code !== 200) {
        NotificationManager.error(response.data.msg, 'Message', 4000);
      } else {
        localStorage.setItem('adminToken', response.data.token);
        window.location.href="/admin/dashboard";
      }
    } else {
      NotificationManager.error('Some Fields Empty', 'Message', 4000);
    }
  }

  render() {
    return ( 
      <AdminLayout navpanel={false}>
        <div className="admin_login pd15">
          <div className="row adminloginpageform">
            <div className="col-md-5">
              <SectionHeader title="Admin login" />
              <div className="card">
                <div className="card__content">
                  <InputLabel
                    label={`Username`}
                    id={`username-input-field`}
                    required={true}
                    type={`text`}
                    placeholder={`Username`}
                    name={"username"}
                    value={this.state.username}
                    handlechange={this.handlechange}
                  />
                  <InputLabel
                    label={`Password`}
                    id={`userpassword-input-field`}
                    required={true}
                    type={`password`}
                    placeholder={`*********`}
                    name={"password"}
                    value={this.state.password}
                    handlechange={this.handlechange}
                  />
                  <div className="form-group">
                    <span
                      style={{ cursor: `pointer` }}
                      className="btn btn-primary-inverse btn-lg btn-block"
                      onClick={this.handleLogin}
                    >
                      Login
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
