import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import pool from "../../assets/images/home/pool-game.png";
import pong from "../../assets/images/home/pong-placeholder.png";
import pongmobile from "../../assets/images/home/pong-mobile.png";
import fifa from "../../assets/images/home/wintergame.png";
import manyicon from "../../assets/images/home/many-icon.png";
import duel from "../../assets/images/home/duel.png";
import tournament from "../../assets/images/home/tournament.png";
import newtournament from "../../assets/images/home/new-tournament.png";
import multiplayer from "../../assets/images/home/multiplayer.png";
import tryicon from "../../assets/images/home/try-icon.png";
import ChooseFriend from "../choooseduel/choosefriend";
import ChooseGame from "../choooseduel/choosegame";
import { useTranslation } from "react-i18next";
import appstore from "../../assets/images/home/appstore.png";
import playstore from "../../assets/images/home/playstore.png";
import comingsoon from "../../assets/images/home/coming.gif";
import { server } from "../../config/keys";
import agejumpers from "../../assets/images/home/agejumpers.jpg";
import golf from "../../assets/images/home/golf.jpg";
import skifly from "../../assets/images/home/skifly.jpg";
import bijatyka from "../../assets/images/home/TEMPLATE-SKOKI+ANGRY+Golf+Bijatyka.jpg";
import speedway from "../../assets/images/home/featureGraphic.png";

const PopularGames = ({ content, socket, setBet, setGameType }) => {
    const { t, i18n } = useTranslation();
    const [startTimer, setstartTimer] = useState()
    const [show, setShow] = useState(false);
    const [view, setViewShow] = useState(false);
    const [pcount, setpcount] = useState(0);
    const [wcount, setwcount] = useState(0);

    useEffect(() => {
        socket.emit('onlinePlayer');
        socket.on('playercount', (data) => {
            console.log("count=>",data);
            setpcount(data.pong);
            setwcount(data.winter);
        })
    })
    const handleShow = () => setShow(true);
    const handleViewShow = () => setViewShow(true);

    
    const handleClose = () => setShow(false);
    const handleViewClose = () => setViewShow(false);

    const checkLogin = (game) => {
        if(localStorage.getItem('token')){
            setBet(true);
            setGameType(game);
        }
        else{
            window.location.href='/login';
        }
    }

    
    return (

            <div className="popular-game-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <h2>{content && content.games}</h2>
                            </div>
                        </div>

                        <div className="col-md-3 popular-one">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={pong} alt="pool" />
                                <div className="try" onClick={() => window.location.href = '/pong?roomId=offline game&offline=true'}>
                                        <img src={tryicon} /> <span>{content && content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {pcount}
                                </div>
                               
                                <div className="game-action">
                                    
                                    <div className="duel-action" onClick={()=> checkLogin('Pong')}><img src={duel} /> <span>{content && content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content && content.searchtournament}</span></div>
                                    {/* <div className="multiplayer-action" ><img src={multiplayer} /> <span>Multiplayer </span></div>
                                    */}
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content && content.newtournament} </span></div>
                                    
                                </div>
                                <div className="game-info">
                                <h4>{content && content.game1}</h4>
                                <p>{content && content.para1}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 popular-two">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={pongmobile} alt="pool" />
                                    <div className="try trynew">
                                        <img src={tryicon} />
                                        <div className="mobile-app-link mobile-app-link1 ">
                                            <span>Pobierz na urządzenie mobilne</span>
                                            <div className="mobile-app-img" onClick={() => window.location.href=`${server}/user/appDownload`}>
                                                <img src={playstore} alt="playstore" />
                                            </div>
                                        <div className="mobile-app-img" onClick={() => window.location.href ='https://testflight.apple.com/join/URzIgwRg'}>
                                                <img src={appstore} alt="appstore" />
                                            </div>
                                        </div>
                                         {/* <span>{content && content.try}</span> */}
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {pcount}
                                </div>
                               
                                <div className="game-action mobile-app-tile">
                                    
                                    <div className="duel-action newduel-action" ><img src={duel} /> 
                                    <div className="mobile-app-link">
                                            <span>Pobierz na urządzenie mobilne</span>
                                        <div className="mobile-app-img" onClick={() => window.location.href = `${server}/user/appDownload`}>
                                                <img src={playstore} alt="playstore" />
                                            </div>
                                        <div className="mobile-app-img" onClick={() => window.location.href ='https://testflight.apple.com/join/URzIgwRg'}>
                                                <img src={appstore} alt="appstore" />
                                            </div>
                                        </div>
                                    {/* <span>{content && content.playduel} </span> */}
                                    </div>
                                    <div className="tournament-action newtournament"><img src={tournament} />
                                    <div className="mobile-app-link">
                                            <span>Pobierz na urządzenie mobilne</span>
                                        <div className="mobile-app-img" onClick={() => window.location.href = `${server}/user/appDownload`}>
                                                <img src={playstore} alt="playstore" />
                                            </div>
                                        <div className="mobile-app-img" onClick={() => window.location.href ='https://testflight.apple.com/join/URzIgwRg'}>
                                                <img src={appstore} alt="appstore" />
                                            </div>
                                        </div>
                                     {/* <span>{content && content.searchtournament}</span> */}
                                     </div>
                                     {/* <div className="multiplayer-action" ><img src={multiplayer} />

                                     <div className="mobile-app-link">
                                            <span>Pobierz na urządzenie mobilne</span>
                                            <div className="mobile-app-img" onClick={() => window.location.href='#'}>
                                                <img src={playstore} alt="playstore" />
                                            </div>
                                            <div className="mobile-app-img" onClick={() => window.location.href='#'}>
                                                <img src={appstore} alt="appstore" />
                                            </div>
                                        </div>
                                     </div>
                                    */}
                                    <div className="newtournament-action"><img src={newtournament} />

                                        <div className="mobile-app-link">
                                            <span>Pobierz na urządzenie mobilne</span>
                                        <div className="mobile-app-img" onClick={() => window.location.href = `${server}/user/appDownload`}>
                                                <img src={playstore} alt="playstore" />
                                            </div>
                                        <div className="mobile-app-img" onClick={() => window.location.href ='https://testflight.apple.com/join/URzIgwRg'}>
                                                <img src={appstore} alt="appstore" />
                                            </div>
                                        </div>
                                    
                                    </div>
                                   
                                   

                                </div>

                                <div className="game-info">
                                <h4>{content && content.game2}</h4>
                                <p>{content && content.para2}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 popular-three">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={fifa} alt="pool" />
                                    <div className="try" onClick={() => window.location.href = '/winterGame?roomId=offline game&offline=true'}>
                                        <img src={tryicon} /> <span>{content && content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {wcount}
                                </div>
                               
                                <div className="game-action winter-game">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Winter Game')}><img src={duel} /> <span>{content && content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content && content.searchtournament} </span></div>
                                    <div className="multiplayer-action" ><img src={multiplayer} /> <span>{content && content.multiplayer} </span></div>
                                   
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content && content.newtournament}</span></div>
                                    
                                   
                                </div>
                                <div className="game-info">
                                <h4>{content && content.game3}</h4>
                                <p>{content && content.para3}</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-3 popular-three">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={agejumpers} alt="pool" />
                                    <div className="try" onClick={() => window.location.href = '/winterGame?roomId=offline game&offline=true'}>
                                        <img src={tryicon} /> <span>{content && content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {wcount}
                                </div>
                               
                                <div className="game-action winter-game">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Winter Game')}><img src={duel} /> <span>{content && content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content && content.searchtournament} </span></div>
                                    <div className="multiplayer-action" ><img src={multiplayer} /> <span>{content && content.multiplayer} </span></div>
                                   
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content && content.newtournament}</span></div>
                                    
                                   
                                </div>
                                <div className="game-info">
                                <h4>Age Jumper</h4>
                                <p>Niesamowita gra, w której zręczność łaczy się z wysokimi skokami.</p>
                                </div>
                            </div>
                        </div>
						

                        <div className="col-md-3 popular-three">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={golf} alt="pool" />
                                    <div className="try" onClick={() => window.location.href = '/winterGame?roomId=offline game&offline=true'}>
                                        <img src={tryicon} /> <span>{content && content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {wcount}
                                </div>
                               
                                <div className="game-action winter-game">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Winter Game')}><img src={duel} /> <span>{content && content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content && content.searchtournament} </span></div>
                                    <div className="multiplayer-action" ><img src={multiplayer} /> <span>{content && content.multiplayer} </span></div>
                                   
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content && content.newtournament}</span></div>
                                    
                                   
                                </div>
                                <div className="game-info">
                                <h4>Golf</h4>
                                <p>Zalicz każdą dziurę. W pierwszym podejściu.</p>
                                </div>
                            </div>
                        </div>
						

                        <div className="col-md-3 popular-three">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={skifly} alt="pool" />
                                    <div className="try" onClick={() => window.location.href = '/winterGame?roomId=offline game&offline=true'}>
                                        <img src={tryicon} /> <span>{content && content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {wcount}
                                </div>
                               
                                <div className="game-action winter-game">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Winter Game')}><img src={duel} /> <span>{content && content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content && content.searchtournament} </span></div>
                                    <div className="multiplayer-action" ><img src={multiplayer} /> <span>{content && content.multiplayer} </span></div>
                                   
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content && content.newtournament}</span></div>
                                    
                                   
                                </div>
                                <div className="game-info">
                                <h4>Ski Fly</h4>
                                <p>Poczuj wiatr we włosach i pod nartami. Leć wyżej!</p>
                                </div>
                            </div>
                        </div>
						

                        <div className="col-md-3 popular-three">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={bijatyka} alt="pool" />
                                    <div className="try" onClick={() => window.location.href = '/winterGame?roomId=offline game&offline=true'}>
                                        <img src={tryicon} /> <span>{content && content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {wcount}
                                </div>
                               
                                <div className="game-action winter-game">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Winter Game')}><img src={duel} /> <span>{content && content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content && content.searchtournament} </span></div>
                                    <div className="multiplayer-action" ><img src={multiplayer} /> <span>{content && content.multiplayer} </span></div>
                                   
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content && content.newtournament}</span></div>
                                    
                                   
                                </div>
                                <div className="game-info">
                                <h4>Monster Figther</h4>
                                <p>Największe potwory kryją się w nas samych. Zabij je.</p>
                                </div>
                            </div>
                        </div>
						

                        <div className="col-md-3 popular-three">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={speedway} alt="pool" />
                                    <div className="try" onClick={() => window.location.href = '/winterGame?roomId=offline game&offline=true'}>
                                        <img src={tryicon} /> <span>{content && content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> {wcount}
                                </div>
                               
                                <div className="game-action winter-game">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Winter Game')}><img src={duel} /> <span>{content && content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content && content.searchtournament} </span></div>
                                    <div className="multiplayer-action" ><img src={multiplayer} /> <span>{content && content.multiplayer} </span></div>
                                   
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content && content.newtournament}</span></div>
                                    
                                   
                                </div>
                                <div className="game-info">
                                <h4>Speedway Challenge 22</h4>
                                <p>Jestem szybkością.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                
        <Modal dialogClassName="dod-popup" show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <ChooseFriend  socket={socket}/>
            </Modal.Body>
        </Modal>


        <Modal dialogClassName="dod-popup" show={view} onHide={handleViewClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <ChooseGame  socket={socket}/>
            </Modal.Body>
        </Modal>

            </div>

            

    );
};

export default PopularGames;