import React, { useState, useEffect } from 'react';
import Layout from '../layout';
import {Form,Dropdown, DropdownButton, Spinner} from 'react-bootstrap';
import { validateData } from '../../function/function';
import { userInstance } from "../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
import { server } from '../../config/keys.js';
import { useTranslation } from "react-i18next";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import "./kyc.css";
import {cmsInstance} from '../../axios/axiosconfig';

const INITIAL_STATE = {
    selfi: null,
    document: null,
    idBack:null,
    addressProofImg: null,
    id: null,
    paypalId:'',
    kycid: null,
    firstName:'',
    lastName:'',
    fullAddress:'',
    nation:'',
    exposed:'',
    residence:'',
    dob:'',
    idproof:'',
    addressProof:'',
    dropcountry:'Please Select',
    dropResidence: 'Please Select',
    dropIdProof: 'Please Select',
    dropAddressProof: 'Please Select',
    dropExposed: 'Please Select'
}

const KYC = () => {
    const lang = localStorage.getItem('i18nextLng');
    const { i18n } = useTranslation();
    const [selfipreview, setPreview] = useState(null);
    const [idpreview, setIdPreview] = useState(null);
    const [values, setValues] = useState(INITIAL_STATE);
    const [disabled, setDisabled] = useState(false);
    const [popup, setPopup] = useState(false);
    const [kycstatus, setKycStatus] = useState(false);
    const [update, setUpdate] = useState(false);
    const [reject, setReject] = useState(false);
    const [errSelfi, setErrSelfi] = useState("");
    const [errDocument, setErrDocument] = useState("");
    const[ errAddressProofImg, setErrAddressProofImg] = useState('');
    const [addressProofPreview, setAddressProodPreview] = useState(null);
    const [errIdBack, setErrIdBack] = useState('');
    const [idBackpreview, setIdBackPreview] = useState(null)
    const [errId, setErrId] = useState("");
    const [errPaypalId, setErrPaypalId] = useState("");
    const [validation, setValidation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState({});
    const [errExposed, setErrExposed] = useState('');
    const [errFirstName, setErrFirstName] = useState('');
    const [errLastName, setErrLastName] = useState('');
    const [errNation, setErrNation] = useState('');
    const [errIdProof, setErrIdProof] = useState('');
    const [errDob, setErrDob] = useState('');
    const [errFullAddress, setErrFullAddress] = useState('');
    const [errResidence, setErrResidence] = useState('');
    const [errAddressProof, setErrAddressProof] = useState('');

    useEffect(() => {
        const getKyc = async(userid) => {
            const res = await userInstance.get(`/getKyc/${userid}`);
            console.log('userid',res.data);
            if(res.data.code === 200){
                const {firstName,status,reject,_id,selfiPhoto,idfront,idback,addressProofImg,lastName, idProof, addressProof, fullAddress, country, residence, dob, exposed, paypalId } = res.data.data
                setDisabled(true);
                setPopup(true);
                setKycStatus(status);
                setReject(reject);
                setValues({
                    ...values, 
                    paypalId,
                    selfi: selfiPhoto,
                    id:idfront,
                    idBack:idback,
                    addressProofImg,
                    lastName,
                    firstName,
                    idproof:idProof,
                    addressProof,
                    fullAddress,
                    nation:country,
                    residence,
                    dob,
                    exposed,
                    kycid: _id
                })
                
            }else if(res.data.code === 400){
                window.location.href = '/login';
            }else{
                setDisabled(false);
                setPopup(false);
            }
        }
        const userid = localStorage.getItem('Userid');
        console.log('userid',userid);
       
            getKyc(userid);
       
        getContent();
        i18n.on('languageChanged', onLanguageChanged);
    },[])

    const handleSelect = (value, type) => {
        if(type === 'nation'){
            setValues({
                ...values,
                nation: value.label,
                dropcountry: value
            })
        }else if(type === 'residence'){
            setValues({
                ...values,
                dropResidence:value,
                residence:value.label
            })
        }else if(type === 'exposed'){
            setValues({
                ...values,
                dropExposed:value ? 'Yes': 'No',
                exposed:value
            })
        }else if(type === 'idproof'){
            setValues({
                ...values,
                dropIdProof:value,
                idproof:value
            })
        }else if(type === 'addressProof'){
            setValues({
                ...values,
                dropAddressProof:value,
                addressProof:value
            })
        }
    }

    const onLanguageChanged = () => {
        console.log("language change",localStorage.getItem('activeLanguage'))
        getContent();
       }

    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/kyc';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            setValues({
                ...values,
                dropAddressProof: content.select,
                dropcountry:content.select,
                dropIdProof: content.select,
                dropResidence: content.select,
                dropExposed: content.select
            })
            
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }
    const handleEdit = () => {
        setDisabled(false);
        setPopup(false);
        setUpdate(true);
        console.log('CLicked');
    }
    const handleCancel = () => {
        setDisabled(true);
        setPopup(true);
        setUpdate(false);
        console.log('CLicked');
    }
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }
    const handleUpload = async(event, type) =>{
        try {
            if(event.target.files[0].size > 10485760){
                if(type === 'selfi'){
                    setErrSelfi(content.size);
                }else if(type === 'address'){
                    setErrAddressProofImg(content.size)
                }else if(type === 'front'){
                    setErrId(content.size)
                }else if(type === 'back'){
                    setErrIdBack(content.size)
                }
                
                setValidation(false);
            }else{
            let image_as_base64 = URL.createObjectURL(event.target.files[0])
        if((event.target.files[0].type === "image/jpeg") || (event.target.files[0].type === 'image/jpg') || (event.target.files[0].type === 'image/png')){
            if(type === 'selfi'){
                setPreview(image_as_base64);
                setErrSelfi('');
            }else if(type === 'address'){
                setAddressProodPreview(image_as_base64);
                setErrAddressProofImg('')
            }else if(type === 'front'){
                setIdPreview(image_as_base64);
                setErrId('')
            }else if(type === 'back'){
                setIdBackPreview(image_as_base64);
                setErrIdBack('')
            }
            setValidation(true);
            setValues({
                ...values,
                [event.target.name]: event.target.files[0]
            });
        }else{
            if(type === 'selfi'){
                setErrSelfi(content.errDocument1);
            }else if(type === 'address'){
                setErrAddressProofImg(content.errDocument1)
            }else if(type === 'front'){
                setErrId(content.errDocument1)
            }else if(type === 'back'){
                setErrIdBack(content.errDocument1)
            }
            
            setValidation(false);
        }
    }
         
    } catch (error) {
            
    }
    }
    const handleSubmit = async(e) => {
        e.preventDefault();
      setLoading(true);
       console.log('eee', values)
        // console.log('values', values);
        const isValid = validateData([
            values.selfi,
            values.id,
            values.idBack,
            values.addressProofImg,
            values.paypalId,
            values.firstName,
            values.lastName,
            values.nation,
            values.residence,
            values.idproof,
            values.addressProof,
            values.fullAddress,
            values.dob
        ]);
        if(isValid){
            console.log('eee1',validation)
            setErrPaypalId('');
            setErrPaypalId('');
            var atposition=values.paypalId.indexOf("@");  
            var dotposition=values.paypalId.lastIndexOf(".");  
            if (atposition<1 || dotposition<atposition+2 || dotposition+2>=values.paypalId.length){ 
                
                setErrPaypalId(content.errpaypal1);
                setLoading(false);
                } else {
                    setErrPaypalId("");
            
            if(validation){
                console.log("from helo:", validation);
                var fd= new FormData();
                let data = [values.selfi, values.id, values.document];
                fd.append('file1', values.selfi);
                fd.append('file2', values.id);
                fd.append('file3', values.addressProofImg);
                fd.append('file4', values.idBack);
                fd.append('paypalId', values.paypalId);
                fd.append('dob', values.dob);
                fd.append('firstName', values.firstName);
                fd.append('lastName', values.lastName);
                fd.append('fullAddress', values.fullAddress);
                fd.append('residence', values.residence);
                fd.append('exposed', values.exposed);
                fd.append('idProof', values.idproof);
                fd.append('addressProof', values.addressProof);
                fd.append('country', values.nation)
                const response = await userInstance.post('/saveDocuments', fd);   
                if(response.data.code === 200){
                    setDisabled(true);
                    setPopup(true);
                    setKycStatus(response.data.data.status);
                    setReject(response.data.data.reject);
                    setLoading(false);
                    setValues({
                        ...values,
                        kycid: response.data.data._id
                    })
                    NotificationManager.success(content.notification1, "Message", 10000);
    
                }
                else if(response.data.code === 401){
                    NotificationManager.error(content.notification2, "Message", 10000);
                   setLoading(false);
                }else if(response.data.code === 400){
                    localStorage.removeItem('token');
                    localStorage.removeItem('Userid');
                    localStorage.removeItem('nickName');
                    window.location.href = '/login';
                }
                else{
                    NotificationManager.error(content.notification3, "Message", 10000);
                    setLoading(false);
                }
            }
        }
    
        }else{
            setLoading(false);
            if(values.paypalId === ""){
                setErrPaypalId(content.errpaypal);
              }else {
            var atposition=values.paypalId.indexOf("@");  
            var dotposition=values.paypalId.lastIndexOf(".");  
            if (atposition<1 || dotposition<atposition+2 || dotposition+2>=values.paypalId.length){ 
                
                setErrPaypalId(content.errpaypal1);
                } else {
                    setErrPaypalId("");
                }
            }

            if(values.selfi === null){
                setErrSelfi(content.errSelfi);
            }else{
                setErrSelfi('');
            }
            if(values.id === null){
                setErrId(content.errid);
            }else{
                setErrId('')
            }
            if(values.document === null){
                setErrDocument(content.errDocument);
            }else{
                setErrDocument('')
            }
            if(values.firstName === ''){
                setErrFirstName(content.errFirstName)
            }else{
                setErrFirstName('')
            }
            if(values.lastName === ''){
                setErrLastName(content.errLastName)
            }else{
                setErrLastName('')
            }
            if(values.dob === ''){
                setErrDob(content.errDob)
            }else{
                setErrDob('')
            }         
            if(values.nation === ''){
                setErrNation(content.errNation)
            }else{
                setErrNation('')
            }
            if(values.fullAddress === ''){
                setErrFullAddress(content.errFullAddress)
            }else{
                setErrFullAddress('')
            }
            if(values.residence === ''){
                setErrResidence(content.errResidence)
            }else{
                setErrResidence('')
            }
            if(values.exposed === ''){
                setErrExposed(content.errExposed)
            }else{
                setErrExposed('')
            }
            if(values.idproof === ''){
                setErrIdProof(content.errOption)
            }else{
                setErrIdProof('')
            }
            if(values.addressProof === ''){
                setErrAddressProof(content.errOption)
            }else{
                setErrAddressProof('')
            }

                   
                
        }
        }
    
    const handleUpdate = async(e) => {
        e.preventDefault();
        setLoading(true);
        // console.log('values', values);
        const isValid = validateData([
            values.selfi,
            values.id,
            values.idBack,
            values.addressProofImg,
            values.paypalId,
            values.firstName,
            values.lastName,
            values.nation,
            values.residence,
            values.idproof,
            values.addressProof,
            values.fullAddress,
            values.dob
        ]);
        if(isValid){
            setErrPaypalId('');
            var atposition=values.paypalId.indexOf("@");  
            var dotposition=values.paypalId.lastIndexOf(".");  
            if (atposition<1 || dotposition<atposition+2 || dotposition+2>=values.paypalId.length){ 
                
                setErrPaypalId(content.errpaypal1);
                setLoading(false);
                } else {
                    setErrPaypalId("");
            var fd= new FormData();
            let data = [values.selfi, values.id, values.document];
            fd.append('file1', values.selfi);
            fd.append('file2', values.id);
            fd.append('file3', values.addressProofImg);
            fd.append('file4', values.idBack);
            fd.append('paypalId', values.paypalId);
            fd.append('dob', values.dob);
            fd.append('firstName', values.firstName);
            fd.append('lastName', values.lastName);
            fd.append('fullAddress', values.fullAddress);
            fd.append('residence', values.residence);
            fd.append('exposed', values.exposed);
            fd.append('idProof', values.idproof);
            fd.append('addressProof', values.addressProof);
            fd.append('country', values.nation);
            fd.append('kycId', values.kycid)
            const response = await userInstance.post('/updateDocument', fd);
            if(response.data.code === 200){
                setDisabled(true);
                setPopup(true);
                setKycStatus(response.data.data.status);
                setReject(response.data.data.reject);
                setLoading(false);
                NotificationManager.success(content.notification4, "Message", 10000);
            }
            else if(response.data.code === 401){
                NotificationManager.error(content.notification5, "Message", 10000);
                setLoading(false);
            }else if(response.data.code === 400){
                localStorage.removeItem('token');
                localStorage.removeItem('Userid');
                localStorage.removeItem('nickName');
                window.location.href = '/login';
            }
            else{
                NotificationManager.error(content.notification3, "Message", 10000);
                setLoading(false);
            }
                }

    }else{
        setLoading(false);
        if(values.paypalId === ""){
            setErrPaypalId(content.errpaypal);
          }else {
        var atposition=values.paypalId.indexOf("@");  
        var dotposition=values.paypalId.lastIndexOf(".");  
        if (atposition<1 || dotposition<atposition+2 || dotposition+2>=values.paypalId.length){ 
            
            setErrPaypalId(content.errpaypal1);
            } else {
                setErrPaypalId("");
            }
        }
        if(values.selfi === null){
            setErrSelfi(content.errSelfi);
        }else{
            setErrSelfi('');
        }
        if(values.id === null){
            setErrId(content.errid);
        }else{
            setErrId('')
        }
        if(values.document === null){
            setErrDocument(content.errDocument);
        }else{
            setErrDocument('')
        }
        if(values.firstName === ''){
            setErrFirstName(content.errFirstName)
        }else{
            setErrFirstName('')
        }
        if(values.lastName === ''){
            setErrLastName(content.errLastName)
        }else{
            setErrLastName('')
        }
        if(values.dob === ''){
            setErrDob(content.errDob)
        }else{
            setErrDob('')
        }         
        if(values.nation === ''){
            setErrNation(content.errNation)
        }else{
            setErrNation('')
        }
        if(values.fullAddress === ''){
            setErrFullAddress(content.errFullAddress)
        }else{
            setErrFullAddress('')
        }
        if(values.residence === ''){
            setErrResidence(content.errResidence)
        }else{
            setErrResidence('')
        }
        if(values.exposed === ''){
            setErrExposed(content.errExposed)
        }else{
            setErrExposed('')
        }
        if(values.idproof === ''){
            setErrIdProof(content.errOption)
        }else{
            setErrIdProof('')
        }
        if(values.addressProof === ''){
            setErrAddressProof(content.errOption)
        }else{
            setErrAddressProof('')
        }      
    }
        
    }

    return (
       
        <Layout>
           
        <section className={`tournament-page kyc-bg kyc-page ${lang}`}>
           <div className="container">


               <div className="row">
                        <div className="col-md-12">
                            <div className="title-page kyc-title">
                                <ul className="breadcrum">
                                    <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.kyc}</li>
                                </ul>
                                <h2>{content.kyc}</h2>
                            </div>
                        </div>
                    </div>


                        <div className="kyc-content">
                        {popup &&(
                            <div className="kyc-message">
                                    {reject ? <p>{content.status1}
                                        <Button className="blue-btn" type='button' onClick={handleEdit}>{content.edit}</Button></p> 
                                        : kycstatus? <p>{content.status2}</p>: 
                                        <p>{content.status3} 
                                            <Button className="blue-btn" type='button' onClick={handleEdit}>{content.edit}</Button></p>}
                            </div> 
                        )}
                       
                        {!kycstatus && (<Form onSubmit={handleSubmit} encType='multipart/form-data' disabled={disabled}>
                
                            <div className="row">
                                <div className="personal-detail">
                                    <h5>{content.personal}</h5>
                                    <p>{content.warningMsg}</p>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group controlId="firstname">
                                        <Form.Label>{content.firstName}</Form.Label>
                                        {errFirstName === '' ?
                                            <Form.Control
                                                type="text"
                                                name="firstName"
                                                onChange={handleChange}
                                                value={values.firstName}
                                                disabled={disabled} />
                                            : <>
                                                <Form.Control
                                                    type="text"
                                                    name="firstName"
                                                    onChange={handleChange}
                                                    value={values.firstName}
                                                    disabled={disabled} />
                                                <Form.Label style={{ color: 'red' }}>{errFirstName}</Form.Label></>
                                        }
                                    </Form.Group>

                                </div>
                                <div className="col-md-6">
                                    <Form.Group controlId="lastName">
                                        <Form.Label>{content.lastName}</Form.Label>
                                        {errLastName === '' ?
                                            <Form.Control
                                                type="text"
                                                name="lastName"
                                                onChange={handleChange}
                                                value={values.lastName}
                                                disabled={disabled} />
                                            : <>
                                                <Form.Control
                                                    type="text"
                                                    name="lastName"
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                    disabled={disabled} />
                                                <Form.Label style={{ color: 'red' }}>{errLastName}</Form.Label></>
                                        }
                                    </Form.Group>

                                </div>
                                <div className="col-md-6">
                                    <Form.Group controlId="dob">
                                        <Form.Label>{content.dob}</Form.Label>
                                        {errDob === '' ?
                                            <Form.Control
                                                type="date"
                                                name="dob"
                                                onChange={handleChange}
                                                value={values.dob}
                                                disabled={disabled} />
                                            : <>
                                                <Form.Control
                                                    type="date"
                                                    name="dob"
                                                    onChange={handleChange}
                                                    value={values.dob}
                                                    disabled={disabled} />
                                                <Form.Label style={{ color: 'red' }}>{errDob}</Form.Label></>
                                        }
                                    </Form.Group>

                                </div>
                                <div className="col-md-6">
                                    <Form.Group controlId="nation">
                                        <Form.Label>{content.nation}</Form.Label>
                                        {errNation === '' ?
                                            <Select
                                                options={countryList().getData()}
                                                value={values.dropcountry}
                                                onChange={(value) => handleSelect(value, 'nation')}
                                                className="country-list"
                                            />
                   
                                            : <>
                                                <Select
                                                    options={countryList().getData()}
                                                    value={values.dropcountry}
                                                    onChange={(value) => handleSelect(value, 'nation')}
                                                />
                                                <Form.Label style={{ color: 'red' }}>{errNation}</Form.Label></>
                                        }
                                    </Form.Group>

                                </div>
                                <div className="col-md-12">
                                    <Form.Group controlId="fullAddress">
                                        <Form.Label>{content.fullAddress}</Form.Label>
                                        {errFullAddress === '' ?
                                            <Form.Control
                                                type="textarea"
                                                name="fullAddress"
                                                onChange={handleChange}
                                                value={values.fullAddress}
                                                disabled={disabled} />
                                            : <>
                                                <Form.Control
                                                    type="testarea"
                                                    name="fullAddress"
                                                    onChange={handleChange}
                                                    value={values.fullAddress}
                                                    disabled={disabled} />
                                                <Form.Label style={{ color: 'red' }}>{errFullAddress}</Form.Label></>
                                        }
                                    </Form.Group>

                                </div>
                                <div className="col-md-6">
                                    <Form.Group controlId="residence">
                                        <Form.Label>{content.residence}</Form.Label>
                                        {errResidence === '' ?
                                            <Select
                                                options={countryList().getData()}
                                                value={values.dropResidence}
                                                onChange={(value) => handleSelect(value, 'residence')}
                                                className="country-list"
                                            />
                                            : <>
                                                <Select
                                                    options={countryList().getData()}
                                                    value={values.dropResidence}
                                                    onChange={(value) => handleSelect(value, 'residence')}
                                                />
                                                <Form.Label style={{ color: 'red' }}>{errResidence}</Form.Label></>
                                        }
                                    </Form.Group>

                                </div>
                                <div className="col-md-6">
                                    <div className="exposed">
                                        <Form.Group controlId="exposed">
                                            <Form.Label>{content.exposed}</Form.Label>
                                            {errExposed === '' ?
                                                <DropdownButton id="formBasicCountry" title={values.dropExposed}>
                                                    <Dropdown.Item onClick={() => handleSelect(true, 'exposed')}>Yes</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelect(false, 'exposed')}>No</Dropdown.Item>
                                                </DropdownButton>
                                                : <>
                                                    <DropdownButton id="formBasicCountry" title={values.dropExposed}>
                                                        <Dropdown.Item onClick={() => handleSelect(true, 'exposed')}>Yes</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleSelect(false, 'exposed')}>No</Dropdown.Item>
                                                    </DropdownButton>
                                                    <Form.Label style={{ color: 'red' }}>{errExposed}</Form.Label></>
                                            }
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group controlId="paypalId">
                                        <Form.Label>{content.paypal}</Form.Label>
                                        {errPaypalId === '' ?
                                            <Form.Control
                                                type="text"
                                                name="paypalId"
                                                onChange={handleChange}
                                                value={values.paypalId}
                                                disabled={disabled} />
                                            : <>
                                                <Form.Control
                                                    type="text"
                                                    name="paypalId"
                                                    onChange={handleChange}
                                                    value={values.paypalId}
                                                    disabled={disabled} />
                                                <Form.Label style={{ color: 'red' }}>{errPaypalId}</Form.Label></>
                                        }
                                    </Form.Group>

                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className="personal-detail">
                                    <h5>{content.document}</h5>
                                    <p>{content.note}</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="idproof">
                                        <Form.Group controlId="idproof">
                                            <Form.Label>{content.idproof}</Form.Label>
                                            {errIdProof === '' ?
                                                <DropdownButton id="formBasicCountry" title={values.dropIdProof}>
                                                    <Dropdown.Item onClick={() => handleSelect('Id Card', 'idproof')}>ID Card</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelect('Driving License', 'idproof')}>Driving License</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelect('Passport', 'idproof')}>Passport</Dropdown.Item>
                                                </DropdownButton>
                                                : <>
                                                    <DropdownButton id="formBasicCountry" title={values.dropIdProof}>
                                                        <Dropdown.Item onClick={() => handleSelect('Id Card', 'idproof')}>ID Card</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleSelect('Driving License', 'idproof')}>Driving License</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleSelect('Passport', 'idproof')}>Passport</Dropdown.Item>
                                                    </DropdownButton>
                                                    <Form.Label style={{ color: 'red' }}>{errIdProof}</Form.Label></>
                                            }
                                        </Form.Group>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="addressproof">
                                        <Form.Group controlId="addressProof">
                                            <Form.Label>{content.addressProof}</Form.Label>
                                            {errAddressProof === '' ?
                                                <DropdownButton id="formBasicCountry" title={values.dropAddressProof}>
                                                    <Dropdown.Item onClick={() => handleSelect('Utility Bill', 'addressProof')}>Utility Bill</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelect('Bank Statement', 'addressProof')}>Bank Statement</Dropdown.Item>
                                                </DropdownButton>
                                                : <>
                                                    <DropdownButton id="formBasicCountry" title={values.dropAddressProof}>
                                                        <Dropdown.Item onClick={() => handleSelect('Utility Bill', 'addressProof')}>Utility Bill</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleSelect('Bank Statement', 'addressProof')}>Bank Statement</Dropdown.Item>
                                                    </DropdownButton>
                                                    <Form.Label style={{ color: 'red' }}>{errAddressProof}</Form.Label></>
                                            }
                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                            {values.idproof !== '' && values.addressProof !== '' && (
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="address-img">
                                            <Form.Group controlId="bankName">
                                                <Form.Label>{content.addressProof}</Form.Label>
                                                {errAddressProofImg === '' ? <>
                                                    <Form.Control
                                                        type="file"
                                                        name="addressProofImg"
                                                        onChange={(e) => { handleUpload(e, 'address') }}
                                                        accept='image/*'
                                                        disabled={disabled} />
                                                </> : <>
                                                    <Form.Control
                                                        type="file"
                                                        name="addressProofImg"
                                                        onChange={(e) => { handleUpload(e, 'address') }}
                                                        accept='image/*'
                                                        disabled={disabled} />
                                                    <Form.Label style={{ color: 'red' }}>{errAddressProofImg}</Form.Label></>
                                                }
                                                {addressProofPreview ? <img src={addressProofPreview} /> : values.addressProofImg ? <img className="kyc-img" src={`${server}/` + values.addressProofImg} /> : ""}
                                            </Form.Group>
                                        </div>

                                        <div className="idproof-img">
                                            <Form.Group controlId="bankName">
                                                <Form.Label>{content.selfi}</Form.Label>
                                                {errSelfi === '' ? <>
                                                    <Form.Control
                                                        type="file"
                                                        name="selfi"
                                                        onChange={(e) => { handleUpload(e, 'selfi') }}
                                                        accept='image/*'
                                                        disabled={disabled} /></>
                                                    : <>
                                                        <Form.Control
                                                            type="file"
                                                            name="selfi"
                                                            onChange={(e) => { handleUpload(e, 'selfi') }}
                                                            accept='image/*'
                                                            disabled={disabled} />
                                                        <Form.Label style={{ color: 'red' }}>{errSelfi}</Form.Label></>
                                                }
                                                {selfipreview ? <img src={selfipreview} /> : values.selfi ? <img className="kyc-img" src={`${server}/` + values.selfi} /> : ""}
                                            </Form.Group>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className="address-img">
                                            <Form.Group controlId="bankName">
                                                <Form.Label>{values.idproof + "(" + content.front + ")"}</Form.Label>
                                                {errId === '' ? <>
                                                    <Form.Control
                                                        type="file"
                                                        name="id"
                                                        onChange={(e) => { handleUpload(e, 'front') }}
                                                        accept='image/*'
                                                        disabled={disabled} />
                                                </>
                                                    :
                                                    <>
                                                        <Form.Control
                                                            type="file"
                                                            name="id"
                                                            onChange={(e) => { handleUpload(e, 'front') }}
                                                            accept='image/*'
                                                            disabled={disabled} />
                                                        <Form.Label style={{ color: 'red' }}>{errId}</Form.Label>
                                                    </>
                                                }
                                                {idpreview ? <img src={idpreview} /> : values.id ? <img className="kyc-img" src={`${server}/` + values.id} /> : ""}
                                            </Form.Group>
                                        </div>
                                        <div className="idproof-img">
                                            {values.idproof !== 'Passport' && (
                                                <Form.Group controlId="bankName">
                                                    <Form.Label>{values.idproof + "(" + content.back + ")"}</Form.Label>
                                                    {errIdBack === '' ? <>
                                                        <Form.Control
                                                            type="file"
                                                            name="idBack"
                                                            onChange={(e) => { handleUpload(e, 'back') }}
                                                            accept='image/*'
                                                            disabled={disabled} />
                                                    </>
                                                        :
                                                        <>
                                                            <Form.Control
                                                                type="file"
                                                                name="idBack"
                                                                onChange={(e) => { handleUpload(e, 'back') }}
                                                                accept='image/*'
                                                                disabled={disabled} />
                                                            <Form.Label style={{ color: 'red' }}>{errIdBack}</Form.Label>
                                                        </>
                                                    }
                                                    {idBackpreview ? <img src={idBackpreview} /> : values.idBack ? <img className="kyc-img" src={`${server}/` + values.idBack} /> : ""}
                                                </Form.Group>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="col-md-12">
                                <div class="text-center">
                                    {!update ? <>
                                        {disabled ? "" : <Button type="button" className="blue-btn" disabled={disabled} onClick={handleSubmit}>{loading ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            /> :
                                            ''}{content.submit}</Button>}</> :
                                        <>
                                            {disabled ? "" : <Button type="button" className="blue-btn" disabled={disabled} onClick={handleUpdate}> {loading ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /> :
                                                ''}{content.update}</Button>
                                            }{"  "}
                                            {disabled ? "" : <Button type="button" className="red-btn" disabled={disabled} onClick={handleCancel}>{content.cancel}</Button>}</>}
                                </div>
                            </div>

       

                

                        </Form>)}
           
                                
                        </div>
                     </div>
       
                    </section>

            </Layout>
    );
}

export default KYC;