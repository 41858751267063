import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import UserList from "./components/user-list";
import UserSearch from "./components/user-search";
import "./style.css";
import { validateData } from '../../../function/function';
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
import { Modal } from "react-bootstrap";
import PopUP from "./popUp";
export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      fullList:"",
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      status: false,
      query: '',
      filter:"nickName",
      popup:false,
      currentkyc:'',

    };
  }

  componentDidMount = () => {
    this.getKyc();
  }

  getKyc = async (req, res) => {
    
    const response = await adminInstance.post('/getkyc');
    if (response.data.code == 200) {
      const kyc = response.data.data;
      console.log(kyc);
      this.setState({
        list: kyc,fullList:kyc
      });
      console.log(kyc);
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handlePopup = (i)=>{
    console.log("from Handle Popup")
    console.log(this.state.list[i])
    this.setState({popup:!this.state.popup, currentkyc: this.state.list[i]})
  }
  handleVerify = async (id, status, i) => {
    console.log("from HandleVerify")
    const payload = {
      id: id,
      status: status,
      query:this.state.query
    }
    const response = await adminInstance.post('/verifyKyc', payload);
    if (response.data.code == 200) {
      NotificationManager.success("Activity Success", "Message", 4000);
      this.setState({
        list: response.data.data,
        fullList:response.data.data
      });
      this.notify(status, false, i);
    } else {
      NotificationManager.error(response.data.msg, "Message", 4000);
    }
  }

  handleReject = async (i) => {
    console.log("from HandleVerify")
    const payload = {
      id: this.state.list[i].kyc._id,
      query:this.state.query
    }
    const response = await adminInstance.post('/rejectKyc', payload);
    if (response.data.code == 200) {
      NotificationManager.success("Activity Success", "Message", 4000);
      this.setState({
        list: response.data.data,
        fullList: response.data.data
      });
      this.notify(false,true, i);
    } else {
      NotificationManager.error(response.data.msg, "Message", 4000);
    }
  }
  
  notify = async (status,reject, i) => {
    let payload;
    if(reject){
      payload = {
        userId: this.state.list[i]._id,
        msg: 'Your Kyc verification request rejected'
      }
    }
    else if(!status){
      payload={
        userId: this.state.list[i]._id,
        msg: 'Your KYC Module now verified'
      }
    }else{
      payload={
        userId: this.state.list[i]._id,
        msg: 'Your KYC Module is Under verification'
      }
    }
    const res = await adminInstance.post('/notify', payload);
  }

  handleSearch = async (e) => {
     this.setState({
      query: e.target.value
    });
   let length= e.target.value.length;
    if(this.state.filter==="nickName"){
      let arr = this.state.fullList.filter(ele=>ele.nickName.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
      this.setState({list:arr});
    }
    if(this.state.filter==="email"){
      let arr = this.state.fullList.filter(ele=>ele.email.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
      this.setState({list:arr}); 
    }
    if(this.state.filter==="country"){
      let arr = this.state.fullList.filter(ele=>ele.country.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
      this.setState({list:arr}); 
    }
    if( this.state.query===""){
      this.setState({list:this.state.fullList});
    }
   
    // let query = this.state.query;
    // const payload = {
    //   query: e.target.value
    // };
    // const response = await adminInstance.post('/searchUser', payload);
    // console.log(response);
    // if (response.status == 200) {
    //   this.setState({
    //     list: response.data.users
    //   });
    // } else {
    //   NotificationManager.error(response.data.msg, "Message", 4000);
    // };
  }
  handleFilter=(e)=>{
      this.setState({filter:e.target.value});
  }

  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-12">
              <div className="card__content">
                {/* <div className="card--has-table card">
                  <SectionHeader title="User Search" />
                  <UserSearch
                    {...this.state}
                    handleSearch={this.handleSearch}
                  />
                </div> */}
                <div className="card--has-table card">
                  <SectionHeader title="KYC List"
                   query={this.state.query}
                   filter={this.state.filter}
                   handleFilter={this.handleFilter}
                    handleSearch={this.handleSearch} />
                  <UserList
                    {...this.state}
                    handleVerify={this.handleVerify}
                    handlePopup={this.handlePopup}
                    handleReject={this.handleReject}
                  />
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
