import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import tile1 from "../../assets/images/home/tile-11.png";

var settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const HometileSlider = ({content}) => {
    const { t, i18n } = useTranslation();
  return (
    <div className="home-tile-slider">
      <Slider {...settings}>
        <div className="home-slider-image tile-1">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.now}</span>
            <h4>{content && content.takefree}</h4>
            <h2>{content && content.takeprice}</h2>
          
          </div>
        </div>
        <div className="home-slider-image tile-1">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.now}</span>
            <h4>{content && content.takefree}</h4>
            <h2>{content && content.takeprice}</h2>
            
          </div>
        </div>
        <div className="home-slider-image tile-1">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.now}</span>
            <h4>{content && content.takefree}</h4>
            <h2>{content && content.takeprice}</h2>
           
          </div>
        </div>
        <div className="home-slider-image tile-1">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.now}</span>
            <h4>{content && content.takefree}</h4>
            <h2>{content && content.takeprice}</h2>
           
          </div>
        </div>
      </Slider>
    </div>
  );
};
export default HometileSlider;
