import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import "./style.css";
import { validateData } from '../../../function/function';

import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
import queryString from "query-string";

export default class Notify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{ id: `hjh` }],
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      message: ""
    };
  }

  componentDidMount = () => { }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  sendNotification = async (e) => {
    const values = queryString.parse(window.location.search);
    let payload ;
    if(values.userId){
    payload= {
      msg: this.state.message,
      userId:values.userId
    };}
    else{
      payload= {
        msg: this.state.message,
        userId:"boradcast"
      };
    }
    let notify = {
      userId: values.userId,
      msg:payload.msg
    }

    const isValid = validateData([
      payload.msg
    ]);

    if (isValid) {
      const { socket } = this.props;
      socket.emit('notification', payload);
      this.setState({
        message: ""
      });
      const response = await adminInstance.post("/notify", notify);
      if(response.data.code === 200)
      {
        NotificationManager.success("Message Send!", "Message", 4000);
      }
    } else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
  }

  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-6">
              <div className="card__content">
                <SectionHeader title="Send Notification" />
                <div className="card">
                  <div className="card__content">
                    <TextareaLabel
                      label={`Message`}
                      id={`title-input-field`}
                      required={true}
                      type={`text`}
                      placeholder={`Please Enter Notification Message`}
                      name={"message"}
                      value={this.state.message}
                      handlechange={this.handleChange}
                    />
                    <div className="form-group mb-0 text-right">
                      <button className="btn btn-primary-inverse" onClick={this.sendNotification}>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
