import React, { useEffect, useState } from "react";
import "./style.css";
import Layout from '../layout'
import mac from "../../assets/images/home/mac.png";
import macb from "../../assets/images/home/macb.png";
import playstation from "../../assets/images/home/playstation.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cmsInstance } from '../../axios/axiosconfig';
import { NotificationManager } from 'react-notifications';


const OurVision = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({});

    const getContent = async () => {
        try {
            const page = '/ourvision'
            let language = i18n.language;
            const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
            if (status == 200) {
                setContent(content);
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        getContent();
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const onLanguageChanged = () => {
        getContent();
    }

    return (


        <Layout>

            <div className="default-section">
            <div className="container">


                
            <div className="row">
                        <div className="col-md-12">
                            <div className="title-page vision-title">
                                <ul className="breadcrum">
                                    <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.ourvision}</li>
                                </ul>
                                <h2>{content.ourvision}</h2>
                            </div>
                        </div>
                    </div>

                    <div class="f-content">
                    <div className="row">

                        <div className="col-md-6">
                            <div className="feature-descp">
                                <h4>{content.ourvision}</h4>
                                <p>{content.para1}</p>
                                {/* <ul className="feature-list">
                                    <li>{content.para3}</li>
                                    <li>{content.para3}</li>
                                    <li>{content.para3}</li>

                                </ul> */}
                            </div>

                        </div>
                        <div className="col-md-6">


                            <img className="feature-img" src={mac} />

                        </div>
                    </div>


                </div>


                <div class="f-content">
                    <div className="row">


                        <div className="col-md-6">


                            <img className="feature-img" src={playstation} />

                        </div>

                        <div className="col-md-6">
                            <div className="feature-descp">
                                <h4>{content.ourvision}</h4>
                                <p>{content.para2}</p>
                                {/* <ul className="feature-list">
                                    <li>{content.para3}</li>
                                    <li>{content.para3}</li>
                                    <li>{content.para3}</li>

                                </ul> */}
                            </div>

                        </div>


                    </div>
                </div>









            </div>


            </div>




        </Layout>



























    );
};

export default OurVision;