import React from "react"
import {Route, withRouter} from "react-router-dom"
import './theme.css'
import "./style.css"
class Admin extends React.Component {
  constructor(props) {
    super(props);
   
  }


  render() {
    return (
      <div>
      {this.props.children}
      </div>
    )
  }
}
export default withRouter(Admin)