import React from "react";
import {InputLabel } from '../../input-label/index';
import './user-list.css';

export default class UserSearch extends React.Component {
    render() {
        return (
            <div className="card__content">
                <InputLabel
                    label={``}
                    id={`title-input-field`}
                    required={true}
                    type={`text`}
                    placeholder={`Please enter user nickname`}
                    name={"query"}
                    value={this.props.query}
                    handlechange={this.props.handleSearch}
                />
            </div>
        );
    }
}
