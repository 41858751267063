import React, { useEffect, useState } from "react";
import "../admin.css";
import AdminHeader from "../adminheader/header";
import AdminMenu from "../adminmenu/menu";
import Helmet from 'react-helmet'


const AdminWrapper = (props) => {

const [menutoggle, setMenuToggle] = useState(false);

    return (
        <div className="admin-wrapper">
            <Helmet>
                <body className="admin-view"/>
            </Helmet> 

          
            <div className="admin-content-view">

                <AdminMenu menutoggle={menutoggle}/>

                <div className={`admin-container ${menutoggle ? `wrapper-expand`:``}` }>
                    <div className="header-view">
                        <AdminHeader setMenuToggle={setMenuToggle} menutoggle={menutoggle}/>
                    </div>

                    <div className="admin-content"> 
                        {props.children}
                    </div>
                </div>

            </div>
   
        </div>
    );
  };
  
export default AdminWrapper;
