import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import {socket} from "../../../config/socket";
import { NotificationManager } from "react-notifications";
export default class NavigationPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activenav: window.location.pathname
        }
        socket.on('kyc', (data) => {
            NotificationManager.success(`New KYC Details Added`, "Message", 10000);
        })
    }

    handleActiveNav = () => {
        this.setState({ activenav: window.location.pathname })
    }
    render() {
        if (this.props.show) {
            return (
                <div className={`admin_left_side_nav ${this.props.menu ? `active` : ``}`}>
                    <nav className="side-menu navbar navbar-expand navbar-light">
                        <div className="navbar-collapse collapse">
                            <ul className="admin_nav navbar-nav">
                                <NavItem
                                    to="/admin/dashboard"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-ticket"></i>
                                    <span>Dashboard</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/tournament"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-gamepad"></i>
                                    <span>Tournament</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/user"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-user"></i>
                                    <span>User</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/commission"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-percent"></i>
                                    <span>Commission</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/turnover-limit"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-money"></i>
                                    <span>Bonus and Turnover</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/transaction"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-euro"></i>
                                    <span>Transaction</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/matches"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-gamepad"></i>
                                    <span>Matches</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/notify"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-bell"></i>
                                    <span>Notify</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/withdrawRequestList"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-exchange"></i>
                                    <span>Withdraw-Requests</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/kyc"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-list"></i>
                                    <span>KYC</span>
                                </NavItem>
                                <NavItem
                                    to="/admin/user-chat"
                                    active={this.state.activenav}
                                    onClick={this.handleActiveNav}
                                >
                                    <i className="fa fa-comment"></i>
                                    <span>Admin User Chat</span>
                                </NavItem>
                            </ul>
                        </div>
                    </nav>
                </div>
            )
        } else {
            return null
        }
    }
}


const NavItem = ({ to, active, children, onClick }) => {
    return (
        <li className="nav-item">
            <div className={to === active ? 'nav-link active' : `nav-link`}>
                <Link to={to} onClick={onClick}>
                    {children}
                </Link>
            </div>
        </li>
    )
}