import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home/home";
import Login from "./components/login/login";
import Signup from "./components/signup/signup";
import Forgetpass from "./components/forget/forget";
import Resetpassword from "./components/forget/reset";
import Admin from "./components/admin";
import history from "./config/history";
import AdminLogin from "./components/admin/login";
import Dashboard from "./components/admin/dashboard";
import Tournament from "./components/admin/tournament";
import Commission from "./components/admin/commission";
import Turnover from "./components/admin/turnover-limit";
import Transaction from "./components/admin/transaction";
import Matches from "./components/admin/online-matches";
import WithdrawRequest from "./components/admin/withdraw-requestlist";
import User from "./components/admin/user";
import Notify from "./components/admin/notify";
import Legal from "./components/legal/legal";
import Terms from "./components/legal/terms";
import Privacy from "./components/legal/privacy";
import Faqs from "./components/faqs/faqs";
import Howitworks from "./components/howitworks/howitworks";
import TournamentPage from "./components/tournament/tournamentpage";
import Runningduels from "./components/runningduels/runningduels";
import Features from "./components/features/features";
import Populargames from "./components/populargames/populargames";
import Profile from "./components/profile/profile";
import AboutUs from "./components/aboutus/aboutus";
import EditProfile from "./components/editprofile/editprofile";
import OurTeam from "./components/ourteam/ourteam";
import OurVision from "./components/ourvision/ourvision";
import Help from "./components/help/help";
import Payment from "./components/payments/payment";
import TransactionSecurity from "./components/transactionsecurity/transactionsecurity";
import ResponsibleGaming from "./components/responsiblegaming/responsiblegaming";
import Deposit from "./components/deposit/deposit";
import Tournamentdetails from "./components/tournamentdetails/tournamentdetails";
import Notificationpage from "./components/notificationpage/notificationpage";
import UserTransaction from "./components/transaction/transaction";
import WithdrawMoney from "./components/withdrawMoney/withdrawMoney";
import Friend from './components/friend/index';

import AdminCmsLogin from "./components/cms/adminlogin/login";
import AdminDashboard from "./components/cms/admindashboard/dashboard";
import CMSaboutUs from './components/cms/cms-about'
import CMSourteam from './components/cms/cms-ourteam';
import CMSourvision from './components/cms/cms-ourvision';
import CMSresponsiblegaming from './components/cms/cms-responsiblegaming';
import CMSlegal from './components/cms/cms-legalnotice';
import CMSterms from './components/cms/cms-terms';
import CMSprivacy from './components/cms/cms-privacy';
import CMSsecurity from './components/cms/cms-transactionsecurity';
import CMSpayments from './components/cms/cms-payments';
import CMShelp from './components/cms/cms-help';
import CMSfaqs from './components/cms/cms-faqs';
import CMShowitworks from './components/cms/cms-howitworks';
import  CMSfeatures from './components/cms/cms-features';
import  CMSgames from './components/cms/cms-populargames';
import CMSfooter from './components/cms/cms-footer';
import CMSsignup from './components/cms/cms-signup';
import CMShome from './components/cms/cms-home';
import CMSAddlanguage from './components/cms/add-language';
import CMSDellanguage from './components/cms/delete-lang';

// import PayPal from "./components/paypal/paypal";
import { isAuthAdmin } from "./function/function";
import {
  NotificationContainer
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Pong from "./components/pong/game";
import Test from './components/test/test';
import ChangePassword from "./components/changepassword/changepassword";
import CreateTournament from "./components/createtournament/createtournament";
import KYC from "./components/kyc/kyc";
import AdminToUserChat from "./components/admin/adminUserchat/adminUserChat";
import {socket} from "./config/socket";
import EnterTournament from "./components/tournament/entertournament";
import Kyc from './components/admin/kyc';
import SeriesGame from './components/seriesgame';
import WinterGame from './components/pong/winterGame';

import "./responsive.css";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("token") ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect to="/login" />
        )
    }
  />
);

export const AdminPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      // props => adminCheck ? (
        props => /admintoken/.test(document.cookie)  ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect to='/admin/login' />
        )
    }
  />
);

export const AdminCMSPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      // props => adminCheck ? (
        props => /admintoken/.test(document.cookie)  ? (
        <Component {...props} {...rest} />
      ) : (
          <Redirect to='/cms/login' />
        )
    }
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminValid: isAuthAdmin()
    };
  }
  render() {

    return (
      <div className="App">
        <NotificationContainer />
        <Suspense fallback={null}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={() => <Home socket={socket}/> } />
            <Route exact path="/login" component={() => <Login />} />
            <Route exact path="/signup" component={() => <Signup />} />
            <Route exact path="/forget" component={() => <Forgetpass />} />

            <Route exact path="/cms/login" component={()=><AdminCmsLogin/>}/>
            <AdminCMSPrivateRoute exact path="/cms/dashboard" component={()=><AdminDashboard/>}/>
            <AdminCMSPrivateRoute exact path="/cms/about" component={()=><CMSaboutUs/>}/>
            <AdminCMSPrivateRoute exact path="/cms/outteam" component={()=><CMSourteam/>}/>
            <AdminCMSPrivateRoute exact path="/cms/ourvision" component={()=><CMSourvision/>}/>
            <AdminCMSPrivateRoute exact path="/cms/responsiblegaming" component={()=><CMSresponsiblegaming/>}/>
            <AdminCMSPrivateRoute exact path="/cms/legal" component={()=><CMSlegal/>}/>
            <AdminCMSPrivateRoute exact path="/cms/terms" component={()=><CMSterms/>}/>
            <AdminCMSPrivateRoute exact path="/cms/privacy" component={()=><CMSprivacy/>}/>
            <AdminCMSPrivateRoute exact path="/cms/transactionsecurity" component={()=><CMSsecurity/>}/>
            <AdminCMSPrivateRoute exact path="/cms/payment" component={()=><CMSpayments/>}/>
            <AdminCMSPrivateRoute exact path="/cms/help" component={()=><CMShelp/>}/>
            <AdminCMSPrivateRoute exact path="/cms/faqs" component={()=><CMSfaqs/>}/>
            <AdminCMSPrivateRoute exact path="/cms/howitworks" component={()=><CMShowitworks/>}/>
            <AdminCMSPrivateRoute exact path="/cms/features" component={()=><CMSfeatures/>}/>
            <AdminCMSPrivateRoute exact path="/cms/populargames" component={()=><CMSgames/>}/>
            <AdminCMSPrivateRoute exact path="/cms/footer" component={()=><CMSfooter/>}/>
            <AdminCMSPrivateRoute exact path="/cms/signup" component={()=><CMSsignup/>}/>
            <AdminCMSPrivateRoute exact path="/cms/home" component={()=><CMShome/>}/>
            <AdminCMSPrivateRoute exact path="/cms/addlanguage" component={()=><CMSAddlanguage/>}/>
            <AdminCMSPrivateRoute exact path="/cms/deletelanguage" component={()=><CMSDellanguage/>}/>

            
            {/* <Route exact path="/paypal" component={PayPal} /> */}
            <Route exact path="/test" component={Test} />

            <Route path={`/legal`} exact component={Legal} />
            
            <Route path={`/terms`} exact component={Terms} />
            <Route path={`/privacy`} exact component={Privacy} />

            <Route path={`/howitworks`} exact component={Howitworks} />
            <PrivateRoute path={`/tournament`} exact component={TournamentPage} />
            <Route path={`/runningduels`} exact component={Runningduels} />
            <Route path={`/features`} exact component={Features} />
            <Route path={`/faqs`} exact component={Faqs} />
            <Route exact path="/populargames" component={() => <Populargames socket={socket}/> } />
            <PrivateRoute path={`/profile`} exact component={Profile} />
            <PrivateRoute path={`/friend`} exact component={Friend} />
            <PrivateRoute path={`/seriesGame`} exact component={() => <SeriesGame socket={socket}/> } />
            <Route path={`/about`} exact component={AboutUs} />
            <Route path={`/editprofile`} exact component={EditProfile} />
            <Route exact path="/changepassword" component={() => <ChangePassword />} />
            <Route path={`/ourvision`} exact component={OurVision} />
            <Route path={`/ourteam`} exact component={OurTeam} />
            <Route path={`/help`} exact component={Help} />
            <Route path={`/payment`} exact component={Payment} />
            <Route path={`/transactionsecurity`} exact component={TransactionSecurity} />
            <Route path={`/responsiblegaming`} exact component={ResponsibleGaming} />
            <Route exact path="/reset/:hex" component={() => <Resetpassword />}/>
            <Route exact path="/createtournament" component={()=><CreateTournament/>}/>
            <Route exact path="/deposit" component={()=><Deposit/>}/>
            <Route exact path="/kyc" component={()=><KYC/>}/>
            <PrivateRoute exact path="/transactions" component={()=><UserTransaction/>}/>
            <PrivateRoute exact path="/withdrawMoney" component={()=><WithdrawMoney/>}/>
            <Route exact path={`/enterTournament`} component={()=><EnterTournament/>}/>
            <Route
              path={`/tournamentdetails`}
              exact
              component={Tournamentdetails}
            />
             <Route
              path={`/notificationpage`}
              exact
              component={Notificationpage}
            />
            <PrivateRoute exact path="/pong" component={() => <Pong socket={socket} />}/>
            <PrivateRoute exact path="/winterGame" component={() => <WinterGame socket={socket} />}/>

            <Admin>
              <Route path={`/admin/login`} exact component={AdminLogin} />
              <AdminPrivateRoute path={`/admin/dashboard`} exact component={Dashboard} />
              <AdminPrivateRoute path={`/admin/tournament`} exact component={Tournament} />
              <AdminPrivateRoute path={`/admin/user`} exact component={User} />
              <AdminPrivateRoute path={`/admin/kyc`} exact component={Kyc} />
              <AdminPrivateRoute path={`/admin/commission`} exact component={Commission} />
              <AdminPrivateRoute path={`/admin/turnover-limit`} exact component={Turnover} />
              <AdminPrivateRoute path={`/admin/transaction`} exact component={Transaction} />
              <AdminPrivateRoute path={`/admin/matches`} exact component={Matches} />
              <AdminPrivateRoute path={`/admin/withdrawRequestList`} exact component={WithdrawRequest} />
              <AdminPrivateRoute path={`/admin/notify`} exact component={()=><Notify socket={socket}/>}/>
              <AdminPrivateRoute path={`/admin/user-chat`} exact component={()=><AdminToUserChat/>} />
              {/* <AdminPrivateRoute path={`/tournamentdetails`} exact component={Tournamentdetails} /> */}
            </Admin>
            <Route path="*" component={() => <div>Page not found</div>} />

          </Switch>
        </Router>
        </Suspense>
      </div>
    );
  }
}

export default App;
