import React, { Component } from "react";
import { Modal, Form, Button,Alert } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { userInstance,cmsInstance } from "../../axios/axiosconfig";
import {Dropdown, DropdownButton } from 'react-bootstrap';
class Bet extends Component {

    state = {
        amount: 0,
        userBalance: 0,
        walletType: "",
        error:null,
        content:{},
        frienddropdown: 'Select Bet',
        pongHalf:0,
        pongOne:0,
        pongTwo:0,
        pongFive:0,
        pongTen:0,
        pongTwentyFive:0,
        pongFifty:0,
        pongHundred:0,
        winterHalf:0,
        winterOne:0,
        winterTwo:0,
        winterFive:0,
        winterTen:0,
        winterTwentyFive:0,
        winterFifty:0,
        winterHundred:0,
    }

    handleChange= (i) =>{
        this.setState({amount:i })
        this.setState({frienddropdown:i+ 'USD'})
    }
  
    closeError=()=>{
        setTimeout(() => {
            this.setState({error:null});
      }, 3000);
    }
    
    getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/bet';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            this.setState({content:content})
            this.setState({frienddropdown: content.selectBet});
          } 
        } catch (error) {
          console.log('Error', error.message);
        }
      }

     getUserProfile = async () => {
        this.getContent();
        const response = await userInstance.get("/getprofile");
        if (response.data.code === 200) {
            console.log('response.data.profile_data', response.data.profile_data.deposit[0].isActive)
            if(response.data.profile_data.deposit[0].isActive === true){
                this.setState({ userBalance: response.data.profile_data.deposit[0].USD });
                this.setState({ walletType: "Deposit"})
            }
            else{
                this.setState({ userBalance: response.data.profile_data.bonus[0].USD });
                this.setState({ walletType: "Bonus"})
            }
        }
      };

    handleSubmit= async(event) => {
        event.preventDefault();
        console.log('this.state.userBalance', this.state.userBalance)
        if((this.state.walletType === 'Deposit') && (this.state.userBalance >= this.state.amount && this.state.amount>0)){
            this.props.matchRoom(this.state.amount, this.state.walletType);
        }else if((this.state.walletType === 'Bonus') && ((this.state.userBalance * 50/100) >= this.state.amount && this.state.amount>0)){
            this.props.matchRoom(this.state.amount, this.state.walletType);
        }
        else{
            if(this.state.amount<=0){
                this.setState({error:"Please Select Bet Amount"});
               
            }else if((this.state.walletType === 'Bonus') && (this.state.userBalance * 50/100) < this.state.amount){
                this.setState({error:" Only 50% can be used from Bonus wallet for match Making "});
            }
            else{
                // console.log("Insufficent Balance")
                this.setState({error:" Insufficient Amount in Your Wallet "});
            }
            this.closeError();
          
        }
        
        // this.props.socket.emit('bet', {
        //     bet:this.state.amount
        // })
        
        // this.props.socket.emit('betAmt', {amt: this.state.amount, roomId: param.roomId})
    }

    componentDidMount = async() => {
        this.props.socket.on('betAmount', (data) => {
            console.log('betAmount', data)
        })
        this.getUserProfile();

        this.props.socket.on('playercount', (data) => {
           this.setState({
            pongHalf:data.pong_half,
            pongOne:data.pong_one,
            pongTwo:data.pong_two,
            pongFive:data.pong_five,
            pongTen:data.pong_ten,
            pongTwentyFive:data.pong_twentyfive,
            pongFifty:data.pong_fifty,
            pongHundred:data.pong_hundred,
            winterHalf:data.winter_half,
            winterOne:data.winter_one,
            winterTwo:data.winter_two,
            winterFive:data.winter_five,
            winterTen:data.winter_ten,
            winterTwentyFive:data.winter_twentyfive,
            winterFifty:data.winter_fifty,
            winterHundred:data.winter_hundred,
           })
        })
       
    }
   
    render() {
        // const { t, i18n } = useTranslation();
        const { CloseBetComponent } = this.props;
        return (
            <Modal
                size="sm"
                show={true}
                backdrop="static"
                className="bet-popup"
                onHide= {()=>CloseBetComponent()}
            >
                <Modal.Header >
                    {/* <Modal.Title>{t("bet.betting_amount")}</Modal.Title> */}
                    <Modal.Title>{this.state.content.betAmount}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                    {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
                         <Form onSubmit={this.handleSubmit}>
                            <Form.Group>
                                <Form.Label>{this.state.content.betAmount}</Form.Label>
                                {/* <Form.Control as="select"  onChange={this.handleChange} name="amount" size="sm" value={this.state.amount}>
                                    <option selected disabled={this.state.amount>0}> {t("bet.selectbet")}</option> */}
                                    {/* <option selected disabled={this.state.amount>0}> {this.state.content.selectBet}</option>
                                    <option value="0.5">0.5 USD</option>
                                    <option value="1">1 USD</option>
                                    <option value="2">2 USD</option>
                                    <option value="5">5 USD</option>
                                    <option value="10">10 USD</option>
                                    <option value="25">25 USD</option>
                                    <option value="50">50 USD</option>
                                    <option value="100">100 USD</option>
                                    </Form.Control> */}

                                <DropdownButton id="formBasicCountry" title={this.state.frienddropdown}>
                                <Dropdown.Item value="0.5" onClick={() => this.handleChange(0.5)}>0.5 USD </Dropdown.Item>
                                <Dropdown.Item value="1" onClick={() =>this.handleChange(1)}>1 USD </Dropdown.Item>
                                <Dropdown.Item value="2" onClick={() =>this.handleChange(2)}>2 USD </Dropdown.Item>
                                <Dropdown.Item value="5" onClick={() =>this.handleChange(5)}>5 USD </Dropdown.Item>
                                <Dropdown.Item value="10" onClick={() =>this.handleChange(10)}>10 USD</Dropdown.Item>
                                <Dropdown.Item value="25" onClick={() =>this.handleChange(25)}>25 USD</Dropdown.Item>
                                <Dropdown.Item value="50" onClick={() =>this.handleChange(50)}>50 USD</Dropdown.Item>
                                <Dropdown.Item value="100" onClick={() =>this.handleChange(100)}>100 USD</Dropdown.Item>

                                       
                                  
                                </DropdownButton>
                                 <div className="m-2"> 
                                {/* <Button type="submit" size="sm">{t("bet.submit")}</Button> {'  '} */}
                                <Button className="red-btn" type="submit" size="sm">{this.state.content.submit}</Button> {'  '}
                                {/* <Button onClick={()=>CloseBetComponent()} size="sm">{t("bet.close")}</Button> */}
                                <Button className="blue-btn" onClick={()=>CloseBetComponent()} size="sm">{this.state.content.close}</Button>
                                </div>
                                {/* <Button type="submit" size="sm">{t("bet.submit")}</Button> */}
                            </Form.Group>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        )
    };
}

export default Bet;