import {useState} from 'react';
import React from 'react';
import {server} from '../../../config/keys'
import { Modal, Button, Form } from 'react-bootstrap';

const PopUP = ({ popup,currentkyc,handlePopup }) => {

   
    const {paypalId, selfiPhoto, addressProof, addressProofImg,country,dob,exposed,firstName,lastName,fullAddress,idProof,idback,idfront,residence} =currentkyc.kyc
    return (
        <Modal
        size="lg-md"
        show={popup}
        className="kycmodal"
        >
        <Modal.Header closeBtuton>
            <Modal.Title id="example-custom-modal-styling-title" >
               Player:  { currentkyc.nickName}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label>First Name:{firstName}</Form.Label>
                        

                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label>Last Name: {lastName}</Form.Label>
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label>Date of Birth:{dob.substring(0,10)}</Form.Label>  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label> Nationality: {country}</Form.Label>
                    </Form.Group>
                </div>
                <div className="col-md-12">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label>Full Address: {fullAddress}</Form.Label>
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label>Country of Residence: {residence}</Form.Label>
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label> Politically Exposed:{exposed ? 'Yes':'No'} </Form.Label>   
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label> ID Proof(Front): </Form.Label> 
                         <img src={`${server}/`+ idfront} alt="idfront" className="img-fluid" />  
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label> ID Proof(Back): </Form.Label>  
                        <img src={`${server}/`+ idback}alt="idfront" className="img-fluid" />   
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label> Address Proof : </Form.Label> 
                        <img src={`${server}/`+ addressProofImg} alt="idfront" className="img-fluid" />   
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label> Selfi Holding proof  of ID: </Form.Label> 
                        <img src={`${server}/`+ selfiPhoto} alt="idfront" className="img-fluid" />   
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="formBasicloginone">
                        <Form.Label>Paypal ID: {paypalId}</Form.Label>   
                    </Form.Group>
                </div>
                <div className="col-md-12">
                   <div className="submitt">
                    <Button variant="primary" type="submit" onClick={() => handlePopup(false)}>
                        Close
                    </Button>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
       );
}

export default PopUP;