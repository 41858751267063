import React, { Component } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import queryString from "query-string";
import { CANVAS } from "../../data/canvas";
import { socket } from "../../config/socket";
import { userInstance } from "../../axios/axiosconfig";
import { server } from "../../config/keys";



class Pong extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftmiss: 0,
      rightmiss: 0,
      left: 250,
      right: 250,
      x: CANVAS.HEIGHT / 2,
      y: CANVAS.WIDTH / 2,
      handle: null,
      gameObj: null,
      gameStatus: false,
      rematch: false,
      play: true,
      Userid: null,
      player1: "",
      player2: "",
      player1id: "",
      player2id: "",
      tournamentId:"noTID",
      promoted:false,
      nextRoundPromo:false,
      roomId:null,
      gameType:'',
      loserPanel:false,
      winnerPanel:false,
      unityInstance: null,
    };
    this.x = CANVAS.WIDTH / 2;
    this.y = CANVAS.HEIGHT / 2;
    this.left = CANVAS.HEIGHT / 2;
    this.right = CANVAS.HEIGHT / 2;
    socket.on('deniedConfirm', () => {
      console.log("deniedConfirm");
      window.location.href = "/";
    }) 
  }
	
  getRoomId = async () => {
    const values = queryString.parse(window.location.search);
    if(values.roomId){
      if(values.offline){
        localStorage.setItem('offline', true);
      }
      else{
        localStorage.setItem('offline', false);
      }
      localStorage.setItem('roomId', values.roomId);
      localStorage.setItem('dodAdress', `${server}/socket.io/`)
      const res = await userInstance.post('/checkTournament',values);
      if(res.data.code === 200){
        this.setState({tournamentId: res.data.tour_id});
      }
      return values;
    }
    //Check Room is Associated with Tournament?
    
     
  };

  stopGame = () => {
    this.props.socket.emit("abc", "stop");
  };

  // joinRoom = async () => {
  //   const values = this.getRoomId();
  //   if (values.roomId) {
  //     const token = localStorage.getItem("token");
  //     this.setState({
  //       play: false
  //     });
  //     let rid = values.roomId;
  //     this.props.socket.emit("room", {
  //       roomId: rid,
  //       token,
  //       Userid: this.state.Userid
  //     });
  //   }
  // };

  //Deduct amount from the user wallet----------------------------->
  forBetMoney = async () => {
    await userInstance.post("/deductRematchBet");
  }
  check = async (values)=>{
    const response = await userInstance.post("/checkRoom", values);
    console.log("response==>",response);
  }
 
  async componentDidMount() {
    let encodedData = localStorage.getItem("Userid");
    const Userid = atob(encodedData);
    this.setState({ Userid });
    const values = await this.getRoomId();
    if(values){
      window.unityInstance  = window.UnityLoader.instantiate("unityContainer", "Pong Web/Build/Pong Web.json");
    }
     this.props.socket.on("rematchBet", ()=>{
      this.forBetMoney();
    });
    //next Round Socket
    this.props.socket.on("nextRound", (data) => {
      console.log("from nextRound===>", data)
      if(data.userId.toString() === encodedData.toString()){
        this.setState({nextRoundPromo : true, roomId:data.roomId, gameType:data.gameType});
      }
    })

    //Promoted to next Round
    this.props.socket.on('promotedPlayer', (data) => {
      console.log('promotedPLayer listen', data);
     this.setState({promoted:true, roomId:data.roomId, gameType:data.gameType});
    })
    //start next Round
    this.props.socket.on('startNextRound',()=>{
      if(this.state.roomId){
        setTimeout( () => {
          window.location.href=`/${this.state.gameType}?roomId=${this.state.roomId}`}
          ,1000)
      }else{
        this.setState({loserPanel:true});
        setTimeout(()=>{
          window.location.href='/'
        },1000)
      }
    })
    //winner Socket
    this.props.socket.on('Winner',()=>{
      this.setState({winnerPanel:true});
      setTimeout(()=>{
        window.location.href='/'
      },1000)
    })
    //  let encodedData = localStorage.getItem("Userid");
       
   let rid = values.roomId;
    if(values.tour_id){
     this.check(values);
     this.setState({tournamentId:values.tour_id});
      // const players = response.data.players;
    }
    
      
    this.props.socket.on("welcome", data => {
      console.log("welcome", data);
      this.setState({ handle: data.handle });
      let arr = data.roomdet.players;
      if (arr.length) {
        if (arr.length === 2) {
          console.log("arrr",arr);
          this.setState({ player1: arr[0].name, player2: arr[1].name, player1id: arr[0].id, player2id: arr[1].id });
        }
        this.setState({ player1: arr[0].name, player1id: arr[0].id });
        arr.map(ele => {
          if (ele.id === Userid) {
            this.setState({ handle: ele.handle });
          }
        });
      }
     
    });
   
    this.props.socket.on("rematchShow",()=>{
      console.log("Rematch show is listen")
    })
    this.props.socket.on("connect", () => {
      console.log("Connected");
    });
    this.props.socket.on("players", data => {
      console.log("players socket", data);
      let arr = data.response.players;
      if (arr.length) {
        if (arr.length === 2) {
          this.setState({ player1: arr[0].name, player2: arr[1].name });
          console.log("Player names:",arr[0].name,arr[1].name);
        }
        this.setState({ player1: arr[0].name });
        console.log("Player names:",arr[0].name);
        arr.map(ele => {
          if (ele.id === Userid) {
            this.setState({ handle: ele.handle });
          }
        });
      }
    });
    
    this.props.socket.emit("stop", { roomId: rid });
    this.props.socket.on("request", data => {
      this.setState({ rematch: true });
      console.log("Data from Request Socket", data)
    })
    this.props.socket.on('confirm', (data) => {
      console.log("confirm!")
      window.location.reload();
    })

    this.props.socket.on("social", (data) => {
      console.log("This is Social Socket data", data)
    })
  }
  
     
    
  render() {
    return (
      <>
         <div id="unityContainer" style={{height: '100vh'}}>

         </div>
        <Modal show={this.state.nextRoundPromo} onHide={()=>{this.setState({nextRoundPromo:true})}}>
             <ModalBody>
             <div>
             <h1>Winner Promoted to Next Round</h1>
             <h3>Next Round will start after all game on round 1 is finished</h3>
             <p>Do not close this popup</p>
              </div>
             </ModalBody>
            </Modal>
             <Modal show={this.state.promoted} onHide={()=>{this.setState({promoted:true})}}>
             <ModalBody>
             <div>
                <h1>Promoted to Next Round </h1>
                <h3>Next Round will start after all game on round 1 is finished</h3>
             <p>Do not close this popup</p>
              </div>
             </ModalBody>
           </Modal>
           <Modal show={this.state.loserPanel} onHide={()=>{this.setState({loserPanel:true})}}>
             <ModalBody>
             <div>
                <h1>You Lose this Round </h1>
                <h3>Will redirect to Home page in 1 sec</h3>
             <p>Do not close this popup</p>
              </div>
             </ModalBody>
           </Modal>
           <Modal show={this.state.winnerPanel} onHide={()=>{this.setState({winnerPanel:true})}}>
             <ModalBody>
             <div>
                <h1>Tournament Final Match Finished </h1>
                <h3>Will redirect to Home page in 1 sec</h3>
             <p>Do not close this popup</p>
              </div>
             </ModalBody>
           </Modal>
      </>
    );
  }
}

export default Pong;
