import React, {useState, useEffect} from 'react';
import { languages, pages } from '../../../function/languages';
import AdminWrapper from '../adminwrapper/wrapper';
import {cmsInstance} from "../../../axios/axiosconfig";
import CMSaboutUs from '../cms-about'
import CMSourteam from '../cms-ourteam';
import CMSourvision from '../cms-ourvision';
import CMSresponsiblegaming from '../cms-responsiblegaming';
import CMSlegal from '../cms-legalnotice';
import CMSterms from '../cms-terms';
import CMSprivacy from '../cms-privacy';
import CMSsecurity from '../cms-transactionsecurity';
import CMSpayments from '../cms-payments';
import CMSfaqs from '../cms-faqs';
import CMShowitworks from '../cms-howitworks';
import  CMSfeatures from '../cms-features';
import  CMSgames from '../cms-populargames';
import CMSfooter from '../cms-footer';
import CMSsignup from '../cms-signup';
import CMShome from '../cms-home';
import CMSlogin from '../cms-login';
import CMStournament from '../cms-tournament';
import CMSgamespage from '../cms-games';
import CMShowitwork from '../cms-howitworks';
import CMSfaqspage from '../cms-faqs';
import CMSprofile from '../cms-profile';
import CMStransactions from '../cms-transaction';
import CMSnotification from '../cms-notification';
import CMSkyc from '../cms-kyc';
import CMSheader from  '../cms-header';
import CMSeditprofile from '../cms-editprofile';
import CMSmoney from '../cms-addmoney';
import CMSwithdrawmoney from '../cms-withdrawmoney';
import CMSforgetpassword from '../cms-forgetpassword';
import CMSchoosegame from '../cms-choosegame';
import CMSfriend from '../cms-choosefriend';
import CMSfriendpage from '../cms-feiend';
import CMSFooter from '../cms-footer';
import CMSpopup from '../cms-popup';
import CMSbet from '../cms-bet';
import CMSgametable from '../cms-gametable';
import CMSregistersuccess from '../cms-registersuccess';
import CMSchangepassword from   '../cms-changepassword';
import CMSinvitefriends from '../cms-invitefriends';
import CMShelp from '../cms-help';
import Transactionsecurity from '../cms-transactionsecurity';

const CMSAddlanguage = () => {
    const [addedlanguages, setAddlanguages] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [selectlanguage, setSelectLanguage] = useState(null);
    const [showPages, setShowPages] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);
    const [success, setSuccess] = useState(false);
    const [index, setIndex] = useState(null);
    
    let newArr = [];
    useEffect(() => {
        getTotalLanguages();
    }, [selectlanguage]);


    const getTotalLanguages = async() => {
        const res = await cmsInstance.get('/getlanguage');
        if(res.data.code === 200){
          setAddlanguages(res.data.language);
          setisLoading(true);
          console.log(res);
        }
      }

    const handleSelectLanguage = (e) => {
       setSelectLanguage(e.target.value);
       setShowPages(true);
      
    }
    const handleDropdown = () => {
       pages.splice(index -1, 1);
        
    }
    const handleSelectPage = (e) => {
        setIndex(e.target.selectedIndex)
        setSelectedPage(e.target.value);
    }
    return(
        <AdminWrapper>
            <div className="container">
            <div className='row'>
                <label style={{marginTop:"6px"}}>
                Select the language you want to edit (if exists) or add new language
                </label>{"  "}
                <select name="language" onChange={(e) => handleSelectLanguage(e)} style={{width:'200px', height:"25px",marginLeft:"10px"}}>
                    <option>Select Language</option>
                    {
                       isLoading?  languages.forEach(ele => {
                            // let da= addedlanguages.filter(el => el === ele.code);
                            let da = [];
                          if(da.length === 0)
                              newArr.push(ele);
                        }) : ''
                    }
                    {
                        newArr.map(language => {
                            return (
                                <option value={language.code}>{language.name}</option>
                            );
                        })
                    }
                </select>
               <br/><br/>
               {showPages ? <select name='page' onChange={handleSelectPage} style={{width:'200px', marginLeft:"10px", height:"25px"}}>
                    <option>
                        Select Page
                    </option>
                    { pages.map(ele => {
                        return (
                            <option value={ele.page}>{ele.name}</option>
                        )
                    })}
                </select>:""}
                <br/><br/>
                {console.log('====>',selectedPage)}
                {selectedPage =='home'? <CMShome language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='signup' ? <CMSsignup language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='login' ? <CMSlogin language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='tournamentpage' ? <CMStournament language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='games' ? <CMSgamespage language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='howitwork' ? <CMShowitwork language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='faq' ? <CMSfaqspage language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='profile' ? <CMSprofile language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='transactions' ? <CMStransactions language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='notification' ? <CMSnotification language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='kyc' ? <CMSkyc language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='header' ? <CMSheader language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='editprofile' ? <CMSeditprofile language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='addmoney' ? <CMSmoney language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='withdrawmoney' ? <CMSwithdrawmoney language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='forgetpassword' ? <CMSforgetpassword language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='choosegame' ? <CMSchoosegame language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='choosefriend' ? <CMSfriend language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='friend' ? <CMSfriendpage language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='footer' ? <CMSFooter language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='about' ? <CMSaboutUs language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='popup' ? <CMSpopup language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='bet' ? <CMSbet language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='gametable' ? <CMSgametable language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='populargames' ? <CMSgames language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='registersuccess' ? <CMSregistersuccess language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='changepassword' ? <CMSchangepassword language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='invitefriends' ? <CMSinvitefriends language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='help' ? <CMShelp language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='legal' ? <CMSlegal language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='terms' ? <CMSterms language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='privacy' ? <CMSprivacy language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='transactionsecurity' ? <Transactionsecurity language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='payment' ? <CMSpayments language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='responsiblegaming' ? <CMSresponsiblegaming language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='vision' ? <CMSourvision language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='team' ? <CMSourteam language={selectlanguage} handleDropdown={handleDropdown}/>:
                 selectedPage ==='features' ? <CMSfeatures language={selectlanguage} handleDropdown={handleDropdown}/>:
               
               ''}
                   
            </div>
            </div>
        </AdminWrapper>
    )
}

export default CMSAddlanguage;