// export const client = 'http://localhost:3000';
// export const server = 'http://localhost:3001';

export const client = "https://demo.dayofduel.com";
export const server = "https://demo.dayofduel.com";

// export const client = 'http://3.134.250.42:3000';
// export const server = 'http://3.134.250.42:3001';

export const GoogleclientId =
  "427107477908-5drivpon5kfjgk98nopgqfaaapeta6io.apps.googleusercontent.com";

export const FacebookAppID = "365646731411555";
