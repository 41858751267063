import React from "react";
import { InputLabel } from "../input-label/index";
import "../user/components/user-list.css";

const SectionHeader = ({
  title,
  buttonText,
  query,
  handleSearch,
  handleFilter,
  filter,
  content
}) => {
  return (
    <header
      className={`card__header ${buttonText ? `card__header--has-btn` : ``}`}
    >
      <h4>{title}</h4>
      {title === "User list" && (
        <div className="row">
          <div className="col-6">
            <InputLabel
              label={``}
              id={`title-input-field`}
              required={false}
              type={`text`}
              placeholder={`Please enter text to search`}
              name={"query"}
              value={query}
              handlechange={handleSearch}
            />
          </div>
          <div className="col-6">
            <div className="dropdown nickname-list">
              <select
                className="form-control"
                onChange={handleFilter}
                value={filter}
              >
                <option selected disabled>
                  Filter By
                </option>
                <option value="nickName">NickName</option>
                <option value="email">Email</option>
                <option value="country">Country</option>
                <option value="all">All</option>
              </select>
            </div>
          </div>
        </div>
      )}
      {title === 'KYC List' &&(
        <div className="row">
        <div className="col-6">
          <InputLabel
            label={``}
            id={`title-input-field`}
            required={false}
            type={`text`}
            placeholder={`Please enter text to search`}
            name={"query"}
            value={query}
            handlechange={handleSearch}
          />
        </div>
        <div className="col-6">
          <div className="dropdown nickname-list">
            <select
              className="form-control"
              onChange={handleFilter}
              value={filter}
            >
              <option selected disabled>
                Filter By
              </option>
              <option value="nickName">NickName</option>
              <option value="email">Email</option>
              <option value="country">Country</option>
             
            </select>
          </div>
        </div>
      </div>
      )}
      {content && title === 'PLAYERS LIST' && (
        <div className="row">
        <div className="col-6">
          <InputLabel
            label={``}
            id={`title-input-field`}
            required={false}
            type={`text`}
            placeholder={content.placeholder}
            name={"query"}
            value={query}
            handlechange={handleSearch}
          />
        </div>
        <div className="col-6">
          <div className="dropdown nickname-list">
            <select
              className="form-control"
              onChange={handleFilter}
              value={filter}
            >
              <option selected disabled>
                {content.filter}
              </option>
              <option value="nickName">{content.nickname}</option>
              <option value="country">{content.country}</option>
             
            </select>
          </div>
        </div>
      </div>
      )}
      {buttonText && (
        <a href="#" className="btn btn-default btn-xs card-header__button">
          {buttonText}
        </a>
      )}
    </header>
  );
};
export default SectionHeader;
