import React, { useState, useEffect } from 'react';
import AdminWrapper from "../adminwrapper/wrapper";
import { cmsInstance } from '../../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";

const CMShome = ({ language, handleDropdown }) => {
    const [payload, setPayload] = useState({});
    const [content, setContent] = useState({});
    const [copyContent, setCopyContent] = useState({});
    const [prevContent, setPrevContent] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [dummyData,setDummyData] = useState({});
    let arr = [];
    let dummy ={};
    let m=0;
    let k=0;
    let name ='';
    useEffect(() => {
        getContent();
        getPrevContent();
    },[])
    const getContent = async (e) => {
        try {
           
            const page ='home';
            const { data: { content }, status } = await cmsInstance.get(`/content/${page}/en`);
            if (status === 200) {
                console.log(content);
                setContent(content);
                setCopyContent(content);
                
                let n=1;
               
               
                Object.values(content).map((ele,j) => {
                    
                     Object.values(ele).map((el,i) => {
                         let z = 'a'+j+i
                        dummyData[z]='';
                    })
                })
            } else {
                setContent({ term1: '', term2: '', term3: '', term4: '' });
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    const getPrevContent = async (e) => {
        try {
           
            const page ='home';
            const { data: { content }, status } = await cmsInstance.get(`/content/${page}/${language}`);
            if (status === 200) {
                console.log(content);
                setPrevContent(content);
                setCopyContent(content);
                setIsEditable(true);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }
    const handleChange = (e, j,i) => {
        if(isEditable){
            let obj = arr[j];
            console.log("===>",obj);
            let newContent = prevContent[arr[j]];
            
            newContent = {...newContent, [e.target.name] : e.target.value}
            console.log("obj===>", copyContent[obj], newContent);
            setPrevContent({ ...prevContent,  [obj]: newContent });
        }else{
            let z = 'a'+j+i;
            console.log(z);
           
            let obj = arr[j]
            let newContent = copyContent[arr[j]];
            setDummyData({...dummyData, [z]:e.target.value});
            newContent = {...newContent, [e.target.name] : e.target.value}
            console.log("obj===>", copyContent[obj], newContent);
            setCopyContent({ ...copyContent,  [obj]: newContent });
            
        }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const page = 'home';
        payload.page = page;
        payload.content = isEditable ? prevContent : copyContent;
        payload.language = language;
        if (payload.language !== 'Select Language' && payload.language) { 
            const { data: { msg }, status } = await cmsInstance.post('/content', payload);
            if (status == 200) {
                NotificationManager.success(msg, "Message", 10000);
                handleDropdown();
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        }
    }
    const handleReset = () => {
        setCopyContent({});
    }
   console.log(dummyData);
    Object.keys(content).map(ele => {
        arr.push(ele);
   })

    return (
       <div className="container">
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <h5>Home Page Content in English</h5>
              {Object.values(content).map((ele,j) => {
                 return Object.values(ele).map((el,i) => {
                  return( 
                  <div className='row'>
                        <div classname='col-md-6'style={{width:'40vw'}}>
                                <label>{el}</label>
                        </div>
                        <div classname='col-md-6'>
                            <textarea type='text' name={Object.keys(ele)[i]} value={isEditable ? Object.values(Object.values(prevContent)[j])[i] : Object.values(dummyData)['a'+j+i] } onChange ={(e) => handleChange(e,j,i)} style={{marginTop:"3px"}}/>
                        </div>
                    </div>)
                  })
              })}
              
               
                    
                   
                    {isEditable ?<><input type="submit" value="Edit" style={{marginRight:"10px"}}/>
                    <input type="reset" value="Cancel" />
                    </>
                    :<> <input type="submit" value="Submit" style={{marginRight:"10px"}}/>
                    <input type="reset" value="Clear" /></>}
                    </form>
                </div>
           
    );
}
export default CMShome;