/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./populargames.css";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Layout from '../layout'
import pong from "../../assets/images/home/pong-placeholder.png";
import fifa from "../../assets/images/home/wintergame.png";
import manyicon from "../../assets/images/home/many-icon.png";
import duel from "../../assets/images/home/duel.png";
import tournament from "../../assets/images/home/tournament.png";
import newtournament from "../../assets/images/home/new-tournament.png";
import tryicon from "../../assets/images/home/try-icon.png";
import { useTranslation } from "react-i18next";
import { userInstance, adminInstance, cmsInstance } from '../../axios/axiosconfig';
import { NotificationManager } from 'react-notifications';
import comingsoon from "../../assets/images/home/coming.gif";
import pongmobile from "../../assets/images/home/pong-mobile.png";
import Bet from '../home/bet';
import Popup from '../home/popup';
import multiplayer from "../../assets/images/home/multiplayer.png";
import { server } from "../../config/keys";
import appstore from "../../assets/images/home/appstore.png";
import playstore from "../../assets/images/home/playstore.png";
let message;
let nomatch;
const Populargames = ({ socket }) => {
  const [show, setShow] = useState(false);
  const [view, setViewShow] = useState(false);
  const { i18n } = useTranslation();
  const [content, setContent] = useState({});
  const [level, setLevel] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [country, setCountry] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [startTimer, setstartTimer] = useState(false);
  const [matched, setMatched] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const Userid = localStorage.getItem("Userid");
  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);
  const [playerDetails, setPlayerDetails] = useState(null);
  const [banned, setBanned] = useState(null);
  const [bet, setBet] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gametype, setGameType] = useState('Pong');

    
    const handleShow = () => setShow(true);
    const handleViewShow = () => setViewShow(true);

    
    const handleClose = () => setShow(false);
    const handleViewClose = () => setViewShow(false);

    const CloseBetComponent = () => {
        setBet(false);
      }

    const getUserProfile = async () => {
        const response = await userInstance.get("/getprofile");
        if (response.data.code === 200) {
          setBanned(response.data.profile_data.isbanned);
  
          setLevel(response.data.profile_data.Level)
          setPlayerOne(response.data.profile_data)
          setNickName(response.data.profile_data.firstName)
          setCountry(response.data.profile_data.country)
          setPhoto(response.data.profile_data.photo)
        }
      };

    const getContent = async () => {
        try {
            const page = window.location.pathname.replace('/cms/', '');
            let language = i18n.language;
            const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
            if (status == 200) {
                setContent(content);
                message= content.message;
                nomatch= content.nomatch;
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        getContent();
        getUserProfile();
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const onLanguageChanged = () => {
        getContent();
    }

    const matchRoom = async (amount, walletType) => {
        const res = await adminInstance.post("/getCommission")
        console.log("Admin API", res.data.rate)
        const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
        // const response = await userInstance.post("/getprofile");
        // console.log("responseData", walletType);
        socket.emit("matchMaking", {
          Userid,
          level: level,
          socketId: socket.id,
          bet: amount,
          adminCommission: cutOff,
          betAmt: amount - cutOff,
          walletType: walletType,
          nickName: nickName,
          country: country,
          photo: photo,
          gameType: gametype,
          platform:'web'
        });
        console.log('Matchmaking emitted==========>');
        setBetAmount(amount);
        setBet(false);
        setWallettype(walletType);
        setMatched(true)
      };
      const handleTimerPopup = e => {
        if (e === `close`) {
          setstartTimer(false);
          // socket.emit("cancel", {
          //   Userid
          // });
        }
      };

      useEffect(() => {
        // socket.emit("disconnect", {
        //   Userid
        // });
        socket.on('playerPopUp', data => {
    
          if (Userid === data.matched[0].Userid) {
            setPlayerOne(data.matched[0])
            setPlayerTwo(data.matched[1])
          } else {
            setPlayerOne(data.matched[1])
            setPlayerTwo(data.matched[0])
          }
    
        })
    
        socket.on("play", data => {
          // loadRoom();
          setPlayerDetails(data.matched);
          setRoomId(data.roomId);
          setstartTimer(false);
          handleTimerPopup(`close`);
          setMatched(true);
          console.log("Game Room :", roomId);
          console.log("Player Detasils", playerDetails);
          // setBet(true);
    
          socket.emit("server", {
            data
          });
    
          socket.emit('betAmount', data => {
            setBet(false);
          })
        });
        socket.on("winterplay", data => {
          // loadRoom();
          setPlayerDetails(data.matched);
          setRoomId(data.roomId);
          setstartTimer(false);
          handleTimerPopup(`close`);
          setMatched(true);
          console.log("Game Room :", roomId);
          console.log("Player Detasils", playerDetails);
          // setBet(true);
    
          socket.emit("server", {
            data
          });
    
          socket.emit('betAmount', data => {
            setBet(false);
          })
        });
        socket.on('noMatch', () => {
            NotificationManager.error(nomatch, message);
            setMatched(false);
          })
      }, [startTimer]);

      const checkLogin = (game) => {
        if(localStorage.getItem('token')){
            setBet(true);
            setGameType(game);
        }
        else{
            window.location.href='/login';
        }
    }

    return (

        <Layout>
             {matched && (
          <Popup
            roomId={roomId}
            setMatched={setMatched}
            playerOne={playerOne}
            playerTwo={playerTwo}
            socket={socket}
            amount={betAmount}
            wallettype={wallettype}
            gameType = {gametype}
          />
        )}

{bet && (
          <Bet
            socket={socket}
            matchRoom={matchRoom}
            CloseBetComponent={CloseBetComponent}
          ></Bet>
        )}

        <div className="popular-game-bg">
            <div className="popular-game-section">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="title-page">
                                <ul className="breadcrum">
                                    <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.games}</li>
                                </ul>
                                <h2>{content.games}</h2>
                            </div>
                        </div>


                    <div className="col-md-12">
                        <div className="game-tile">
                            <div className="home-tile-box">
                                <div className="home-tile-info">
                                    <span>{content.play}</span>
                                    <h4>{content.poolgame}</h4>
                                    
                                    <h2>100$</h2>
                                   
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className="col-md-3">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={pong} alt="pool" />
                                    <div className="try">
                                        <img src={tryicon} /> <span>{content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> 1050
                                </div>
                               
                                <div className="game-action">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Pong')}><img src={duel} /> <span>{content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content.searchtournament}</span></div>
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content.newtournament} </span></div>
                                    
                                </div>
                                <div className="game-info">
                                <h4>{content.game1}</h4>
                                <p>{content.para1}</p>
                                </div>
                            </div>
                        </div>




                            <div className="col-md-3 popular-two">
                                <div className="popular-box">
                                    <div className="game-img">
                                        <img src={pongmobile} alt="pool" />
                                        <div className="try trynew">
                                            <img src={tryicon} />
                                            <div className="mobile-app-link mobile-app-link1 ">
                                                <span>Pobierz na urządzenie mobilne</span>
                                                <div className="mobile-app-img" onClick={() => window.location.href = `${server}/user/appDownload`}>
                                                    <img src={playstore} alt="playstore" />
                                                </div>
                                                <div className="mobile-app-img" onClick={() => window.location.href = 'https://testflight.apple.com/join/URzIgwRg'}>
                                                    <img src={appstore} alt="appstore" />
                                                </div>
                                            </div>
                                            {/* <span>{content && content.try}</span> */}
                                        </div>
                                    </div>
                                    <div className="many-players">
                                        <img src={manyicon} />213
                                    </div>

                                    <div className="game-action mobile-app-tile">

                                        <div className="duel-action newduel-action" ><img src={duel} />
                                            <div className="mobile-app-link">
                                                <span>Pobierz na urządzenie mobilne</span>
                                                <div className="mobile-app-img" onClick={() => window.location.href = `${server}/user/appDownload`}>
                                                    <img src={playstore} alt="playstore" />
                                                </div>
                                                <div className="mobile-app-img" onClick={() => window.location.href = 'https://testflight.apple.com/join/URzIgwRg'}>
                                                    <img src={appstore} alt="appstore" />
                                                </div>
                                            </div>
                                            {/* <span>{content && content.playduel} </span> */}
                                        </div>
                                        <div className="tournament-action newtournament"><img src={tournament} />
                                            <div className="mobile-app-link">
                                                <span>Pobierz na urządzenie mobilne</span>
                                                <div className="mobile-app-img" onClick={() => window.location.href = `${server}/user/appDownload`}>
                                                    <img src={playstore} alt="playstore" />
                                                </div>
                                                <div className="mobile-app-img" onClick={() => window.location.href = 'https://testflight.apple.com/join/URzIgwRg'}>
                                                    <img src={appstore} alt="appstore" />
                                                </div>
                                            </div>
                                            {/* <span>{content && content.searchtournament}</span> */}
                                        </div>
                                        {/* <div className="multiplayer-action" ><img src={multiplayer} />

                                     <div className="mobile-app-link">
                                            <span>Pobierz na urządzenie mobilne</span>
                                            <div className="mobile-app-img" onClick={() => window.location.href='#'}>
                                                <img src={playstore} alt="playstore" />
                                            </div>
                                            <div className="mobile-app-img" onClick={() => window.location.href='#'}>
                                                <img src={appstore} alt="appstore" />
                                            </div>
                                        </div>
                                     </div>
                                    */}
                                        <div className="newtournament-action"><img src={newtournament} />

                                            <div className="mobile-app-link">
                                                <span>Pobierz na urządzenie mobilne</span>
                                                <div className="mobile-app-img" onClick={() => window.location.href = `${server}/user/appDownload`}>
                                                    <img src={playstore} alt="playstore" />
                                                </div>
                                                <div className="mobile-app-img" onClick={() => window.location.href = 'https://testflight.apple.com/join/URzIgwRg'}>
                                                    <img src={appstore} alt="appstore" />
                                                </div>
                                            </div>

                                        </div>



                                    </div>

                                    <div className="game-info">
                                        <h4>{content && content.game2}</h4>
                                        <p>{content && content.para2}</p>
                                    </div>
                                </div>
                            </div>




                        <div className="col-md-3">
                            <div className="popular-box">
                                <div className="game-img">
                                    <img src={fifa} alt="pool" />
                                    <div className="try">
                                        <img src={tryicon} /> <span>{content.try}</span>
                                    </div>
                                </div>
                                <div className="many-players">
                                    <img src={manyicon} /> 879
                                </div>
                               
                                <div className="game-action winter-game">
                                    
                                    <div className="duel-action" onClick={() => checkLogin('Winter Game')}><img src={duel} /> <span>{content.playduel} </span></div>
                                    <div className="tournament-action"><img src={tournament} /> <span>{content.searchtournament}</span></div>
                                    <div className="multiplayer-action" ><img src={multiplayer} /> <span>Multiplayer </span></div>
                                   
                                    <div className="newtournament-action" onClick={handleViewShow}><img src={newtournament} /> <span>{content.newtournament} </span></div>
                                    
                                </div>
                                <div className="game-info">
                                <h4>{content.game3}</h4>
                                <p>{content.para3}</p>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-3">
                            <div className="popular-box soon"> 
                               <img src={comingsoon} alt="" />                              
                                <div className="game-info">                                    
                                    <h4>{content && content.soon}</h4>
                                    <p>{content && content.para4}</p>
                                </div>
                            </div>
                        </div>









                        <div className="col-md-3">
                            <div className="popular-box soon"> 
                               <img src={comingsoon} alt="" />                              
                                <div className="game-info">                                    
                                    <h4>{content && content.soon}</h4>
                                    <p>{content && content.para4}</p>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-3">
                            <div className="popular-box soon"> 
                               <img src={comingsoon} alt="" />                              
                                <div className="game-info">                                    
                                    <h4>{content && content.soon}</h4>
                                    <p>{content && content.para4}</p>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-3">
                            <div className="popular-box soon"> 
                               <img src={comingsoon} alt="" />                              
                                <div className="game-info">                                    
                                    <h4>{content && content.soon}</h4>
                                    <p>{content && content.para4}</p>
                                </div>
                            </div>
                        </div>




                        <div className="col-md-3">
                            <div className="popular-box soon"> 
                               <img src={comingsoon} alt="" />                              
                                <div className="game-info">                                    
                                    <h4>{content && content.soon}</h4>
                                    <p>{content && content.para4}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>






        <Modal dialogClassName="dod-popup" show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
               <Bet />
            </Modal.Body>
        </Modal>


        <Modal dialogClassName="dod-popup" show={view} onHide={handleViewClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Bet />
            </Modal.Body>
        </Modal>





        </Layout>



    );
};

export default Populargames;