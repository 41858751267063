/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import "bootstrap/dist/css/bootstrap.min.css";
import { server } from '../../config/keys.js';
import "./home.css";
import vs from "../../assets/images/home/vs.png"
import random from '../../assets/images/home/user-random.gif'
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import { adminInstance } from "../../axios/axiosconfig";
import coinpot from '../../assets/images/home/potmoney.png'
import animate from '../../assets/images/home/animate.svg'
import { useTranslation } from "react-i18next";
import ProgressBar from 'react-bootstrap/ProgressBar'
import user from '../../assets/images/home/game-user.png';
import userimg from '../../assets/images/home/JEST69.jpg';
import userimg2 from '../../assets/images/home/znalezione.gif';
const Popup = props => {
    const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [second, setSecond] = useState(10);
  const Userid = localStorage.getItem("Userid");
  const [content, setContent] = useState({});
  let refsec = 10;
  let timer = "";


  const handleClose = () => {
    props.setMatched(false);
    props.socket.emit('cancel', {Userid})
  };

useEffect(() => {
  getContent();
},[])

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = '/popup';
       let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
      const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
      if (status == 200) {
        setContent(content);
      } 
    } catch (error) {
      console.log('Error', error.message);
    }
  }
  const handleShow = () => setShow(true);
  const forBetMoney = async () => {
    if (playerTwoData) {
      const res = await adminInstance.post("/getCommission")
      const payload = {
        betAmt: props.amount,
        wallettype: props.wallettype,
        adminCommission: res.data.rate,
        socketId: props.socket.id,
        gameType: props.gameType
      }
      const response = await userInstance.post("/deductBet", payload);
    
    }
  }

  // useEffect(() => {

  // })
  
  useEffect(() => {
    if(props.roomId !== null){
      forBetMoney();
      countdown();
      localStorage.setItem('betAmt',props.amount);
      if(props.gameType === 'Winter Game'){
        setTimeout(() => {
          window.location.href = `/wintergame?roomId=${props.roomId}`;        
      }, 10000)
      }else{
        setTimeout(() => {
          window.location.href = `/pong?roomId=${props.roomId}`;        
      }, 10000)
      }
      
  }
}, [props.playerOne, props.playerTwo, props.roomId, show])
  
  const countdown = () => {
    timer = setInterval(() => {
      Timer();
      
      setSecond(refsec - 1);
      refsec = refsec - 1;
    }, 1000)
  }
  const Timer = () => {
    if (refsec === 1) {
      clearInterval(timer);
      // window.location.href = `/pong?roomId=${props.roomId}`

    }
  }
  const playerOneData = props.playerOne !== null ? props.playerOne : null
  const playerTwoData = props.playerTwo !== null ? props.playerTwo : null
  if(playerTwoData){
    localStorage.setItem("opponent", playerTwoData.nickName);
  }
 

  return (
    <Modal show={true} onHide={handleClose} className="match-making-popup">

          {playerTwoData !== null && <div className="estimated-time">
                {" "}
                {
                   <div>
                   <p><span>{second}</span></p>
                 </div> 
                }
              </div>}


      <Modal.Header closeButton>  
        {/* closeButton> */}
        <Modal.Title>
          {content.opponent} {playerTwoData !== null ? content.found : content.finding}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="match-making-popup-content">
    


        
        <div className="row">
          <div className="col-md-12">
              <p className="match-heading">{content.require}</p>
            <p className="speed">{content.please}</p>
            <p className="speed">{content.net}</p>
          </div>
          <div className="col-2"/>
          <div className="col-3">
            {playerOneData !== null ?
              <div className="match-box dfs">
                  <img src={userimg} />
                  <h4 className="">{playerOneData.nickName.substring(0,6)}{playerOneData.nickName.length >=7 ? "...": ''}</h4>
                  {/* <p>{playerOneData.country}</p>
                <span>{playerOneData.level}</span> */}
                {/* <div className="user-pics">
                  <img src={`${server}/` + `${playerOneData.photo}`} className="player-img" />
                  <img src={`${server}/public/country/` + `${playerOneData.country}.png`} className="flag-pic" />
                </div> */}
              
               
              </div> : <div className="match-box ">
                {/* <div className="user-pics">
                  <img src={random} className="player-img vsm" />
                  <img src={coinpot} className="player-img coinpot" /> 
                  
                   <img src={`${server}/public/country/`+`${playerTwoData.country}.png`} className="flag-pic"/> 
                </div> */}
                <h4 className="text-right">{content.finding}</h4>
                {/* <p>{content.finding}</p>
                <span>{content.finding}</span> */}
              </div>}
          </div>
          <div className="col-2 text-center bg857">
            <div className="wrapperr">
              <h1>VS</h1>
            {/* <div className="vs">
              <img src={vs} />
            </div> */}
            {/* <div className="coinpot-wrapper">
            <img src={coinpot} className="player-img coinpot" />
            </div> */}
            </div>
          </div>

          <div className="col-3">
            {playerTwoData !== null ? <div className="match-box sdf">
              {/* <div className="user-pics">
                <img src={`${server}/` + `${playerTwoData.photo}`} className="player-img" />
                <img src={`${server}/public/country/` + `${playerTwoData.country}.png`} className="flag-pic" />
              </div> */}
              <img src={userimg2}  className="userimg2"/>
              <h4 className="">{playerTwoData.nickName ? playerTwoData.nickName.substring(0,6):'Player'}{playerTwoData.nickName && playerTwoData.nickName.length >=7 ? "...": ''}</h4>
              {/* <p>{playerTwoData.country}</p>
              <span>{playerTwoData.level}</span> */}
            </div>
              :
              <div className="match-box sdf">
                {/* <div className="user-pics">
                  <img src={random} className="player-img" />
                  <img src={`${server}/public/country/`+`${playerTwoData.country}.png`} className="flag-pic"/>
                </div> */}
                <div className="loading-image">
                <img src={user} className="loading" />
                </div>
               
                <h4 > Player</h4>
                {/* <p>{content.finding}</p>
                <span>{content.finding}</span> */}
                
              </div>}
          
          </div>
          <div className="col-2"/>
        </div>

        <div className="row">
       
          <div className="col-12">
        
          <div className="loader text-center" style={{margin:"0 auto"}}>
             {/* <h4>{content.loading}</h4> */}
             <h4>Player Matching in Progress</h4>
             <div className="loader-bar">
                <div className="loaderBar"></div>
            </div>
          </div>



            <div className="interval">
              {playerTwoData !== null && <p className="link-go">
                {" "}
                {
                  <>
                  <p>Winning Amount</p>
                  <a>$ {parseFloat(playerOneData.betAmt) + parseFloat(playerTwoData.betAmt)}</a>
                  </>
                }
              </p>}
            </div>
          </div>
        </div>

      </Modal.Body>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-primary" onClick={handleClose}>
          Close
        </button>
      </div> */}
      
    </Modal>
  );
};

export default Popup;
