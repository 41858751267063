import React, { useEffect, useState } from "react";
import "./choose.css";
import { Form,  Button } from "react-bootstrap";
import { server } from "../../config/keys";
  import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FacebookShareButton, EmailShareButton, WhatsappShareButton} from 'react-share';
import {cmsInstance} from '../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";

const InviteFriends = () => {


    const [copy, setcopy] = useState(false);
    const [copytext, setcopytext] = useState(null);
    const[content, setContent] = useState({});
    useEffect(() => {
         setcopytext(`${server}/user/invite/` + localStorage.getItem('Userid'));
         setcopy(false);
         getContent();
      }, [copy]);
      const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/invitefriends';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status === 200) {
            setContent(content);
            
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }
    return (

        <div className="invite-friend">

            <div className="invite-friend-content">

                <h2>{content.heading1}</h2>


                <Form>


                <Form.Group className="max-300">
                    <Form.Label>{content.label1}</Form.Label>
                    <Form.Control type="text" placeholder={`${server}/user/invite/` + localStorage.getItem('Userid')} value = {`${server}/user/invite/` + localStorage.getItem('Userid')} />
                    
                    <CopyToClipboard text= {copytext}
                        onCopy={() => setcopy({copy : true})}>
                        <button type='button' onClick={()=> { NotificationManager.success("Referal Link Copied", "Message", 10000);}}><i className="fa fa-copy"></i></button>
                    </CopyToClipboard>
                </Form.Group>


                <Form.Group className="clear">
                    <Form.Label className="text-center">{content.label2}</Form.Label>

                    <div className="entry-fee">

                        <div className="entry-box">
                        <FacebookShareButton
                            tite="Day of Dual"
                            url={`https://demo.dayofduel.com/user/invite/` + localStorage.getItem('Userid')}
                            quote={'Check this new Gaming Website where I Have earned a lot.'}>
                            <Button varaint='outline-secondary'><i class="fa fa-facebook-square" aria-hidden="true"></i></Button>
                            </FacebookShareButton>
                            
                            <span>{content.label3}</span>
                        </div>

                        <div className="entry-box">
                            <WhatsappShareButton
                            tite="Day of Dual"
                            url={`https://demo.dayofduel.com/user/invite/` + localStorage.getItem('Userid')}
                            quote={'Check this new Gaming Website where I Have earned a lot.'}>
                            <Button varaint='outline-secondary'><i class="fa fa-whatsapp" aria-hidden="true"></i>
                            </Button>
                            </WhatsappShareButton>
                            <span>{content.label4}</span>
                        </div>

                        <div className="entry-box">
                        <EmailShareButton
                            subject={`Check out what I did on GoodWerk`}
                            body={`Check this new Gaming Website where I Have earned a lot.:`+ `${server}/user/invite/` + localStorage.getItem('Userid')}>
                            <Button varaint='outline-secondary'><i class="fa fa-envelope-o" aria-hidden="true"></i>
                            </Button>
                            </EmailShareButton>
                            
                            <span>{content.label5}</span>
                        </div>


                    </div>
                   
                </Form.Group>




                </Form>

            </div>
            

        </div>


    );
};

export default InviteFriends;