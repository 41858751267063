import React, { useState } from "react";
import GoogleLogin from 'react-google-login';
import {GoogleclientId} from '../../config/keys';
import { userInstance } from "../../axios/axiosconfig";


const INITIAL_STATE = {
    email: '',
    password: ''
}

const Google = ({btnText}) => {

    const responseGoogle = async (response) => {
   try{
    const guser = await userInstance.post("/googlelogin", response);
    if (guser) {
        localStorage.setItem('token', guser.data.token);
        localStorage.setItem('Userid', guser.data.id);
        window.location.href = '/'
    }
   }catch(e){
return 0;
   }
    }


    return (
        <GoogleLogin
            clientId={GoogleclientId}
            buttonText={btnText}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            autoLoad={false}
        />);
}
export default Google