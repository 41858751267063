import React from 'react'
import AdminLayout from '../layout'
import banner from '../../../assets/images/home/homebanner.png'
import './style.css'
import {adminInstance} from "../../../axios/axiosconfig";
export default class Dashboard extends React.Component{
    constructor(props){
        super(props)
        this.state ={
            admindata:"",
            activeuser:"",
            totaluser:"",
            banneduser:"",
            muteduser:"",
            blockeduser:"",
            wallet: 0.00,
        }
    }

    async componentDidMount() {
        const res = await adminInstance.get("/getUserDetails")
        console.log("jgdygy",res);
        let datauser = res.data.data;
        let admindata = datauser;
        let wallet = res.data.admin[0].wallet[0].USD;
        let activeuser = datauser.filter(ele=>ele.isvalid===true).length;
        let banneduser = datauser.filter(ele=>ele.isbanned===true).length;
        let muteduser = datauser.filter(ele=>ele.ismuted===true).length;
        let blockeduser = datauser.filter(ele=>ele.isblock===true).length;
        let totaluser = datauser.length;
    
        this.setState({activeuser,totaluser,admindata,banneduser,muteduser,blockeduser, wallet})
        console.log(datauser,activeuser,totaluser);
    }


    render(){
        const {admindata,totaluser,activeuser,banneduser,muteduser,blockeduser, wallet} = this.state;
        return(
            <AdminLayout navpanel={true}>
                <div className="admindashboard col-lg-12">
            <div className="content_ad">
            <div className="welcome_section row">
                <div
                className="banner col-md-12"
                style={{ backgroundImage: "url(" + banner + ")" }}
                >
                <h1 className="headding">Dashboard</h1>
                <p className="desc_ad">Welcome Administrator</p>
                </div>
            </div>
            <div className="content_wrapper">
                <div className="label_section row">
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="wrapper">
                    <div className="inner_wrapper">
                        <div className="show_label">Total users</div>
                        <div className="label_content">{totaluser}</div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="wrapper">
                    <div className="inner_wrapper">
                        <div className="show_label">Active users</div>
                        <div className="label_content">{activeuser}</div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="wrapper">
                    <div className="inner_wrapper">
                        <div className="show_label">Banned users</div>
        <div className="label_content">{banneduser}</div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="wrapper">
                    <div className="inner_wrapper">
                        <div className="show_label">Muted users</div>
        <div className="label_content">{muteduser}</div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="wrapper">
                    <div className="inner_wrapper">
                        <div className="show_label">Blocked User</div>
        <div className="label_content">{blockeduser}</div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 col-sm-6">
                    <div className="wrapper">
                    <div className="inner_wrapper">
                        <div className="show_label">Total Commission</div>
        <div className="label_content">{wallet.toFixed(2)} USD</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
            </AdminLayout>
        )
    }
} 