import React, { useEffect, useState } from 'react'
import './profile.css'
import Layout from '../layout'
import { Link } from 'react-router-dom'
import { Table, Form, Spinner } from 'react-bootstrap'
import { getParamByParam } from 'iso-country-currency'
import { server } from '../../config/keys.js'
import { userInstance } from '../../axios/axiosconfig'
import { NotificationManager } from 'react-notifications'
import { Button, Modal } from 'react-bootstrap'
import addmoney from '../../assets/images/home/addmoney-icon.png'
import withdraw from '../../assets/images/home/withdraw-icon.png'
import { useTranslation } from 'react-i18next'
import EditProfile from '../editprofile/editprofile'
import WithdrawMoney from '../withdrawMoney/withdrawMoney'
import Deposit from '../deposit/deposit'
import { cmsInstance } from '../../axios/axiosconfig'

const Profile = () => {
  const { t, i18n } = useTranslation()
  const [user, setUser] = useState([])
  const [preview, setPreview] = useState(null)
  const [pic, setPic] = useState(null)
  const [bonus, setBonus] = useState(null)
  const [deposit, setDeposit] = useState(null)
  const [tournamentBtn, setTournamentBtn] = useState(false)
  const UserId = localStorage.getItem('Userid')
  const [friendList, setFriendList] = useState([])
  const [receiveList, setReceiveList] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [recentDuels, setRecentDuels] = useState([])
  const [content, setContent] = useState({})
  const [page, setPage] = useState(1)
  const [recentTournament, setRecentTournament] = useState([])
  const [tourPage, setTourPage] = useState(1)
  const [ranking, setRanking] = useState([
    'Beginner',
    'Trainee',
    'Amateur',
    'Hustler',
    'Virtuoso',
    'Expert',
    'Veteran',
    'Semi-Pro',
    'Professional',
    'Master',
    'Champ',
    'Superstar',
    'Hero',
    'Legend',
    'Immortal',
  ])
  const [rank, setRank] = useState('')
  const [currency, setCurrency] = useState(null)
  // useEffect(() => {
  //     getProfile();
  //     getturnover();
  //     createtournamentEligible();
  //     duelHistory();
  //    //tournamentcreation();
  //    console.log("hhhhhhhhhhhhhh",user);
  // }, [])

  useEffect(() => {
    const getUserProfile = async () => {
      const response = await userInstance.get('/getprofile')
      if (response.data.code === 200) {
        setUser(response.data.profile_data)
        setBonus(response.data.profile_data.bonus[0])
        setDeposit(response.data.profile_data.deposit[0])
        setPic(response.data.profile_data.photo)
        const currencyCode = getParamByParam(
          'countryName',
          response.data.profile_data.country,
          'currency'
        )
        setCurrency(currencyCode)
      } else {
        window.location.href = '/login'
      }
      if (user) {
        if (response.data.profile_data.Level <= 2) setRank(ranking[0])
        else if (
          response.data.profile_data.Level > 2 &&
          response.data.profile_data.Level <= 5
        )
          setRank(ranking[1])
        else if (
          response.data.profile_data.Level > 5 &&
          response.data.profile_data.Level <= 9
        )
          setRank(ranking[2])
        else if (
          response.data.profile_data.Level > 9 &&
          response.data.profile_data.Level <= 14
        )
          setRank(ranking[3])
        else if (
          response.data.profile_data.Level > 14 &&
          response.data.profile_data.Level <= 20
        )
          setRank(ranking[4])
        else if (
          response.data.profile_data.Level > 20 &&
          response.data.profile_data.Level <= 27
        )
          setRank(ranking[5])
        else if (
          response.data.profile_data.Level > 27 &&
          response.data.profile_data.Level <= 35
        )
          setRank(ranking[6])
        else if (
          response.data.profile_data.Level > 35 &&
          response.data.profile_data.Level <= 44
        )
          setRank(ranking[7])
        else if (
          response.data.profile_data.Level > 44 &&
          response.data.profile_data.Level <= 54
        )
          setRank(ranking[8])
        else if (
          response.data.profile_data.Level > 54 &&
          response.data.profile_data.Level <= 65
        )
          setRank(ranking[9])
        else if (
          response.data.profile_data.Level > 65 &&
          response.data.profile_data.Level <= 77
        )
          setRank(ranking[10])
        else if (
          response.data.profile_data.Level > 77 &&
          response.data.profile_data.Level <= 90
        )
          setRank(ranking[11])
        else if (
          response.data.profile_data.Level > 90 &&
          response.data.profile_data.Level <= 104
        )
          setRank(ranking[12])
        else if (
          response.data.profile_data.Level > 104 &&
          response.data.profile_data.Level <= 119
        )
          setRank(ranking[13])
        else if (
          response.data.profile_data.Level > 120 &&
          response.data.profile_data.Level <= 136
        )
          setRank(ranking[14])
        else if (
          response.data.profile_data.Level > 136 &&
          response.data.profile_data.Level <= 150
        )
          setRank(ranking[15])
      }
    }
    getUserProfile()
    //getturnover();
    getAllPlayers()
    getLastDuels(1, 10)
    getRecentTournament(1, 10)
    createtournamentEligible()
    getContent()
    i18n.on('languageChanged', onLanguageChanged)
    // duelHistory();
  }, [])

  const onLanguageChanged = () => {
    console.log('language change', localStorage.getItem('activeLanguage'))
    getContent()
  }

  const getAllPlayers = async () => {
    const res = await userInstance.get('/friendlist')
    if (res.data.code === 200) {
      setFriendList(res.data.friend)
      setReceiveList(res.data.receive)
    }
  }
  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = '/profile'
      let language = localStorage.getItem('activeLanguage')
        ? localStorage.getItem('activeLanguage')
        : localStorage.getItem('i18nextLng')
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`)
      if (status === 200) {
        setContent(content)
      } else {
        NotificationManager.error(msg, content.message, 10000)
      }
    } catch (error) {
      console.log('Error', error.message)
    }
  }
  const getLastDuels = async (pageValue, paginationValue) => {
    setPage(pageValue)
    let filterBy = ''
    let p = pageValue ? pageValue : 1

    let payload = {
      data: filterBy,
      page: p,
      pagination: paginationValue,
    }

    const res = await userInstance.post('/recentDuel', payload)
    console.log('RecentDuels===>', res.data.duels, payload)
    if (res.data.code === 200) {
      setRecentDuels(res.data.duels)
    }
  }
  const getRecentTournament = async (pageValue, paginationValue) => {
    setTourPage(pageValue)
    let filterBy = ''
    let p = pageValue ? pageValue : 1

    let payload = {
      data: filterBy,
      page: p,
      pagination: paginationValue,
    }
    const res = await userInstance.post('/recentTournamentUser', payload)
    console.log("===?",res)
    if (res.data.code === 200) {
      setRecentTournament(res.data.tournament)
    }
  }

  const handleCancelReceiveRequest = async (id) => {
    console.log('CLicked CancelReceive Request')
    const payload = {
      requestid: id,
    }
    const res = await userInstance.post('/cancelReceiveRequest', payload)
    if (res.data.code === 200) {
      setFriendList(res.data.friend)
      setReceiveList(res.data.receive)
      NotificationManager.success(content.notification1, content.message, 4000)
    }
  }
  const handleAcceptRequest = async (id) => {
    console.log('CLicked CancelReceive Request')
    const payload = {
      requestid: id,
    }
    setDisabled(true)
    const res = await userInstance.post('/acceptReceiveRequest', payload)
    if (res.data.code === 200) {
      setDisabled(false)
      setFriendList(res.data.friend)
      setReceiveList(res.data.receive)
      NotificationManager.success(content.notification2, content.message, 4000)
    }
  }
  const handleUnFriend = async (id) => {
    console.log('CLicked CancelReceive Request')
    const payload = {
      requestid: id,
    }
    const res = await userInstance.post('/unfriendRequest', payload)
    if (res.data.code === 200) {
      setFriendList(res.data.friend)
      setReceiveList(res.data.receive)
      NotificationManager.success(content.notification3, content.message, 4000)
    }
  }
  const Item1 =
    receiveList &&
    receiveList.map((el, i) => {
      {
        return (
          <tr key={i}>
            <td>
              <img src={`${server}/` + el.photo} alt='' />
            </td>
            <td className='game-player-result__date'>{el.nickName}</td>
            <td className='game-player-result__vs'>{el.country}</td>
            <td className='game-player-result__vs'>{el.Level}</td>
            <td className='game-player-result__vs'>
              {el.online && el.online.isActive ? (
                content.online
              ) : el.online ? (
                <>
                  {parseInt(
                    Math.abs(
                      new Date(new Date().toISOString()).getTime() -
                        new Date(el.online.lastActive).getTime()
                    ).toString() /
                      1000 /
                      60 /
                      60
                  ) %
                    24 >
                    0 &&
                  parseInt(
                    Math.abs(
                      new Date(new Date().toISOString()).getTime() -
                        new Date(el.online.lastActive).getTime()
                    ).toString() /
                      1000 /
                      60 /
                      60
                  ) %
                    24 <=
                    24
                    ? content.offline
                    : `${parseInt(
                        (Math.abs(
                          new Date(new Date().toISOString()).getTime() -
                            new Date(el.online.lastActive).getTime()
                        ).toString() /
                          1000 /
                          60) %
                          60
                      )} ${content.minago}`}{' '}
                </>
              ) : (
                content.offline
              )}
            </td>
            <td></td>
            <td className='game-player-result__vs'>
              {/* <Button disabled ={disabled} onClick={() => handleAcceptRequest(el._id)}> {content.accept}</Button>{" "}
                     <Button onClick={() => handleCancelReceiveRequest(el._id)}> {content.cancel}</Button>
                    */}
              <i
                class='fa fa-plus-circle'
                aria-hidden='true'
                disabled={disabled}
                onClick={() => handleAcceptRequest(el._id)}
                style={{
                  fontSize: '25px',
                  marginRight: '20px',
                  cursor: 'pointer',
                }}
                data-toggle='tooltip'
                data-placement='top'
                title='Accept'
              ></i>
              <i
                class='fa fa-times'
                aria-hidden='true'
                onClick={() => handleCancelReceiveRequest(el._id)}
                style={{ fontSize: '25px', cursor: 'pointer' }}
                data-toggle='tooltip'
                data-placement='top'
                title='Reject'
              ></i>
            </td>
          </tr>
        )
      }
    })
  let start, currDate, today
  const Item =
    friendList &&
    friendList.map((el, i) => {
      {
        return (
          <tr key={i}>
            <td>
              <img src={`${server}/` + el.photo} alt='' />
            </td>
            <td className='game-player-result__date'>{el.nickName}</td>
            <td className='game-player-result__vs'>{el.country}</td>
            <td className='game-player-result__vs'>{el.Level}</td>
            <td className='game-player-result__vs'>
              {el.online && el.online.isActive ? (
                content.online
              ) : el.online ? (
                <>
                  {parseInt(
                    Math.abs(
                      new Date(new Date().toISOString()).getTime() -
                        new Date(el.online.lastActive).getTime()
                    ).toString() /
                      1000 /
                      60 /
                      60
                  ) %
                    24 >
                    0 &&
                  parseInt(
                    Math.abs(
                      new Date(new Date().toISOString()).getTime() -
                        new Date(el.online.lastActive).getTime()
                    ).toString() /
                      1000 /
                      60 /
                      60
                  ) %
                    24 <=
                    24
                    ? content.offline
                    : `${parseInt(
                        (Math.abs(
                          new Date(new Date().toISOString()).getTime() -
                            new Date(el.online.lastActive).getTime()
                        ).toString() /
                          1000 /
                          60) %
                          60
                      )} ${content.minago}`}{' '}
                </>
              ) : (
                content.offline
              )}
            </td>
            <td></td>
            <td className='game-player-result__vs'>
              <Button
                className='blue-btn'
                onClick={() => handleUnFriend(el._id)}
              >
                {content.unfriend}
              </Button>
            </td>
          </tr>
        )
      }
    })
  const createtournamentEligible = async () => {
    const res = await userInstance.get('/eligible')
    console.log('eligible criteria=>', res)
    if (res.data.code === 200) {
      setTournamentBtn(true)
    }
  }

  const handlePicChange = async (e) => {
    var f = e.target.files[0]
    let image_as_base64 = URL.createObjectURL(e.target.files[0])
    setPreview(image_as_base64)
    setPic(f)
  }
  const handlePicShow = async () => {
    setDisabled(true)
    var fd = new FormData()
    console.log(pic)
    if (pic.name) {
      fd.append('file', pic)
      const response = await userInstance.post('/addImg', fd)
      if (response.data.code === 200) {
        setDisabled(false)
        NotificationManager.success(content.notification5, content.message, 10000)
      } else if (response.data.code === 400) {
        setDisabled(false)
        NotificationManager.error(content.notification6, content.message, 10000)
      } else {
        setDisabled(false)
        NotificationManager.error(content.notification7, content.message, 10000)
      }
    } else {
      setDisabled(false)
      NotificationManager.error(content.notification4, content.message, 10000)
    }
  }
  const [editshow, setEditShow] = useState(false)
  const handleEditShow = () => setEditShow(true)
  const handleEditClose = () => setEditShow(false)

  const [depositshow, setDepositShow] = useState(false)
  const handleDepositShow = () => setDepositShow(true)
  const handleDepositClose = () => setDepositShow(false)

  const [withdrawshow, setWithdrawShow] = useState(false)
  const handleWithdrawShow = () => setWithdrawShow(true)
  const handleWithdrawClose = () => setWithdrawShow(false)

  return (
    <Layout>
      <div className='profile'>
        <div className='profile-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='title-page'>
                  <ul className='breadcrum'>
                    <li>
                      <Link to='/'>{content.home}</Link>
                    </li>
                    <li>{content.prof}</li>
                  </ul>
                  <h2>{content.prof}</h2>
                </div>
              </div>
            </div>
            <div className='row mb-100'>
              <div className='col-md-2'>
                <div className='user-profile'>
                  <div className='user-pic-box'>
                    {preview === null ? (
                      user.photo != 'public\\profileimages\\default.png' ? (
                        <img src={`${server}/` + user.photo} alt='' />
                      ) : (
                        <img
                          src={`${server}/public/profileimages/default.jpg`}
                          alt=''
                        />
                      )
                    ) : (
                      <img src={preview} />
                    )}
                    <Form>
                      <Form.Control
                        type='file'
                        className='profile-input'
                        onChange={handlePicChange}
                      />
                    </Form>
                  </div>
                  <Button
                    href='#'
                    className='edit-btn'
                    disabled={disabled}
                    onClick={handlePicShow}
                  >
                    {disabled ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      ''
                    )}
                    {content.upload}
                  </Button>
                </div>
              </div>
              <div className='col-md-5'>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.gamename}:
                      </div>
                      <div className='col-md-7'>{user.firstName}</div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.level}:
                      </div>
                      <div className='col-md-7'>
                        {'Level '} {user.Level}
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.ranking}:
                      </div>
                      <div className='col-md-7'>{rank}</div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>{content.name}:</div>
                      <div className='col-md-7'>{user.nickName}</div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.email}:
                      </div>
                      <div className='col-md-7'>{user.email}</div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.country}:
                      </div>
                      <div className='col-md-7'>{user.country}</div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.uniquereferal}:
                      </div>
                      <div className='col-md-7'>
                        <a
                          href={`${server}/user/invite/` + user._id}
                          target='_blank'
                        >
                          {`${server}/user/invite/`}
                          {user._id}
                        </a>
                      </div>
                    </div>
                    <a
                      href='#'
                      className='blue-btn edit-btn'
                      onClick={handleEditShow}
                    >
                      {content.editprofile}
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-5'>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.bonus}:
                      </div>
                      <div className='col-md-7'>
                        {user.bonusTurnOver
                          ? user.bonusTurnOver.toFixed(2)
                          : 0.0}{' '}
                        {' USD'}
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.winpercentage}:
                      </div>
                      <div className='col-md-7'>
                        {user.gameWon && user.gameLoss
                          ? (
                              (user.gameWon * 100) /
                              (user.gameLoss + user.gameWon)
                            ).toFixed(2)
                          : 0.0}{' '}
                        %
                        {/* {((user.gameWon + user.tournamentWon)/ user.gameLose)*100}% */}
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.totalwin}:
                      </div>
                      <div className='col-md-7'>
                        {user &&
                          user.totalWinPrize &&
                          user.totalWinPrize.toFixed(2)}
                        {' USD'}
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.duelswon}:
                      </div>
                      <div className='col-md-7'>{user.gameWon}</div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.tournamentwon}:
                      </div>
                      <div className='col-md-7'>{user.tournamentWon}</div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.bonusaccount}:
                      </div>
                      <div className='col-md-7'>
                        {bonus && <span>{bonus.USD.toFixed(2)}</span>} USD
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-5 text-muted'>
                        {content.despositaccount}:
                      </div>
                      <div className='col-md-7'>
                        {deposit && <span>{deposit.USD.toFixed(2)}</span>} USD
                      </div>
                    </div>
                    <Button className='border-btn' onClick={handleDepositShow}>
                      <img src={addmoney} /> {content.addmoney}
                    </Button>
                    <Button className='border-btn' onClick={handleWithdrawShow}>
                      <img src={withdraw} />
                      {content.withdrawmoney}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 mb-100'>
                <h4 className='litle-title mb-3'>{content.recentduels}</h4>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <div className='home-table '>
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th>{content.matchtype}</th>
                            <th>{content.betAmt}</th>
                            <th>{content.winAmt}</th>
                            <th className='date'>{content.date}</th>
                            <th>{content.opponent}</th>
                            <th>{content.score}</th>
                            <th>{content.result}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recentDuels &&
                            recentDuels.map((ele,i) => {
                              return ele.matches.reverse().map((match,j) => {
                                return (
                                  <tr key={`item-${i}${j}`}>
                                    <td>
                                      {ele.gameName ? <>{ele.gameName}</> : ''}
                                    </td>
                                    <td>
                                      {ele.betAmount}
                                      {' USD'}
                                    </td>
                                    <td>
                                      {ele.betPot} {' USD'}
                                    </td>
                                    <td>
                                      {ele.updatedAt &&
                                        ele.updatedAt.substring(0, 10)}
                                    </td>
                                    <>
                                      {ele.players[0].toString() ===
                                      UserId.toString() ? (
                                        match.player_one_score >
                                        match.player_two_score ? (
                                          <>
                                            <td>
                                              {ele.playersData[1].name
                                                ? ele.playersData[1].name
                                                : ''}
                                            </td>
                                            <td>
                                              {match.walkover ? 'Walkover' :match.player_one_score + " : " + match.player_two_score }
                                            </td>
                                            <td>{content.winner}</td>
                                          </>
                                        ) : (
                                          <>
                                            <td>
                                              {ele.playersData[1].name
                                                ? ele.playersData[1].name
                                                : ''}
                                            </td>
                                            <td>
                                            {match.walkover ? 'Walkover' :match.player_one_score + " : " + match.player_two_score }
                                            </td>
                                            <td>{content.loser}</td>
                                          </>
                                        )
                                      ) : match.player_one_score >
                                        match.player_two_score ? (
                                        <>
                                          <td>
                                            {ele.playersData[0].name
                                              ? ele.playersData[0].name
                                              : ''}
                                          </td>
                                          <td>
                                          {match.walkover ? 'Walkover' :match.player_one_score + " : " + match.player_two_score }
                                          </td>
                                          <td>{content.loser}</td>
                                        </>
                                      ) : (
                                        <>
                                          <td>
                                            {ele.playersData[0].name
                                              ? ele.playersData[0].name
                                              : ''}
                                          </td>
                                          <td>
                                            {match.walkover ? 'Walkover' :match.player_one_score + " : " + match.player_two_score }
                                          </td>
                                          <td>{content.winner}</td>
                                        </>
                                      )}
                                    </>
                                  </tr>
                                )
                              })
                            })}
                          {recentDuels.length === 0 ? (
                            <tr
                              style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <td>{content.noDuel}</td>
                            </tr>
                          ) : (
                            ''
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <div className='pagination-section'>
                      {page > 1 ? (
                        <button
                          onClick={() => getLastDuels(page - 1, 10)}
                          className='previous'
                        >
                          {content.previous}
                        </button>
                      ) : (
                        ''
                      )}
                      {recentDuels.length === 10 ? (
                        <button
                          onClick={() => getLastDuels(page + 1, 10)}
                          className='next'
                        >
                          {content.next}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 mb-100'>
                <h4 className='litle-title mb-3'>{content.recenttournaments}</h4>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <div className='home-table '>
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th>{content.game}</th>
                            <th>{content.winner}</th>
                            <th>{content.platform}</th>
                            <th>{content.prize}</th>
                            <th>{content.players}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recentTournament &&
                            recentTournament.map((value, i) => {
                              return [
                                <tr key={i}>
                                  <td>
                                    <b>{value.title} : </b>{' '}
                                    {value.tournamentStart}{' '}
                                    {value.tournamentEntryFee}
                                  </td>
                                  <td className='winner'>
                                    {value.winner.length === 3 ? (
                                      <>
                                        1st : {value.winner[0]}, 2nd :{' '}
                                        {value.winner[1]}, 3rd :{' '}
                                        {value.winner[2]}{' '}
                                      </>
                                    ) : value.winner.length === 2 ? (
                                      <>
                                        1st : {value.winner[0]}, 2nd :{' '}
                                        {value.winner[1]}
                                      </>
                                    ) : (
                                      value.winner[0]
                                    )}
                                  </td>
                                  <td>XBOX</td>
                                  <td className='prize'>
                                    {value.amt.length === 3 ? (
                                      <>
                                        {' '}
                                        1st : {value.amt[0]}, &nbsp; 2nd:{' '}
                                        {value.amt[1]}, &nbsp; 3rd:{' '}
                                        {value.amt[2]}
                                      </>
                                    ) : value.amt.length === 2 ? (
                                      <>
                                        1st : {value.amt[0]}, &nbsp; 2nd:{' '}
                                        {value.amt[1]}
                                      </>
                                    ) : (
                                      <>{value.amt[0]}</>
                                    )}
                                  </td>
                                  <td>{value.playerNumbers}</td>
                                </tr>,
                              ]
                            })}
                          {recentTournament.length === 0 ? (
                            <tr
                              style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <td></td>
                              <td colSpan={4}>{content.noTournament}</td>
                            </tr>
                          ) : (
                            ''
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <div className='pagination-section'>
                      {tourPage > 1 ? (
                        <button
                          onClick={() => getRecentTournament(tourPage - 1, 10)}
                          className='previous'
                        >
                          {content.previous}
                        </button>
                      ) : (
                        ''
                      )}
                      {recentTournament.length === 10 ? (
                        <button
                          onClick={() => getRecentTournament(tourPage + 1, 10)}
                          className='next'
                        >
                          {content.next}
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 my-friends'>
                <h4 className='litle-title mb-3'>{content.myfriend}</h4>
                <Link to='/friend'>{content.seeall}</Link>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <div className='home-table'>
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{content.nickName}</th>
                            <th>{content.country}</th>
                            <th>{content.level}</th>
                            <th>{content.online}</th>
                            {/* <th></th> */}
                            <th>{content.option}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {friendList && friendList.length !== 0 ? (
                            Item
                          ) : (
                            <tr
                              style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <td colSpan={5}>{content.nofriend}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 my-friends'>
                <h4 className='litle-title mb-3'>{content.friendRequest}</h4>
                <div className='card mb-4'>
                  <div className='card-body'>
                    <div className='home-table'>
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{content.nickName}</th>
                            <th>{content.country}</th>
                            <th>{content.level}</th>
                            <th>{content.online}</th>
                            {/* <th></th> */}
                            <th>{content.option}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {receiveList && receiveList.length !== 0 ? (
                            Item1
                          ) : (
                            <tr
                              style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <td colSpan={5}>{content.nofriend}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName='dod-popup'
        show={editshow}
        onHide={handleEditClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <EditProfile />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName='dod-popup'
        show={depositshow}
        onHide={handleDepositClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Deposit />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName='dod-popup'
        show={withdrawshow}
        onHide={handleWithdrawClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <WithdrawMoney currency={currency} />
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default Profile
