import React, { useEffect, useState } from "react";
import "./withdraw.css";
import Layout from '../layout';
import { userInstance, adminInstance } from "../../axios/axiosconfig";
import {Link} from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Bet from '../home/bet';
import Popup from '../home/popup';
const SeriesGame = ({socket}) => {
  const { t, i18n } = useTranslation();
  
  const [level, setLevel] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [country, setCountry] = useState(null);
  const [photo , setPhoto] = useState(null);
  const [startTimer, setstartTimer] = useState(false);
  const [matched, setMatched] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const Userid = localStorage.getItem("Userid");
  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);
  const [playerDetails, setPlayerDetails] = useState(null);
  const [banned, setBanned] = useState(null);
  const [bet, setBet] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);
  
  useEffect(() => {
    const getUserProfile = async () => {
      const response = await userInstance.get("/getprofile");
      if (response.data.code === 200) {
        setBanned(response.data.profile_data.isbanned);
          setLevel(response.data.profile_data.Level)
          setPlayerOne(response.data.profile_data)
         setNickName(response.data.profile_data.nickName)
         setCountry(response.data.profile_data.country)
          setPhoto(response.data.profile_data.photo)
      }
    };
    getUserProfile();
  }, []);
  const handlePlay =() => {
      setBet(true);
  }
  const matchRoom = async (amount, walletType) => {
    const res = await adminInstance.post("/getCommission")
    console.log("Admin API", res.data.rate)
    const cutOff = ((res.data.rate * amount)/100).toFixed(2);
    // const response = await userInstance.post("/getprofile");
    // console.log("responseData", walletType);
    socket.emit("matchMaking", {
      Userid,
      level: level,
      socketId: socket.id,
      bet: amount,
      adminCutOff: cutOff,
      betAmt: amount - cutOff,
      walletType: walletType,
      nickName: nickName,
      country: country,
      photo: photo
    });
    console.log(playerOne);
    setBetAmount(amount);
    setBet(false);
    setWallettype(walletType);
    setMatched(true)
  };
  const CloseBetComponent=()=>{
    setBet(false);
  }
  const handleTimerPopup = e => {
    if (e === `close`) {
      setstartTimer(false);
      // socket.emit("cancel", {
      //   Userid
      // });
    }
  };
  useEffect(() => {
    // socket.emit("disconnect", {
    //   Userid
    // });
    socket.on('playerPopUp', data => {

      if (Userid === data.matched[0].Userid) {
        setPlayerOne(data.matched[0])
        setPlayerTwo(data.matched[1])
      } else {
        setPlayerOne(data.matched[1])
        setPlayerTwo(data.matched[0])
      }

    })

    socket.on("play", data => {
      // loadRoom();
      setPlayerDetails(data.matched);
      setRoomId(data.roomId);
      setstartTimer(false);
      handleTimerPopup(`close`);
      setMatched(true);
      console.log("Game Room :",roomId);
      console.log("Player Detasils", playerDetails);
      // setBet(true);

      socket.emit("server", {
        data
      });

      socket.emit('betAmount', data => {
        setBet(false);
      })
    });
  }, [startTimer]);
  return (
    <Layout>
      <div className="withdraw">
        <div className="container">

        <div className="withdraw-content">
        {matched && (
          <Popup
            roomId={roomId}
            setMatched={setMatched}
            playerOne={playerOne}
            playerTwo={playerTwo}
            socket={socket}
            amount={betAmount}
            wallettype={wallettype}
          />
        )}
        {bet && (
          <Bet
            socket={socket}
            matchRoom={matchRoom}
            CloseBetComponent={CloseBetComponent}
          ></Bet>
        )}
           
            <h3>Bouns Wallet amount transfer to deposit Wallet</h3>
            <p>
                1. Bonus wallet amount can not be withdrwan, It can transfer to deposit wallet only.<br/>
                2. To transfer bonus amount to deposit amount you have to win three game with<br/>
                same bet amount.
                3. All bet amount deducted from Deposit wallet. So please set your active wallet as Deposit
                4. After wining 3 games continuously with same bet amount X % of Bonus will be transfer to your deposit wallet
                
                
            </p>
            <Button type="button" className="blue-btn" onClick ={handlePlay}>Play</Button>{"  "}
            <Button type="button" className="red-btn"><Link to='/profile'>Cancel</Link></Button>
            
             

               
               
              
        </div>
        </div>
      </div>
      </Layout>
  );
};

export default SeriesGame;
