import React from "react";
import './user-list.css';
import PopUP from "../popUp";

export default class UserList extends React.Component {
  constructor(props) {
    super(props);
  }


  notify = (id) => {
    console.log('function is called!', id)
    window.location.href = `/admin/notify?userId=${id}`;
  }
   sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("user-table");
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount ++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
  render() {

    const Item = this.props.list.map((el, i) => {
      return (
        <tr key={i}>
          <td className="game-player-result__date">{el.nickName}</td>
          <td className="game-player-result__date">{el.email}</td>
          <td className="game-player-result__vs">{el.country}</td>
          <td className="game-player-result__vs">{new Date(el.createdAt).toDateString()}</td>
          <td className="game-player-result__vs text-center">

            {(el.isblock === true) ?
              <label className="switch">
                <input
                  className="switch-input"
                  type="checkbox"
                  name="block"
                  value={el._id}
                  data-block={true}
                  onClick={this.props.handleBlock.bind(this, el._id, true)}
                  checked
                />
                <span className="switch-label" data-on="Unblock" data-off="Block"></span> <span className="switch-handle"></span>
              </label>
              :
              <label className="switch">
                <input
                  className="switch-input"
                  type="checkbox"
                  name="block"
                  value={el._id}
                  data-block={false}
                  onClick={this.props.handleBlock.bind(this, el._id, false)}
                />
                <span className="switch-label" data-on="Unblock" data-off="Block"></span> <span className="switch-handle"></span>
              </label>
            }
          </td>
          <td className="game-player-result__vs text-center">

            {(el.isbanned === true) ?
              <label className="switch">
                <input
                  className="switch-input"
                  type="checkbox"
                  name="banned"
                  value={el._id}
                  data-banned={true}
                  onClick={this.props.handleBanned.bind(this, el._id, true)}
                  checked
                />
                <span className="switch-label" data-on="Unbanned" data-off="Banned"></span> <span className="switch-handle"></span>
              </label>
              :
              <label className="switch">
                <input
                  className="switch-input"
                  type="checkbox"
                  name="banned"
                  value={el._id}
                  data-banned={false}
                  onClick={this.props.handleBanned.bind(this, el._id, false)}
                />
                <span className="switch-label" data-on="Unbanned" data-off="Banned"></span> <span className="switch-handle"></span>
              </label>
            }
          </td>
          <td>
            <button className="btn btn-primary btn-sm" onClick={this.notify.bind(this, el._id)}>Notify</button>
          </td>
        </tr>
      );
    });
    return (
      <div className="card__content">
        {this.props.popup?<PopUP popup={this.props.popup} Userid={this.props.currentUserId} 
        handlePopup={this.props.handlePopup}
        />:""}
        {Item ? <div className="table-responsive">
          <table className="table table-hover game-player-result user-list" id='user-table'>
            <thead>
              <tr>
                <th className="game-player-result__date text-left" onClick={() => this.sortTable(0)}>Name</th>
                <th className="game-player-result__date text-left" onClick={() =>this.sortTable(1)}>Email</th>
                <th className="game-player-result__vs text-left" onClick={() =>this.sortTable(2)}>Country</th>
                <th className="game-player-result__vs text-left" onClick={() =>this.sortTable(3)}>Registration Date</th>
                <th className="game-player-result__character text-center" onClick={() =>this.sortTable(4)}>
                  Block
                </th>
                <th className="game-player-result__character text-center" onClick={() =>this.sortTable(5)}>
                  Banned
                </th>
                <th className="game-player-result__character text-center">
                  Chat
                </th>
              </tr>
            </thead>
            <tbody>{this.props.list.length !== 0 ? Item : <tr><td colSpan={3}>No User found</td></tr>}</tbody>
          </table>
        </div>
        :""}
      </div>
    );
  }
}
