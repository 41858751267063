import React, { useEffect, useState } from "react";
import "./howitworks.css";
import Layout from '../layout'
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import match from "../../assets/images/howitworks/icon-match.png";
import fight from "../../assets/images/howitworks/icon-fight.png";
import payout from "../../assets/images/howitworks/icon-payout.png";
import signup from "../../assets/images/howitworks/icon-signup.png";
import checkin from "../../assets/images/howitworks/icon-checkin.png";
import royal from "../../assets/images/howitworks/icon-royal.png";
import date from "../../assets/images/howitworks/icon-date.png";
import waiting from "../../assets/images/howitworks/icon-waiting.png";
import { useTranslation } from "react-i18next";
import { cmsInstance, userInstance } from '../../axios/axiosconfig';
import { NotificationManager } from 'react-notifications';

const Howitworks = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({});

    const loadRoom = async () => {
        const { data } = await userInstance.post("/createRoom");
        console.log(data);
        const { roomId } = data;
        if (roomId) {
          window.location.href = `/pong?roomId=${roomId}`;
        }
        else {
          window.location.href = "/login";
        }
      };
    const getContent = async () => {
        try {
            const page = window.location.pathname.replace('/cms/', '');
            let language = localStorage.getItem("activeLanguage");
            const { data: { content, msg }, status } = await cmsInstance.get(`/content/howitwork/${language}`);
            if (status == 200) {
                setContent(content);
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        getContent();
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const onLanguageChanged = () => {
        getContent();
    }

    return (


        <Layout>
            <div className="how-it-works-page">


                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-page">
                                <ul className="breadcrum">
                                    <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.heading1}</li>
                                </ul>
                                <h2>{content.heading1}</h2>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="how-main-box">

                    <div className="container">

                        <div className="how-box border-line">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="left-content">
                                        <p>{content.para}</p>
                                        <p>{content.para1}</p>
                                        <p className="span-content">{content.para2}</p>
                                        
                                        <p>{content.para3}</p>
                                        <ul>
                                        <li>
                                            {content.label1}
                                            </li>
                                            <li>
                                            {content.label2}
                                            </li>
                                            <li>
                                            {content.label3}
                                            </li>
                                            <li>
                                            {content.label4}
                                            </li>
                                            <li>
                                            {content.label5} 
                                            </li>
                                           
                                        </ul>

                                    </div>
                                </div>



                                <div className="col-md-7">
                                    <div className="right-content">
                                        <h2>{content.heading2}</h2>
                                            <p className="duel-description">{content.dueldesc}</p>
                                        <div className="info-box">
                                            <img src={match} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.heading3}</h4>
                                                <p>{content.newpara1}</p>
                                                <p>{content.para4}</p>
                                                <p>{content.newpara2}</p>
                                            </div>
                                        </div>

                                        <div className="info-box">
                                            <img src={fight} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.heading4}</h4>
                                                <p>{content.para5}</p>
                                            </div>
                                        </div>


                                        <div className="info-box">
                                            <img src={payout} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.heading5}</h4>
                                                <p>{content.para6}</p>
                                                <p>{content.newpara3}</p>
                                            </div>
                                        </div>

                                        <Button onClick={loadRoom} className="red-btn">{content.startduel}</Button>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="how-box border-line">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="left-content">
                                        <p className="textright">{content.contentthird}</p>
                                        <div className="info-box-new1">
                                        <h4 className="textright-heading">{content.contentfourth1}</h4>
                                        <p  className="textright">{content.contentfourth}</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-7">
                                    <div className="right-content">
                                        <h2>{content.tournament}</h2>

                                        <div className="info-box">
                                            <img src={signup} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.signup}</h4>
                                                <p>{content.signuppara}</p>
                                            </div>
                                        </div>

                                        <div className="info-box">
                                            <img src={checkin} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.checkin}</h4>
                                                <p>{content.checkinpara}</p>
                                            </div>
                                        </div>


                                        <div className="info-box">
                                            <img src={fight} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.heading4}</h4>
                                                <p>{content.fight2para}</p>
                                            </div>
                                        </div>

                                        <div className="info-box">
                                            <img src={payout} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.heading5}</h4>
                                                <p>{content.payoutpara}</p>
                                            </div>
                                        </div>


                                        <Button className="blue-btn"><Link to='/tournament' className="search-tournament">{content.searchtournament}</Link></Button>

                                    </div>
                                </div>

                            </div>
                        </div>
                        
                        <div className="how-box">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="left-content">
                                   
                                        <div className="info-box-new">
                                            
                                            <div className="info-list">
                                                <h4>{content.setpriceleft}</h4>
                                                <p>{content.setpriceleftpara1}</p>
                                                <p>{content.setpriceleftpara2}</p>
                                                <p>{content.setpriceleftpara3}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-7">
                                    <div className="right-content">
                                        <h2>{content.newtournament}</h2>

                                        <div className="info-box">
                                            <img src={royal} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.royalggamer}</h4>
                                                <p>{content.royalgamer}</p>
                                            </div>
                                        </div>

                                        <div className="info-box">
                                            <img src={payout} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.setprice}</h4>
                                                <p>{content.setpricepara1}</p>
                                                <p>{content.setpricepara2}</p>
                                            </div>
                                        </div>


                                        <div className="info-box">
                                            <img src={date} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.setdate}</h4>
                                                <p>{content.setdatepara}</p>
                                                <p>{content.setdatepara1}</p>
                                            </div>
                                        </div>

                                        <div className="info-box">
                                            <img src={waiting} alt="match" />
                                            <div className="info-list">
                                                <h4>{content.waiting}</h4>
                                                <p>{content.waitingpara}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Howitworks;