import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import tile1 from "../../assets/images/home/tile-4.png";

var settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 500000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Hometiletwo = ({ content }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="home-tile-slider">
      <Slider {...settings}>
        <div className="home-slider-image tile-4">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.letgame}</span>
            <span>{content && content.withfriends}</span>
          </div>
        </div>
        <div className="home-slider-image tile-4">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.letgame}</span>
            <span>{content && content.withfriends}</span>
          </div>
        </div>
        <div className="home-slider-image tile-4">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.letgame}</span>
            <span>{content && content.withfriends}</span>
          </div>
        </div>
        <div className="home-slider-image tile-4">
          <img src={tile1} alt="sliderimage" />
          <div className="home-tile-info">
            <span>{content && content.letgame}</span>
            <span>{content && content.withfriends}</span>
          </div>
        </div>
      </Slider>
    </div>
  );
};
export default Hometiletwo;
