import React, { useEffect, useState } from "react";
import Layout from '../layout'
import "./login.css";
// import logo from "../../assets/images/header/logo.png";
import { Form, Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { validateData } from "../../function/function";
import { NotificationManager } from "react-notifications";
import { userInstance,cmsInstance } from "../../axios/axiosconfig";
import Facebook from '../facebook';
import Google from '../google';
import { useTranslation } from "react-i18next";
import Signup from "../signup/signup"
import Forgetpass from "../forget/forget"



const INITIAL_STATE = {
    email: '',
    password: ''
}

const Login = () => {

    const { t, i18n } = useTranslation();


    const [values, setValues] = useState(INITIAL_STATE);
    const [errPassword, setErrPassword] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [content, setContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getContent();
    },[])
    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/login';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            setIsLoading(true);
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isValid = validateData([
            values.email,
            values.password
        ]);
        if (isValid) {
            try {
                const response = await userInstance.post("/login", values);
                console.log('response', response);
                const statusCode = response.data.code;
                const msg = response.data.msg;
                if (statusCode === 400) {
                   setErrEmail(content.errEmail2); 
                } else if(statusCode === 401){
                    setErrEmail(content.errEmail3);
                    setErrPassword(content.errPassword1);
                }else if(statusCode === 403){
                    setErrEmail(content.errEmail4);
                }
                else{
                    localStorage.setItem('token', response.data.token);
                    let payload = tokenparser(response.data.token);
                    localStorage.setItem('Userid', payload.userid);
                    let path = localStorage.getItem('lastlocation');
                    window.location.href = `${path}`
                }
            } catch (error) {
                console.log('error', error);
            }
        } else {
           
            if(values.email === ""){
                setErrEmail(content.errEmail1);
              }else {
            var atposition=values.email.indexOf("@");  
            var dotposition=values.email.lastIndexOf(".");  
            if (atposition<1 || dotposition<atposition+2 || dotposition+2>=values.email.length){ 
                
                    setErrEmail(content.errEmail);
                } else {
                    setErrEmail("");
                }
            }
            if(values.password === ""){
                setErrPassword(content.errPassword);
            }
        }
    }
    const tokenparser = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }





    const [signupshow, setSignupShow] = useState(false);
    const handleSignupShow = () => setSignupShow(true);
    const handleSignupClose = () => setSignupShow(false);

    const [forgetshow, setForgetShow] = useState(false);
    const handleForgetShow = () => setForgetShow(true);
    const handleForgetClose = () => setForgetShow(false);


    return (
        <>
        {isLoading && (
        <div className="login">
           
            <div className="login-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="login-form">
                                <h1>{content.loginon} <span>{content.dayofduel} </span></h1>

                                <div className="social-login">
                                <span className ="loginBtn loginBtn--facebook"><Facebook btntext ={content.fbbtntext}/></span>
                                        <span className="loginBtn loginBtn--google"><Google btnText ={content.ggbtntext}/></span>
                                </div>

                                <div className="login-box">
                                    <Form onSubmit={handleSubmit}>

                                        <div className="row">
                                        <div className="col-md-6">
                                                <Form.Group controlId="formBasicloginOne">
                                                    <Form.Label>{content.emailaddress}</Form.Label>
                                                    {errEmail === "" ?
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={content.youremail}
                                                            name="email"
                                                            onChange={handleChange}
                                                            value={values.email}
                                                        /> :
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Your e-mail"
                                                            name="email"
                                                            className="showError"
                                                            onChange={handleChange}
                                                            value={values.email}
                                                        />}
                                                    <label className="text-danger">{errEmail}</label>
                                                </Form.Group>

                                            </div>

                                             <div className="col-md-6">
                                                <Form.Group controlId="formBasicPassword">
                                                    <Form.Label>{content.password}</Form.Label>
                                                    {errPassword === "" ? <Form.Control
                                                        type="password"
                                                        placeholder={content.yourpassword}
                                                        name="password"
                                                        onChange={handleChange}
                                                        value={values.password} />
                                                        :
                                                        <Form.Control
                                                            type="password"
                                                            className="showError"
                                                            placeholder="8 Characters with Alpha Numeric and Symbols"
                                                            name="password"
                                                            onChange={handleChange}
                                                            value={values.password} />}
                                                    <label className="text-danger">{errPassword}</label>
                                                </Form.Group>
                                            </div>
                                        </div>


                                        <div className="login-button">
                                            <Button type="submit" className="red-btn" >
                                            {content.login}
                                           </Button>
                                        </div>


                                        <div className="forget-password">
                                        {content.para1} <Link className="forget-link" onClick={setForgetShow}>
                                        {content.para2}<span>{'>>'}</span>
                                            </Link>
                                        </div>

                                    </Form>
                                    <p>{content.para3} <Link className="signup-link" onClick={setSignupShow}>
                                    {content.signup} <span>{'>>'}</span>
                                    </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal dialogClassName="dod-popup dark-popup" show={signupshow} onHide={handleSignupClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Signup />
                </Modal.Body>
            </Modal>


            <Modal dialogClassName="dod-popup dark-popup" show={forgetshow} onHide={handleForgetClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Forgetpass />
                </Modal.Body>
            </Modal> 




        </div>
        )}
        </>
    );
};

export default Login;
