import React, { useEffect, useState } from "react";
import "./runningduels.css";
import Layout from '../layout'
import player1 from "../../assets/images/home/player1.png";
import player2 from "../../assets/images/home/player2.png";
import player3 from "../../assets/images/home/player3.png";
import player4 from "../../assets/images/home/player4.png";
import player5 from "../../assets/images/home/player5.png";
import player6 from "../../assets/images/home/player6.png";
import { useTranslation } from "react-i18next";


const Runningduels = () => {

    const { t, i18n } = useTranslation();
    const [runningduelsData, setRunningDuelsData] = useState([
        { title: "Pong Game", subtitle: "Day of Duel", badge: "Playing", entryfee: "10 €", plateform: "DOD", prize: "12 €", player1: "Abigail", player1img: player1, player2: "Bella", player2img: player2, },
        { title: "Pool", subtitle: "Day of Duel", badge: "Playing", entryfee: "25 €", plateform: "DOD", prize: "95 €", player1: "Claire", player1img: player3, player2: "Diana", player2img: player4, },
        { title: "Fifa 2020", subtitle: "Day of Duel", badge: "Playing", entryfee: "5 €", plateform: "XBOX", prize: "11 €", player1: "Emma", player1img: player5, player2: "Jasmine", player2img: player6, },
        { title: "Pong Game", subtitle: "Day of Duel", badge: "Playing", entryfee: "10 €", plateform: "DOD", prize: "12 €", player1: "Abigail", player1img: player1, player2: "Bella", player2img: player2, },
        { title: "Pool", subtitle: "Day of Duel", badge: "Playing", entryfee: "25 €", plateform: "DOD", prize: "95 €", player1: "Claire", player1img: player3, player2: "Diana", player2img: player4, },
        { title: "Fifa 2020", subtitle: "Day of Duel", badge: "Playing", entryfee: "5 €", plateform: "XBOX", prize: "11 €", player1: "Emma", player1img: player5, player2: "Jasmine", player2img: player6, },
        { title: "Pong Game", subtitle: "Day of Duel", badge: "Playing", entryfee: "10 €", plateform: "DOD", prize: "12 €", player1: "Abigail", player1img: player1, player2: "Bella", player2img: player2, },
        { title: "Pool", subtitle: "Day of Duel", badge: "Playing", entryfee: "25 €", plateform: "DOD", prize: "95 €", player1: "Claire", player1img: player3, player2: "Diana", player2img: player4, },
        { title: "Fifa 2020", subtitle: "Day of Duel", badge: "Playing", entryfee: "5 €", plateform: "XBOX", prize: "11 €", player1: "Emma", player1img: player5, player2: "Jasmine", player2img: player6, }

    ])




    return (




        <Layout>

            <section className="running-duels">
                <div className="container">
                    <div className="page-title-dark">
                        <h2>{t("runningduels.running_duels")}</h2>
                        <h3>{t("runningduels.running")} <span>{t("runningduels.duels")}</span></h3>
                    </div>
                    <div className="row">


                        {runningduelsData.map((el, i) => {
                            return (
                                <div class="col-md-6 col-lg-4">
                                    <div class="card shadow-effect">
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-8">
                                                    <h4 class="card-headline">
                                                        <strong>{el.title}</strong>
                                                    </h4>
                                                    <h4 class="card-subline">{el.subtitle}</h4>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-badge">
                                                        <span class="badge badge-info">{el.badge}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body row no-gutters">
                                            <div class="card-body-item col-4">
                                                <div class="card-body-item-inner">
                                                    <div class="card-body-headline">{el.entryfee}</div>
                                                    <div class="card-body-subline">{t("runningduels.entryfee")}</div>
                                                </div>
                                            </div>
                                            <div class="card-body-item col-5">
                                                <div class="card-body-item-inner">
                                                    <div class="card-body-headline">{el.plateform}</div>
                                                    <div class="card-body-subline"><span class="translation_missing">{t("runningduels.platform")}</span></div>
                                                </div>
                                            </div>
                                            <div class="card-body-item card-body-item-prize col-3">
                                                <div class="card-body-item-inner">
                                                    <div class="card-body-headline">
                                                        <strong>{el.prize}</strong>
                                                    </div>
                                                    <div class="card-body-subline">{t("runningduels.prize")}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <div class="vs-wrapper row no-gutters">
                                                <div class="vs-item col-5">
                                                    <a class="vs-name" href="#">{el.player1}</a>
                                                    <a class="avatar avatar- avatar-sm avatar-false" href="#">
                                                        <div class="avatar-image-wrapper"><img class="avatar-image" src={el.player1img} alt="" /></div>
                                                    </a>

                                                </div>
                                                <div class="vs-vs col-2">
                                                    <span>vs.</span>
                                                </div>
                                                <div class="vs-item col-5">

                                                    <a class="avatar avatar- avatar-sm avatar-false" href="#">
                                                        <div class="avatar-image-wrapper"><img class="avatar-image" src={el.player2img} alt="" /></div>
                                                    </a>
                                                    <a class="vs-name" href="#">{el.player2}</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}








                    </div>
                </div>
            </section>

        </Layout>


    );
};

export default Runningduels;