import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import CommissionList from "./components/commission-list";
import "./style.css";
import { validateData } from '../../../function/function';
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
export default class Commission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{ id: `hjh` }],
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      rate: '',
      commissionRate: ''
    };
  }

  componentDidMount = () => {
    this.getCommission();
  }

  getCommission = async (req, res) => {
    let filterBy = '';
    let payload = {
      data: filterBy
    }
    const response = await adminInstance.post('/getCommission', payload);
    console.log('response',response);
    if (response.data.code == 200) {
      const rate = response.data.rate;
      this.setState({
        commissionRate: rate
      });
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  createCommission = async (e) => {
    const payload = {
      rate: this.state.rate
    };
    if(this.state.rate<0){
      NotificationManager.error("Please enter vailid entery","Message",4000);
      return null;
    }
    const isValid = validateData([
      payload.rate
    ]); 

    if (isValid) {
      const response = await adminInstance.post('/setCommission', payload);
      
      if (response.data.code == 200) {
            this.setState({
          commissionRate:response.data.rate
        });
        NotificationManager.success("Commission Updated!", "Message", 4000);
      }
    } else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
  }

  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-6">
              <div className="card__content">
                <SectionHeader title="Update Commission" />
                <div className="card">
                  <div className="card__content">
                    <InputLabel
                      label={`Rate`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please enter Commission Rate`}
                      name={"rate"}
                      value={this.state.rate}
                      handlechange={this.handleChange}
                    />
                    <div className="form-group mb-0 text-right">
                      <button className="btn btn-primary-inverse" onClick={this.createCommission}>
                        Update
                      </button>
                    </div>
                    <p style={{color:"white", marginTop:"10px"}}>Commision Rate: {this.state.commissionRate} %</p>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
