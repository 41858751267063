import React from "react";
import './user-list.css';
import PopUP from "../popUp";

export default class UserList extends React.Component {
  constructor(props) {
    super(props);
  }


  

  render() {
    const Item = this.props.list.length && this.props.list.map((el, i) => {
      {console.log(el.kyc)}
      return (
        <tr key={el._id}>
          <td className="game-player-result__date">{el.nickName}</td>
          <td className="game-player-result__date">{el.email}</td>
          <td className="game-player-result__vs">{el.country}</td>
      <td className="game-player-result__date">{el.kyc ? el.kyc.paypalId:""}</td>
          <td className="game-player-result__vs text-center">
              <label className="switch">
                <input
                  className="switch-input"
                  type="checkbox"
                  name="Verify"
                  value={el.kyc ? el.kyc._id : ''}
                
                  onClick={this.props.handleVerify.bind(this,el.kyc ? el.kyc._id : '', !el.kyc.status,i)}
                  checked={el.kyc.status}
                />
                <span className="switch-label" data-on="Verified" data-off="Verify"></span> <span className="switch-handle"></span>
              </label>
          </td>
          <td > <button className="btn-primary-inverse" data-id={el.kyc ? el.kyc._id : ''} onClick={this.props.handlePopup.bind(this, i)}>
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>{" "}{el.kyc? el.kyc.status ? "" : <button className="btn-primary-inverse" data-id={el.kyc._id} onClick={this.props.handleReject.bind(this, i)}>
              Reject
          </button>: ""}</td>
        </tr>
      );
    });
    return (
      <div className="card__content">
        {this.props.popup?<PopUP popup={this.props.popup} currentkyc={this.props.currentkyc}
        handlePopup={this.props.handlePopup}
        />:""}
        <div className="table-responsive">
          <table className="table table-hover game-player-result">
            <thead>
              <tr>
                <th className="game-player-result__date text-left">Name</th>
                <th className="game-player-result__date text-left">Email</th>
                <th className="game-player-result__vs text-left">Country</th>
                <th className="game-player-result__character text-center">
                 Paypal Id
                </th>
                <th className="game-player-result__character text-center">
                 Verify
                </th>
                <th className="game-player-result__character text-center">
                  View Documents
                </th>
              </tr>
            </thead>
            <tbody>{this.props.list.length !== 0 ? Item : <tr><td colSpan={3}>No User found</td></tr>}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
