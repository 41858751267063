import React, { useEffect,useState } from "react";
import "./choose.css";
import Layout from '../layout'
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from 'react-bootstrap/DropdownButton'
import user from "../../assets/images/duel/user.png";
import { useTranslation } from "react-i18next";
import {userInstance,adminInstance} from '../../axios/axiosconfig';
import Popup from '../home/popup';
import Bet from '../home/bet';
import {cmsInstance} from '../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";
import {server} from '../../config/keys';

const ChooseFriend = ({ socket }) => {
    const { t, i18n } = useTranslation();
    const [game, setGame] = useState(null);
    const [matched, setMatched] = useState(false);
    const [bet, setBet] = useState(false);
    const Userid = localStorage.getItem("Userid");
    const [startTimer, setstartTimer] = useState(false);
    const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);
  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);
  const [playerDetails, setPlayerDetails] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [level, setLevel] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [country, setCountry] = useState(null);
  const [photo, setPhoto] = useState(null);
  const[content, setContent] = useState({});
  const [choosefriend, setchoosefriend] = useState('Choose Friend');
  const [friend, setFriend] = useState([]);
  const [userid, setUserId] = useState('');
  const [onlinefriend, setOnlineFriend] = useState(false);

  const handleTimerPopup = e => {
    if (e === `close`) {
      setstartTimer(false);
      // socket.emit("cancel", {
      //   Userid
      // });
    }
  };

  const normalMatch = async() => {
    if(game){
      let roomId = await loadRoom();
      socket.emit('normalMatch',{
        userId: userid,
        gameType: game,
        roomId: roomId
      });
    }
    
  }

  const getFriends = async() => {
    const res = await userInstance.get('/friendlist');
    if(res.data.code === 200){
        setFriend(res.data.friend);
    }
}

    const handleBetAmount = () => {
        setBet(true)
      }
      const CloseBetComponent = () => {
        setBet(false);
      }

      const getUserProfile = async () => {
        const response = await userInstance.get("/getprofile");
        if (response.data.code === 200) {
  
          setLevel(response.data.profile_data.Level)
          setPlayerOne(response.data.profile_data)
          setNickName(response.data.profile_data.nickName)
          setCountry(response.data.profile_data.country)
          setPhoto(response.data.profile_data.photo)
        }
      };

      useEffect(() => {
      
        getContent();
        getUserProfile();
        getFriends();
        i18n.on('languageChanged', onLanguageChanged);
    }, [])

    const onLanguageChanged = () => {
      console.log("language change",localStorage.getItem('activeLanguage'))
      getContent();
     }
     
      const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/choosefriend';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            setGame(content.label1);
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }
      const matchRoom = async (amount, walletType) => {
        const res = await adminInstance.post("/getCommission")
        console.log("Admin API", res.data.rate)
        const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
        // const response = await userInstance.post("/getprofile");
        // console.log("responseData", walletType);
        socket.emit("matchMaking", {
          Userid,
          level: level,
          socketId: socket.id,
          bet: amount,
          adminCutOff: cutOff,
          betAmt: amount - cutOff,
          walletType: walletType,
          nickName: nickName,
          country: country,
          photo: photo,
          gameType: game
        });
        console.log(playerOne);
        setBetAmount(amount);
        setBet(false);
        setWallettype(walletType);
        setMatched(true)
      };

      useEffect(() => {
        // socket.emit("disconnect", {
        //   Userid
        // });
        socket.on('playerPopUp', data => {
    
          if (Userid === data.matched[0].Userid) {
            setPlayerOne(data.matched[0])
            setPlayerTwo(data.matched[1])
          } else {
            setPlayerOne(data.matched[1])
            setPlayerTwo(data.matched[0])
          }
    
        })
    
        socket.on("winterplay", data => {
          setPlayerDetails(data.matched);
          setRoomId(data.roomId);
          setstartTimer(false);
          handleTimerPopup(`close`);
          setMatched(true);
          console.log("Game Room :", roomId);
          console.log("Player Detasils", playerDetails);
        })
        socket.on("play", data => {
          // loadRoom();
          setPlayerDetails(data.matched);
          setRoomId(data.roomId);
          setstartTimer(false);
          handleTimerPopup(`close`);
          setMatched(true);
          console.log("Game Room :", roomId);
          console.log("Player Detasils", playerDetails);
          // setBet(true);
    
          socket.emit("server", {
            data
          });
    
          socket.emit('betAmount', data => {
            setBet(false);
          })
        });
      }, [startTimer]);

    const loadRoom = async () => {
        const { data } = await userInstance.post("/createRoom");
        const { roomId } = data;
        return roomId
      //  if(roomId && game === 'Pong'){
      //   window.location.href =`/pong?roomId=${roomId}`;
      //  }else if(roomId){
      //   window.location.href =`/wintergame?roomId=${roomId}`;
      //  }
      };

    // const handleSubmit = async() => {
    //     game = "WinterGame";
    //         const roomId = await loadRoom();
    //         console.log("Roomid", roomId);
    //         window.location.href =`/pong?roomId=${roomId}`;
           
        
    // }

    const handlefriendselect = (userid, i) => {
      setchoosefriend(i);
      setUserId(userid);
      setOnlineFriend(true);
      console.log("====>",i);
    }

    const handleSelect = (i) => {
        setGame(i);
        console.log(i);
    }
    return (

        <div className="choose-friend">
            {matched && (
          <Popup
            roomId={roomId}
            setMatched={setMatched}
            playerOne={playerOne}
            playerTwo={playerTwo}
            socket={socket}
            amount={betAmount}
            wallettype={wallettype}
            gameType = {game}
          />
        )}

        {bet && (
          <Bet
            socket={socket}
            matchRoom={matchRoom}
            CloseBetComponent={CloseBetComponent}
          ></Bet>
        )}
            
            <div className="choose-friend-content">

                <h2>{content.heading1}</h2>


                <Form onSubmit = {loadRoom}>


                <Form.Group>
                    {/* <Form.Label>{content.label1}</Form.Label>
                    <Form.Control id="choose-game" as="select" name="game" onChange={handleSelect} defaultValue={game}>
                                                            <option>{content.choosegame}</option>
                                                            <option>{content.label2}</option>
                                                            <option>{content.label3}</option>
                                                            <option>{content.label4}</option>
                                                        </Form.Control> */}
                    <DropdownButton id="choose-game" title={game}>
                        <Dropdown.Item onClick ={() => handleSelect(content.label2)}>{content.label2}</Dropdown.Item>
                        <Dropdown.Item onClick ={() => handleSelect(content.label3)}>{content.label3}</Dropdown.Item>
                        <Dropdown.Item onClick ={() => handleSelect(content.label4)}>{content.label4}</Dropdown.Item>
                    </DropdownButton>
                   
                </Form.Group>


                <Form.Group>
                    <Form.Label>{content.label5}</Form.Label>

                    <DropdownButton id="choose-friend" title={choosefriend}>
                    {
                      friend && friend.map((ele,i) => (
                          ele.online && ele.online.isActive ? 
                            <Dropdown.Item onClick={() => handlefriendselect(ele._id, ele.nickName)}>
                              <img src={`${server}/`+ ele.photo} alt ='' />
                               {ele.nickName}
                            </Dropdown.Item> : ''
                          
                        
                      ))
                    }

                        </DropdownButton>
                   
                </Form.Group>

                <div className="choose-button">
                    <Button type="button" onClick ={handleBetAmount} className="red-btn" >
                    {content.searchuser}
                    </Button>
                    {onlinefriend ? <Button type="button" onClick={normalMatch} className="blue-btn" >
                    {content.choose_friend}
                    </Button> : ''}
                </div>



                </Form>

            </div>


        </div>


    );
};

export default ChooseFriend;