import React, { useEffect,useState } from "react";
import "./transaction.css";
import Layout from "../layout";
import Table from "react-bootstrap/Table";
import { userInstance } from "../../axios/axiosconfig";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {cmsInstance} from '../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";

const UserTransaction = () => {
  const { i18n } = useTranslation();
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [content, setContent] = useState({});
  const [page, setPage] = useState(1);
  useEffect(()=>{
    getTransaction(1,10);
    getContent();
    i18n.on('languageChanged', onLanguageChanged);
},[])
const onLanguageChanged = () => {
  console.log("language change",localStorage.getItem('activeLanguage'))
  getContent();
 }
const getTransaction = async(pagevalue, paginationvalue)=>{
  setPage(pagevalue);
  let payload ={
    page: pagevalue,
    pagination: paginationvalue
  }
    const res = await userInstance.post('/getTransactionuser',payload);
    if(res.data.code===200){
      console.log("res",res);
    
      setTransactionDetails(res.data.list);
    }
}

const getContent = async () => {
  try {
    // const page = window.location.pathname.replace('/cms/', '');
    const page = '/transaction';
     let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
    const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
    if (status == 200) {
      setContent(content);
      
    } else {
      NotificationManager.error(msg, "Message", 10000);
    }
  } catch (error) {
    console.log('Error', error.message);
  }
}
  return (
    <Layout>
      <div className="transactionpage">
        <div className="container">

        <div className="row">
              <div className="col-md-12">
                  <div className="title-page">
                      <ul className="breadcrum">
                          <li><Link to='/'>{content.home}</Link></li>
                          <li>{content.transactions}</li>
                      </ul>
                      <h2>{content.transactions}</h2>
                     
                    </div>
              </div>
          </div>





          <div className="row">



            <div class="col-md-12 col-lg-12">
                <div className="home-table">

                <div className="responsive-table">
                 <Table bordered responsive>
                      <thead>
                        <tr>
                        <th>{content.transactionid}</th>
                          <th>{content.transactiontype} </th>
                          <th>{content.amount}</th>
                          
                          <th>{content.time}</th>
                          <th>{content.date}</th>
                          <th>{content.source}</th>

                        </tr>
                      </thead>
                      <tbody>
                         
                      {transactionDetails &&  transactionDetails.length? <> {transactionDetails.map(obj=>
                      <tr key={obj._id} className={obj.addRemove=== '+' ? 'red' : 'green'}>
                        <td>{obj._id}</td>
                         <td>{obj.isDeposit ? content.deposit : content.withdraw}</td>
                      <td className="amount">{obj.addRemove}{' '}{obj.amount} {' USD'}</td>
                            
                            <td>{new Date(obj.createdAt).toLocaleTimeString()}</td>
                          <td>{obj.createdAt.substring(0,10)}</td>
                          <td>{obj.gameType ? obj.gameType : obj.source}</td>
                        </tr>
                        )}</>:< thead><h3>{content.notransaction}</h3> </thead>}

                      </tbody>
                    </Table>                   
                 <div className="pagination-section">
                 { page > 1 ? <button onClick={ () => getTransaction(page - 1, 10)} className="previous">{content.previous}</button> : ''} 
                    {transactionDetails && transactionDetails.length === 10 ? <button onClick={() => getTransaction(page + 1, 10)} className="next">{content.next}</button> : ''}
                 
                 </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Layout>
  );
};

export default UserTransaction;
