import React, { useState, useEffect } from 'react';
import { cmsInstance } from '../../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";

const CMSterms = ({ language, handleDropdown }) => { 
    const [payload, setPayload] = useState({});
    const [content, setContent] = useState({});
    const[copyContent, setCopyContent] = useState({});
    const [prevContent, setPrevContent] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [dummyData,setDummyData] = useState({});
    useEffect(() => {
        getContent();
        getPrevContent();
    },[]) 

    const getPrevContent = async (e) => {
        try {
           
            const page ='terms';
            const { data: { content }, status } = await cmsInstance.get(`/content/${page}/${language}`);
            if (status === 200) {
                console.log(content);
                setPrevContent(content);
                setCopyContent(content);
                setIsEditable(true);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    const getContent = async (e) => {
        try {
           
            const page = 'terms'
            const { data: { content }, status } = await cmsInstance.get(`/content/${page}/en`);
            if (status === 200) {
                console.log(content);
                setContent(content);
                setCopyContent(content);
                Object.keys(content).forEach((ele, i)=>{
                    dummyData[i] = '';
                    }
                    )
            } else {
                setContent({ term1: '', term2: '', term3: '', term4: '' });
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }

    const handleChange = (e,i) => {
        if(isEditable){
            setPrevContent({...prevContent,[e.target.name]:e.target.value});
        }else{
            setDummyData({...dummyData, [i]: e.target.value});
            setCopyContent({ ...copyContent, [e.target.name]: e.target.value });
        }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        payload.page = 'terms';
        payload.content = isEditable ? prevContent : copyContent;
        payload.language = language;
        if (payload.language !== 'Select Language' && payload.language) {
            const { data: { msg }, status } = await cmsInstance.post('/content', payload);
            if (status == 200) {
                NotificationManager.success(msg, "Message", 10000);
                handleDropdown();
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        }
    }
    const handleReset = () => {
        setCopyContent({});
    }

   
    return (
          <div className="container">
            
            <form onSubmit={handleSubmit} onReset={handleReset}>
                        <h5>Legal page Content in English</h5>
                        {Object.values(content).map((ele, i)=>{
                            
                        return [
                        <div className='row'>
                            <div classname='col-md-6'style={{width:'40vw'}}>
                                <label>{ele}</label>
                            </div>
                            <div classname='col-md-6'>
                                <textarea type='text' name={Object.keys(content)[i]} value={isEditable ? Object.values(prevContent)[i] : Object.values(dummyData)[i] } onChange ={(e) => handleChange(e,i)} style={{marginTop:"3px"}}/>
                            </div>
                        </div>]
                    })}
                     
                     {isEditable ?<><input type="submit" value="Edit" style={{marginRight:"10px"}}/>
                    <input type="reset" value="Cancel" />
                    </>
                    :<> <input type="submit" value="Submit" style={{marginRight:"10px"}}/>
                    <input type="reset" value="Clear" /></>}
                </form>
                    </div>
               
       
    );
}
export default CMSterms;