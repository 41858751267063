import React, { useEffect, useState } from "react";
import Layout from '../layout'
import "./changepassword.css";
// import logo from "../../assets/images/header/logo.png";
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { validateData } from "../../function/function";
import { NotificationManager } from "react-notifications";
import { userInstance } from "../../axios/axiosconfig";
import { useTranslation } from "react-i18next";
import {cmsInstance} from '../../axios/axiosconfig';

// let url = window.location.href.split('/');
// let hex = url[url.length - 1];

const INITIAL_STATE = {
    oldpassword:"",
    password: '',
    confirmPassword: '',
  
}

const ChangePassword = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState(INITIAL_STATE);
    const [content, setContent] = useState({});
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }
    useEffect(() => {
        
        getContent();
    }, [])
    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/changepassword';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }
    const handleSubmit = async (event) => {
        event.preventDefault();


        const isValid = validateData([
            values.password,
            values.confirmPassword,
            values.oldpassword
        ]);
        if (isValid) {
            if (values.password == values.confirmPassword) {
                const payload = {
                    currentPassword:values.oldpassword,
                    newPassword:values.password
                }
                const response = await userInstance.post("/changePassword", payload);
                const statusCode = response.data.code;
                const msg = response.data.msg;
                if (statusCode == 200) {
                    setValues(INITIAL_STATE);
                    NotificationManager.success(content.message1, "Message", 4000);
                } else {
                    NotificationManager.error(content.message2, "Message", 4000);
                }
            } else {
                NotificationManager.error(content.message3, "Message", 4000);
            }
        } else {
            NotificationManager.error(content.message4, "Message", 4000);
        }
    }

    return (
        <div className="forget changepass">
            <div className="forget-section">
                <div className="container">
                    <div classNmae="row">
                        <div className="col-md-12">
                            <div className="forget-form">
                                <h1>{content.heading1} <span>{content.heading2}</span></h1>
                                <div className="forget-box">
                                    <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formBasicloginone">
                                            <Form.Label htmlFor="oldpassword">{content.label1}</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder={content.placeholder1}
                                                name="oldpassword"
                                                id="oldpassword"
                                                onChange={handleChange}
                                                value={values.oldpassword}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicloginone">
                                            <Form.Label htmlFor="new password">{content.label2}</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder={content.placeholder2}
                                                name="password"
                                                id="new password"
                                                onChange={handleChange}
                                                value={values.password}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicloginone">
                                            <Form.Label htmlFor="confirmPassword">{content.label3}</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder={content.placeholder3}
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                onChange={handleChange}
                                                value={values.confirmPassword}
                                            />
                                        </Form.Group>
                                        <div className="forget-button">
                                            <Button type="submit" className="red-btn" >
                                            {content.label4}
                                        </Button>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ChangePassword;