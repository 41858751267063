import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import TournamentList from "./components/tournament-list";
import "./style.css";
import { validateData } from '../../../function/function';
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
export default class Tournament extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{ id: `hjh` }],
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      title: "",
      tournamentStart: "",
      playerNumbers: "",
      tournamentType: "",
      tournamentEntryFee: "",
      firstPrize: 0,
      secondPrize: 0,
      thirdPrize:0
    };

  }

  componentDidMount = () => {
    this.getTournaments();
  }

  getTournaments = async (req, res) => {
    let filterBy = '';
    let payload = {
      data: filterBy
    }
    const response = await adminInstance.post('/getTournaments', payload);
    if (response.data.code == 200) {
      const tournaments = response.data.data;
      this.setState({
        list: tournaments
      });
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  createTournament = async (e) => {
    let  payload = {
      title: this.state.title,
      tournamentStart: this.state.tournamentStart,
      playerNumbers: this.state.playerNumbers,
      tournamentType: this.state.tournamentType,
      tournamentEntryFee: this.state.tournamentEntryFee
    };
    let data= [ payload.title,
      payload.tournamentStart,
      payload.playerNumbers,
      payload.tournamentType,
      payload.tournamentEntryFee];
    if(this.state.playerNumbers == 16){
      payload={...payload,
        tournamentFirstPrize:this.state.firstPrize,
        tournamentSecondPrize:this.state.secondPrize}
        console.log("from IF:" , payload);
      data.push(payload.tournamentFirstPrize);
      data.push(payload.tournamentSecondPrize);  
    }
    if(this.state.playerNumbers == 32){
      console.log("from IF:" , payload);
      payload={...payload,
        tournamentFirstPrize:this.state.firstPrize,
        tournamentSecondPrize:this.state.secondPrize,
        tournamentThirdPrize: this.state.thirdPrize}
      data.push(payload.tournamentFirstPrize);
      data.push(payload.tournamentSecondPrize);
      data.push(payload.tournamentThirdPrize);
    }
      
 
    
    console.log(payload, data);
    const isValid = validateData(data);
    

    if (isValid) {
      if(parseInt(this.state.firstPrize) + parseInt(this.state.secondPrize) + parseInt(this.state.thirdPrize) == 100){

      
      const response = await adminInstance.post('/createTournament', payload);
      console.log('resposne', response);
      if (response.data.code == 200) {
        this.setState({
          title: '',
          tournamentStart: '',
          playerNumbers: '',
          tournamentType: '',
          tournamentEntryFee: '',
          firstPrize: 0,
          secondPrize: 0,
          thirdPrize: 0,
          editid: '',
          update: false,
          list: response.data.data
        });
        NotificationManager.success("Tournament created successfully!", "Message", 4000);
      }
    }else{
      NotificationManager.error("Total % must be 100", "Message", 4000);
      console.log(this.state.firstPrize + this.state.secondPrize + this.state.thirdPrize);
    }
    } else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
  }

  handleDelete = async (id) => {
    const payload = {
      id: id
    }
    const response = await adminInstance.post('/deleteTournament', payload);
    if (response.data.code == 200) {
      this.setState({
        list: response.data.data
      });
      NotificationManager.success("Tournament Deleted Successfully !", "Message", 4000);
    } else {
      NotificationManager.error(response.data.msg, "Message", 4000);
    }
  }

  handleUpdate = async (id) => {
    // const id = e.target.getAttribute('data-id');
    const payload = {
      id: id
    }
    const response = await adminInstance.post('/getTournamentSingle', payload);
    if (response.data.code == 200) {
      this.setState({
        title: response.data.data.title,
        tournamentStart: response.data.data.tournamentStart,
        playerNumbers: response.data.data.playerNumbers,
        tournamentType: response.data.data.tournamentType,
        tournamentEntryFee: response.data.data.tournamentEntryFee,
        editid: id,
        update: true
      });
      if(response.data.data.tournamentThirdPrize){
        this.setState({
          firstPrize: response.data.data.tournamentFirstPrize,
          secondPrize: response.data.data.tournamentSecondPrize,
          thirdPrize: response.data.data.tournamentThirdPrize
        })
      }else if(response.data.data.tournamentSecondPrize){
        this.setState({
          firstPrize: response.data.data.tournamentFirstPrize,
          secondPrize: response.data.data.tournamentSecondPrize
        })
      }
    } else {
      NotificationManager.error(response.data.msg, "Message", 4000);
    }
  }

  handleUpdateSingle = async () => {
    let  payload = {
      _id: this.state.editid,
      title: this.state.title,
      tournamentStart: this.state.tournamentStart,
      playerNumbers: this.state.playerNumbers,
      tournamentType: this.state.tournamentType,
      tournamentEntryFee: this.state.tournamentEntryFee
    };
    let data= [ payload.title,
      payload.tournamentStart,
      payload.playerNumbers,
      payload.tournamentType,
      payload.tournamentEntryFee];
    if(this.state.playerNumbers == 16){
      payload={...payload,
        tournamentFirstPrize:this.state.firstPrize,
        tournamentSecondPrize:this.state.secondPrize
       }
       console.log("from UUPdate If: ",payload);
      data.push(payload.tournamentFirstPrize);
      data.push(payload.tournamentSecondPrize);  
    }
    if(this.state.playerNumbers == 32){
      payload={...payload,
        tournamentFirstPrize:this.state.firstPrize,
        tournamentSecondPrize:this.state.secondPrize,
        tournamentThirdPrize: this.state.thirdPrize}
      data.push(payload.tournamentFirstPrize);
      data.push(payload.tournamentSecondPrize);
      data.push(payload.tournamentThirdPrize);
    }

    const isValid = validateData(data);

    if (isValid) {
      if(parseInt(this.state.firstPrize) + parseInt(this.state.secondPrize) + parseInt(this.state.thirdPrize) == 100){
          const response = await adminInstance.post('/updatettournament', payload);
          if (response.data.code == 200) {
            this.setState({
              title: '',
              tournamentStart: '',
              playerNumbers: '',
              tournamentType: '',
              tournamentEntryFee: '',
              firstPrize:0,
              secondPrize: 0,
              thirdPrize: 0,
              editid: '',
              update: false,
              list: response.data.data
            });
            NotificationManager.success("Tournament Updated successfully!", "Message", 4000);
          }
      }else{
        NotificationManager.error("Total % must be 100", "Message", 4000);
      }
    } else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
  }

  handleCancel = () => {
    this.setState({
      title: '',
      tournamentStart: '',
      playerNumbers: '',
      tournamentType: '',
      tournamentEntryFee: '',
      tournamentPrizeMoney: '',
      firstPrize:0,
      secondPrize:0,
      thirdPrize:0,
      editid: '',
      update: false
    });
  }

  handleDetails = (id) =>{
    window.location.href = `/tournamentdetails?tour_id=${id}`;
  }



  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-6">
              <div className="card__content ">
                <SectionHeader title="Create tournament " />
                <div className="card">
                  <div className="card__content">
                    <InputLabel
                      label={`title`}
                      id={`title-input-field`}
                      required={true}
                      type={`text`}
                      placeholder={`Please enter tournament title`}
                      name={"title"}
                      value={this.state.title}
                      handlechange={this.handleChange}
                    />
                    <div className="card_content start">
                      <label htmlFor="tournamentStart">Tournament Start Time</label>
                      <br />
                      <input
                        type="datetime-local"
                        id="tournamentStart"
                        name="tournamentStart"
                        value={this.state.tournamentStart}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="card_content start">
                      <label htmlFor="datetime">Enter Number of Players</label>
                      <br />
                      <select name="playerNumbers" onChange={this.handleChange}>
                      <option key="" value="">Select Number of Players</option>
                        <option key="8" value="8">8 Players</option>
                        <option key="16" value="16">16 Players</option>
                        <option key="32" value="32">32 Players</option>
                      </select>
                    </div>
                    {this.state.playerNumbers === "32" ? <>
                    <InputLabel
                      label={`1st Prize %`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please 1st Winning Prize %`}
                      name={"firstPrize"}
                      value={this.state.firstPrize}
                      handlechange={this.handleChange}
                      
                    /><InputLabel
                    label={`2nd Prize %`}
                    id={`title-input-field`}
                    required={true}
                    type={`number`}
                    placeholder={`Please 2nd Winning Prize %`}
                    name={"secondPrize"}
                    value={this.state.secondPrize}
                    handlechange={this.handleChange}
                    
                  />
                  <InputLabel
                      label={`3rd Prize %`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please 3rd Winning Prize %`}
                      name={"thirdPrize"}
                      value={this.state.thirdPrize}
                      handlechange={this.handleChange}
                      
                    />
                    </>: this.state.playerNumbers === "16" ? <>
                    <InputLabel
                      label={`1st Prize %`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please 1st Winning Prize %`}
                      name={"firstPrize"}
                      value={this.state.firstPrize}
                      handlechange={this.handleChange}
                      
                    /><InputLabel
                    label={`2nd Prize %`}
                    id={`title-input-field`}
                    required={true}
                    type={`number`}
                    placeholder={`Please 2nd Winning Prize %`}
                    name={"secondPrize"}
                    value={this.state.secondPrize}
                    handlechange={this.handleChange}
                    
                  />
                    </>: ""}
                    <div className="card_content start">
                      <label htmlFor="tournamentType">Tournament Type</label>
                      <br />
                      <select name="tournamentType" onChange={this.handleChange}>
                      <option key="8" value="8">Select Tournament Type</option>
                        <option key="sometype" value="some">Some</option>
                        <option key="someother" value="someother">Some Other</option>
                      </select>
                    </div>
                    <InputLabel
                      label={`Tournament Entry Fee`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please enter tournament entry Fee`}
                      name={"tournamentEntryFee"}
                      value={this.state.tournamentEntryFee}
                      handlechange={this.handleChange}
                    />
                    <div className="form-group mb-0 text-right">
                      {!this.state.update ? (
                        <button
                          className="btn btn-primary-inverse"
                          onClick={this.createTournament}
                        >
                          Create
                      </button>
                      ) : (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary-inverse"
                              onClick={this.handleUpdateSingle}
                            >
                              Update
                        </button>
                            <button
                              type="submit"
                              className="btn btn-danger"
                              onClick={this.handleCancel}
                            >
                              Cancel
                        </button>
                          </div>
                        )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card__content">
                <div className="card--has-table card">
                  <SectionHeader title="Tournament list" />
                  <TournamentList
                    {...this.state}
                    handleDelete={this.handleDelete}
                    handleUpdate={this.handleUpdate}
                    handleDetails ={this.handleDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
