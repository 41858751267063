import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import twitter from "../../assets/images/home/twitter-icon.png";
import youtube from "../../assets/images/home/youtube-icon.png";
import facebook from "../../assets/images/home/facebook-icon.png";
import instagram from "../../assets/images/home/instagram-icon.png";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";



const DayOfDuel = ({ content }) => {
    const { t, i18n } = useTranslation();

    return (

        <div className="day-of-duel-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title">
                            <h2>{content && content.dod}</h2>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="duel-box">
                            <div className="duel-info social">
                                {/* <h4>{t("dayofduel.socialmedia")}</h4>
                                 <p>{t("dayofduel.para1")}</p><p>{t("dayofduel.justfollow")}</p> */}
                                <h4>{content && content.socialmedia}</h4>
                                <p>{content && content.socialpara1}</p><p>{content && content.justfollow}</p>
                                <ul className="duel-social">
                                    <li><Link to="/"><img src={twitter} alt="Twitter" /></Link></li>
                                    <li><Link><img src={youtube} alt="Youtube" /></Link></li>
                                    <li><a href="https://www.facebook.com/Day-of-Duel-101086868042585" target="_blank"><img src={facebook} alt="Facebook" /></a></li>
                                    <li><Link><img src={instagram} alt="Instagram" /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="duel-box">
                            <div className="duel-info feature">
                                {/* <h4>{t("dayofduel.features")}</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                                <h4>{content && content.features}</h4>
                                <p>{content && content.para3}</p>
                                <Link to="/features">{content && content.more} <span>{'>>'}</span></Link>
                            </div>
                        </div>
                    </div>



                    <div className="col-md-4">
                        <div className="duel-box">
                            <div className="duel-info joinus">
                                {/* <h4>{t("dayofduel.joinus")} </h4>
                                <p>{t("dayofduel.para2")}</p> */}
                                <h4>{content && content.joinus} </h4>
                                <p>{content && content.para2}</p>
                                <Link>{content && content.dev} <span>{'>>'}</span></Link>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </div>



    );
};

export default DayOfDuel;