import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import signup from "../../assets/images/home/signup-icon.png";
import checkin from "../../assets/images/home/checkin-icon.png";
import fight from "../../assets/images/home/fight-icon.png";
import igtv from "../../assets/images/home/tv.png";
import payout from "../../assets/images/home/payout-icon.png";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";



const HowTournament = ({ content, loadRoom }) => {
    const { t, i18n } = useTranslation();
    const slider = useRef(null)

    const next = () => {
        slider.current.slickNext();
    }
    const previous = () => {
        slider.current.slickPrev();
    }
    console.log(content, '====>')
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,

    };


    return (

        <div className="how-tournament-section">

            <div className="align-tournament">
                <Slider ref={slider} {...settings}>


                    <div className="duel-bg-slide">
                        <div className="container">

                            <Link onClick={next} className="next-link">{content && content.tournament} <span>{'>>'}</span></Link>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title">
                                        {/* <h2>How to Duel</h2> */}
                                        <h2>{content && content.title1}</h2>
                                    </div>
                                </div>

                                <div className="col-md-3"></div>

                                <div className="col-md-2">
                                    <div className="how-tournament-box">
                                        <div className="how-tournament-icon">
                                            <img src={checkin} alt="" />
                                        </div>
                                        <div className="how-tournament-info">
                                            {/* <h4>1. Match</h4> */}
                                            <h4>{content && content.match}</h4>
                                            {/* <p>Find an opponent that matches your skill and budget</p> */}
                                            <p>{content && content.opponent}</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-2">
                                    <div className="how-tournament-box">
                                        <div className="how-tournament-icon">
                                            <img src={fight} alt="" />
                                        </div>
                                        <div className="how-tournament-info">
                                            {/* <h4>2. Fight</h4> */}
                                            <h4>{content && content.fight}</h4>
                                            {/* <p>You battle each other on PS4, Xbox One, PC or mobile</p> */}
                                            <p>{content && content.battle}</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-2">
                                    <div className="how-tournament-box">
                                        <div className="how-tournament-icon">
                                            <img src={payout} alt="" />
                                        </div>
                                        <div className="how-tournament-info">
                                            {/* <h4>3. Payout</h4> */}
                                            <h4>{content && content.payout}</h4>
                                            {/* <p>Win and receive your cash reward instantly</p> */}
                                            <p>{content && content.reward}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3"></div>



                                <div className="col-md-12">
                                    <div className="how-btn">
                                        <Button onClick={loadRoom} className="red-btn">{content && content.startplaying}</Button>
                                        <Button className="border-btn" onClick={() => window.location.href = '/howitworks'}>{content && content.details}</Button>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>













                    <div className="tournament-bg-slide">
                        <div className="container">
                            <Link onClick={previous} className="prev-link"><span>{'<<'}</span> {content && content.duel}</Link>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="title">
                                        {/* <h2>{t("howtournament.para1")}</h2> */}
                                        <h2>{content && content.title2}</h2>
                                    </div>
                                </div>

                                <div className="col-md-2"></div>
                                <div className="col-md-2">
                                    <div className="how-tournament-box">
                                        <div className="how-tournament-icon">
                                            <img src={signup} alt="" />
                                        </div>
                                        <div className="how-tournament-info">
                                            {/* <h4>{t("howtournament.signup")}</h4>
                                    <p>{t("howtournament.para2")}</p> */}
                                            <h4>{content && content.signup}</h4>
                                            <p>{content && content.para2}</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-2">
                                    <div className="how-tournament-box">
                                        <div className="how-tournament-icon">
                                            <img src={checkin} alt="" />
                                        </div>
                                        <div className="how-tournament-info">
                                            {/* <h4>{t("howtournament.checkin")}</h4>
                                    <p>{t("howtournament.para3")}</p> */}
                                            <h4>{content && content.checkin}</h4>
                                            <p>{content && content.para3}</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-2">
                                    <div className="how-tournament-box">
                                        <div className="how-tournament-icon">
                                            <img src={fight} alt="" />
                                        </div>
                                        <div className="how-tournament-info">
                                            {/* <h4>{t("howtournament.fight")}</h4>
                                    <p>{t("howtournament.para4")}</p> */}
                                            <h4>{content && content.fight2}</h4>
                                            <p>{content && content.para4}</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-2">
                                    <div className="how-tournament-box">
                                        <div className="how-tournament-icon">
                                            <img src={payout} alt="" />
                                        </div>
                                        <div className="how-tournament-info">
                                            {/* <h4>{t("howtournament.payout")}</h4>
                                    <p>{t("howtournament.para5")}</p> */}
                                            <h4>{content && content.payout2}</h4>
                                            <p>{content && content.para5}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2"></div>



                                <div className="col-md-12">
                                    <div className="how-btn">
                                        <Button className="blue-btn" onClick={() => window.location.href = '/tournament'}>{content && content.searchtournament}</Button>
                                        <Button className="border-btn" onClick={() => window.location.href = '/howitworks'}>{content && content.details}</Button>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>











                    {/* <div className="igtv">
                    <img src={igtv} alt="TV"/>                
                </div> */}

                </Slider>

            </div>
        </div>



    );
};

export default HowTournament;