import React from "react";
import './transaction-list.css';

export default class TransactionList extends React.Component {
  render() {
    const Item = this.props.list.map((el, i) => {
      return (
        <tr key={i}>
          <td className="game-player-result__date">{el.userID.nickName}</td>
          <td className="game-player-result__date">{el.userID.email}</td>
          <td className="game-player-result__vs">{el.amount}</td>
          <td className="game-player-result__vs">{new Date(el.createdAt).toDateString()}</td>
          {/* <td className="game-player-result__vs text-center">

            {(el.isblock === true) ?
              <label class="switch">
                <input
                  class="switch-input"
                  type="checkbox"
                  name="block"
                  value={el._id}
                  data-block={true}
                  onClick={this.props.handleBlock.bind(this, el._id, true)}
                  checked
                />
                <span class="switch-label" data-on="Unblock" data-off="Block"></span> <span class="switch-handle"></span>
              </label>
              :
              <label class="switch">
                <input
                  class="switch-input"
                  type="checkbox"
                  name="block"
                  value={el._id}
                  data-block={false}
                  onClick={this.props.handleBlock.bind(this, el._id, false)}
                />
                <span class="switch-label" data-on="Unblock" data-off="Block"></span> <span class="switch-handle"></span>
              </label>
            }
          </td> */}
        </tr>
      );
    });
    return (
      <div className="card__content">
        <div className="table-responsive">
          <table className="table table-hover game-player-result">
            <thead>
              <tr>
                <th className="game-player-result__date text-left">User ID</th>
                <th className="game-player-result__date text-left">Email</th>
                <th className="game-player-result__vs text-left">Amount</th>
                <th className="game-player-result__character text-center">Time</th>
              </tr>
            </thead>
            <tbody>{this.props.list.length !== 0 ? Item : <tr><td colSpan={3}>No Payment found</td></tr>}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
