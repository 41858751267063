import React from 'react'
import { userInstance } from '../../axios/axiosconfig';
import { useTranslation } from "react-i18next";
export default class Test extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handlePayment = async (req, res) => {
        let payload = {
            totalPrice: 150
        }

        const response = await userInstance.post('payment', payload);
        console.log(response.data.data);
        // window.location.replace(response.data);
        window.location.href = response.data.data;
    }

    render() {
        const { t, i18n } = useTranslation();
        return (
            <button onClick={this.handlePayment}>{t("test.dopayment")}</button>
        )
    }
} 