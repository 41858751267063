import React, { useEffect, useState } from "react";
import Layout from '../layout'
import "./signup.css";
// import logo from "../../assets/images/header/logo.png";
import { userInstance } from "../../axios/axiosconfig";
import { Form, Button, Alert, Modal, ModalBody } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { validateData } from "../../function/function";
import { NotificationManager } from "react-notifications";
import Google from '../google';
import Facebook from '../facebook';
import queryString from 'query-string';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { useTranslation } from "react-i18next";
import { cmsInstance } from '../../axios/axiosconfig';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from 'react-bootstrap/DropdownButton';
import RegistrationSuccess from '../registrationSuccess/registrationSuccess';

const INITIAL_STATE = {
    firstName: '',
    nickName: '',
    country: '',
    password: '',
    repeatPassword: '',
    email: '',
    terms1: false,
    terms2: false,
    terms3: false,
    terms4: false,
    terms: false,
    msg: '',
    msg_status: false,
    inviter: ''
}

const inviter = queryString.parse(window.location.search);
const Signup = ({SetBothSates}) => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({});
    const [values, setValues] = useState(INITIAL_STATE);
    const [errFirstName, setErrFirstName] = useState("");
    const [errName, setErrName] = useState("");
    const [errPassword, setErrPassword] = useState("");
    const [errConfirmPass, setErrConfirmPass] = useState("");
    const [errCountry, setErrCountry] = useState("");
    const [errEmail, setErrEmail] = useState("");
    const [errTerm1, setErrTerm1] = useState(false);
    const [errTerm2, setErrTerm2] = useState(false);
    const [errTerm3, setErrTerm3] = useState(false);
    const [errTerm4, setErrTerm4] = useState(false);
    const [dropcountry, setDropCountry] = useState(null);
    const [registrationSuccessShow, setRegistrationSuccessShow] = useState(false)

    const handleRegistrationSuccessClose = () => {
        setRegistrationSuccessShow(false)
        window.location.href='/'}
    // const inviter = queryString.parse(window.location.search); 

    // console.log('inviter', inviter.ref);
    useEffect(() => {

        if (values.terms1 && values.terms2 && values.terms3 && values.terms4) {
            // setValues({
            //     ...values,
            //     terms: true
            // })
            // console.log(inviter.ref);

            // const inviter = queryString.parse(window.location.search);
            setValues({ ...values, inviter: inviter.ref, terms: true })
        }

    }, [values.terms4, values.terms3, values.terms2, values.terms1])

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    const handleTerms = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.checked
        });
    }

    const handleSelect = (i) => {
        console.log(i.label)
        setDropCountry(i);
        setValues({
            ...values,
            country: i.label
        });
    }
    
   
    const handleSubmit = async (event) => {
        console.log("clicked!")
        event.preventDefault();
        // console.log('values', values);
        const isValid = validateData([
            values.firstName,
            values.nickName,
            values.password,
            values.repeatPassword,
            values.email,
            values.terms,
            values.country
        ]);
        if (isValid) {
           
            if (values.password == values.repeatPassword) {
                const response = await userInstance.post("/signup", values);
                const statusCode = response.data.code;
                const msg = response.data.msg;
                console.log('response', response);
                if (statusCode === 200) {
                     
                    var v= document.getElementById("scroll");
                    v.scrollIntoView();
                   if(window.location.pathname !== '/'){
                       setRegistrationSuccessShow(true)
                   }
                    // NotificationManager.success(msg, "Message", 10000);
                    if (values.inviter) {
                        const payload = {
                            _id: values.inviter
                        };
                        const response = await userInstance.post("/referBonus", payload);
                        console.log(response);
                    }
                    SetBothSates();
            } else if(statusCode === 400){ 
                setErrEmail(content.notification2);
            }
             else if(statusCode === 500) {
                NotificationManager.error(content.notification1, "Message", 10000);
            }}}
         else {

            if (values.firstName === "") {
                setErrFirstName(content.errFirstName);
            } else if(values.firstName.length >11) {
                setErrFirstName(content.gamerErr);
            }
            else{
                setErrFirstName("");
            }

            if (values.nickName === "") {
                setErrName(content.errName);
            } else {
                setErrName("");
            }

            if ((values.country === "")||(values.country === null)||(values.country === content.selectcountry)) {
                setErrCountry(content.errCountry);
            } else {
                setErrCountry("");
            }

            if (values.password === "") {
                setErrPassword(content.errPassword1);
            } else {
                setErrPassword("");
            }

            if((values.repeatPassword === "") ||(values.repeatPassword !== values.password)) {
                setErrConfirmPass(content.errConf);
            } else {
                setErrConfirmPass("");
            }
            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8})");
            if((values.password.length<8) ||(!strongRegex.test(values.password))){ 
                setErrPassword(content.errPassword);
        } else {
            setErrPassword("");
        }
          if(values.email === ""){
            setErrEmail(content.errEmail2);
          }else {
        var atposition=values.email.indexOf("@");  
        var dotposition=values.email.lastIndexOf(".");  
        if (atposition<1 || dotposition<atposition+2 || dotposition+2>=values.email.length){ 
            
                setErrEmail(content.errEmail1);
            } else {
                setErrEmail("");
            }
        }

            if (values.terms1 === false) {
                setErrTerm1(content.errTerm);
            } else {
                setErrTerm1("");
            }

            if (values.terms2 === false) {
                setErrTerm2(content.errTerm);
            } else {
                setErrTerm2("");
            }

            if (values.terms3 === false) {
                setErrTerm3(content.errTerm);
            } else {
                setErrTerm3("");
            }

            if (values.terms4 === false) {
                setErrTerm4(content.errTerm);
            } else {
                setErrTerm4("");
            }
        }
    }

    const responseGoogle = async (res) => {
        if (res.profileObj) {
            let profile = res.getBasicProfile();

            let firstName = profile.getName();
            let email = profile.getEmail();
            let photo = profile.getImageUrl();
            let externalType = 'Google';
            let externalId = profile.getId();
            let nickName = profile.getName();

            let data = { firstName, email, photo, externalType, externalId, nickName };

            const response = await userInstance.post("/otherLogin", data);

            if (response.data.code == 200) {
                NotificationManager.success(response.data.msg, "Message", 4000);
            }
            if (response.data.code == 400) {
                NotificationManager.error(response.data.msg, "Message", 4000);
            }


        } else {
            NotificationManager.error(res.error, "Message", 4000);
        }
    }

    const getContent = async () => {
        try {
            // const page = window.location.pathname.replace('/cms/', '');
            const page = '/signup';
            let language = i18n.language;
            const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
            if (status == 200) {
                setContent(content);
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        getContent();
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const onLanguageChanged = () => {
        getContent();
    }

    return (
        <div className="signup">
            <div className="register-section">
                <div className="container back-color">
                    <div className="row"  >
                        <div className="col-md-12">
                            <div className="register-form" id="scroll">
                            
                               

                            <h1>{content.getaccount}</h1>
                                {values.msg_status &&
                                    <Alert variant="success">
                                        <strong id="sign">{content.success}</strong> {values.msg}
                                    </Alert>
                                }

                                <div className="social-login">
                                <span className ="loginBtn loginBtn--facebook"><Facebook btntext ={content.fbbtntext}/></span>
                                        <span className="loginBtn loginBtn--google"><Google btnText ={content.ggbtntext}/></span>
                                </div>

                                <div className="rigister-tab">
                                    <Form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group controlId="formBasicFirst">
                                                <Form.Label>{content.gamename}</Form.Label>
                                                    {errFirstName === "" ?
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={content.gamenameplace}
                                                            name="firstName"
                                                            onChange={handleChange}
                                                            value={values.firstName} />
                                                        :
                                                        <Form.Control
                                                            type="text"
                                                            className="showError"
                                                            placeholder={content.gamenameplace}
                                                            name="firstName"
                                                            onChange={handleChange}
                                                            value={values.firstName} />}
                                                    <label className="text-danger pt-0">{errFirstName}</label>
                                                </Form.Group>

                                            </div>
                                            <div className="col-md-6">
                                                <Form.Group controlId="formBasicEmail">
                                                <Form.Label>{content.name}</Form.Label>
                                                    {errName === "" ?
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={content.nameplace}
                                                            name="nickName"
                                                            onChange={handleChange}
                                                            value={values.nickName} />
                                                        :
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={content.nameplace}
                                                            className="showError"
                                                            name="nickName"
                                                            onChange={handleChange}
                                                            value={values.nickName} />}
                                                    <label className="text-danger">{errName}</label>
                                                </Form.Group>

                                            </div>



                                            <div className="col-md-6">
                                                <Form.Group controlId="formBasicPassword">
                                                <Form.Label>{content.password}</Form.Label>
                                                    {errPassword === "" ? <Form.Control
                                                        type="password"
                                                        placeholder={content.passwordplace}
                                                        name="password"
                                                        onChange={handleChange}
                                                        value={values.password} />
                                                        :
                                                        <Form.Control
                                                            type="password"
                                                            className="showError"
                                                            placeholder={content.passwordplace}
                                                            name="password"
                                                            onChange={handleChange}
                                                            value={values.password} />}
                                                    <label className="text-danger">{errPassword}</label>
                                                </Form.Group>
                                            </div>

                                            <div className="col-md-6">
                                                <Form.Group controlId="formBasicPassword">
                                                <Form.Label>{content.confirmpassword}</Form.Label>
                                                    {errConfirmPass === "" ?
                                                        <Form.Control
                                                            type="password"
                                                            placeholder={content.confirmplace}
                                                            name="repeatPassword"
                                                            onChange={handleChange}
                                                            value={values.repeatPassword} />
                                                        :
                                                        <Form.Control
                                                            type="password"
                                                            placeholder={content.confirmplace}
                                                            name="repeatPassword"
                                                            className="showError"
                                                            onChange={handleChange}
                                                            value={values.repeatPassword} />}
                                                    <label className="text-danger">{errConfirmPass}</label>
                                                </Form.Group>

                                            </div>

                                            <div className="col-md-6">
                                                <Form.Group controlId="formBasicPassword">
                                                <Form.Label>{content.email}</Form.Label>
                                                    {errEmail === "" ?
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={content.emailplace}
                                                            name="email"
                                                            onChange={handleChange}
                                                            value={values.email}
                                                        /> :
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={content.emailplace}
                                                            name="email"
                                                            className="showError"
                                                            onChange={handleChange}
                                                            value={values.email}
                                                        />}
                                                    <label className="text-danger">{errEmail}</label>
                                                </Form.Group>

                                            </div>

                                            <div className="col-md-6">
                                                <Form.Group controlId="formBasicCountry">
                                                <Form.Label>{content.country}</Form.Label>
                                                    {errCountry === "" ?
                                                        // <Form.Control as="select" name="country" onChange={handleSelect} defaultValue={values.country}>
                                                        //     <option>{content.selectcountry}</option>
                                                        //     {country.map(item => (
                                                        //         <option key={item} value={item} >{item}</option>
                                                        //     ))}
                                                        // </Form.Control>
                                                        <Select
                                                            options={ countryList().getData()}
                                                            value={dropcountry}
                                                            onChange={handleSelect}
                                                        />
                                                       
                                                    //     <DropdownButton id="formBasicCountry" title={dropcountry}>
                                                        
                                                    //     {country.map(item => (
                                                    //             <Dropdown.Item onClick={() => handleSelect(item)}>{item} </Dropdown.Item>
                                                    //         ))}
                                                       
                                                       
                                                    // </DropdownButton>
                                                        : <Select
                                                        options={ countryList().getData()}
                                                        value={dropcountry}
                                                        onChange={handleSelect}
                                                    />
                                                        // <Form.Control as="select" name="country" className="showError" onChange={handleSelect} defaultValue={values.country}>
                                                        //      <option>{content.selectcountry}</option>
                                                        //      {country.map(item => (
                                                        //         <option key={item} value={item} >{item}</option>
                                                        //     ))}
                                                        // </Form.Control>}
                                                    //     <DropdownButton id="formBasicCountry" title={dropcountry}>
                                                        
                                                    //     {country.map(item => (
                                                    //             <Dropdown.Item onClick={() => handleSelect(item)}>{item} </Dropdown.Item>
                                                    //         ))}
                                                       
                                                    //    <label className="text-danger">{errCountry}</label>
                                                    // </DropdownButton>
}
                                                </Form.Group>

                                            </div>

                                            <div className="col-md-12">
                                                <Form.Group controlId="formBasicCheckbox" className="dis-show" name="tnc" checked="">
                                                    <Form.Check id="terms1" type="checkbox" name="terms1" onChange={handleTerms} checked={values.terms1} />
                                                    {errTerm1 === "" ? <Form.Label htmlFor="terms1"> {content.para1}</Form.Label> : <Form.Label htmlFor="terms1" className="showError"> {content.para1}</Form.Label>}
                                                </Form.Group>
                                                <label className="text-danger">{errTerm1}</label>
                                            </div>

                                            <div className="col-md-12">
                                                <Form.Group controlId="formBasicCheckbox" className="dis-show" name="tnc" checked="">
                                                    <Form.Check id="terms2" type="checkbox" name="terms2" onChange={handleTerms} checked={values.terms2} />
                                                    {errTerm2 === "" ? <Form.Label htmlFor="terms2"> {content.para2}</Form.Label> : <Form.Label htmlFor="terms2" className="showError">  {content.para2}</Form.Label>}
                                                </Form.Group>
                                                <label className="text-danger">{errTerm2}</label>
                                            </div>
                                            <div className="col-md-12">
                                                <Form.Group controlId="formBasicCheckbox" className="dis-show" name="tnc" checked="">
                                                    <Form.Check id="terms3" type="checkbox" name="terms3" onChange={handleTerms} checked={values.terms3} />
                                                    {errTerm3 === "" ? <Form.Label htmlFor="terms3">{content.para3}</Form.Label> :
                                                        <Form.Label htmlFor="terms3" className="showError">{content.para3}</Form.Label>}
                                                </Form.Group>
                                                <label className="text-danger" >{errTerm3}</label>
                                            </div>
                                            <div className="col-md-12">
                                                <Form.Group controlId="formBasicCheckbox" className="dis-show" name="tnc" checked="">
                                                    <Form.Check id="terms4" type="checkbox" name="terms4" onChange={handleTerms} checked={values.terms4} />
                                                    {errTerm4 === "" ? <Form.Label htmlFor="terms4">{content.para6}
                                                                <input type="hidden" name='terms' checked={values.terms} />
                                                    </Form.Label> :
                                                        <Form.Label htmlFor="terms4" className="showError">{content.para6}
                                                                <input type="hidden" name='terms' checked={values.terms} />
                                                        </Form.Label>}
                                                </Form.Group>
                                                <label className="text-danger">{errTerm4}</label>
                                            </div>
                                            {/* <div className="col-md-12">
                                                    <Form.Group controlId="formBasicCheckbox" className="dis-show" name="tnc" checked="">
                                                        <Form.Check type="checkbox" name="terms" onChange={handleTerms} value={values.terms} />
                                                        <Form.Label>Yes, I agree to the <Link className="terms-link" to={'/'}>Terms of Service</Link> and <Link className="terms-link" to={'/'}>Privacy Policy</Link>.</Form.Label>
                                                    </Form.Group>
                                                </div> */}

                                            <div className="col-md-12">
                                                <div className="register-center">
                                                    <Button className="red-btn" type="submit" >{content.sign_up}
                                                       
                                                        </Button>
                                                </div>
                                            </div>

                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal dialogClassName="dod-popup" show={registrationSuccessShow} onHide={handleRegistrationSuccessClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
          <RegistrationSuccess handleRegistrationSuccessClose={handleRegistrationSuccessClose}/>
      </Modal.Body>
      </Modal> 
        </div>

    );
};

export default Signup;
