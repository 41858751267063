import React, { useEffect } from "react";
import "../admin.css";
import logo from '../../../assets/images/header/logonew.png'
import history from '../../../config/history'
import { adminInstance } from '../../../axios/axiosconfig';

const AdminMenu = ({ menutoggle }) => {

    const handleLogout = async () => {
        const logoutResponse = await adminInstance.post('/logout');
        window.location.href = '/cms/login';
    }

    return (
        <div className={`admin-menu ${menutoggle ? `menu-hide` : ``}`}>

            <div className="menu-logo" >
                <img src={logo} alt="logo" />
            </div>

            <div className="admin-menu-box">
                <div className="menu-list">
                    <ul>
                        <li><a href="/cms/addlanguage"><i className="fa fa-user"></i>Add/Edit Language</a></li>
                        <li><a href="/cms/deletelanguage"><i className="fa fa-user"></i>Delete Language</a></li>
                        <li><a href="#" onClick={handleLogout}><i className="fa fa-power-off"></i> Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AdminMenu;
