import React from "react";
import './transaction-list.css';
import PopUP from "../popUp";

export default class TransactionList extends React.Component {
  render() {
    const Item = this.props.list.map((el, i) => {
      return (
        <tr key={i}>
         <td className="game-player-result__date">{el.userID.nickName}</td>
         <td className="game-player-result__date">{el.userID.email}</td>
          <td className="game-player-result__vs">{el.amount}</td>
          <td className="game-player-result__vs">{new Date(el.createdAt).toDateString()}</td>
          <th >
            <button className="btn btn-success btn-sm mr-1" onClick={()=>this.props.doPayout(el.userID.email, el.amount, el._id,el.userID._id)}>Approve</button>
                <button className="btn btn-danger btn-sm" onClick={()=>this.props.handleDecline(el.userID._id,el._id)}>Decline</button>
          </th>
        </tr>
      );
    });
    return (
      <div className="card__content">
         {this.props.popup?<PopUP popup={this.props.popup} Userid={this.props.targetUser} 
        handlePopup={this.props.handlePopup} tid={this.props.transactionId}
        />:""}
        <div className="table-responsive">
          
          <table className="table table-hover game-player-result">
            
            <thead>
              
            </thead>
            
            <tbody>
              
            <tr>
                <td className="game-player-result__date text-left">User ID</td>
                <td className="game-player-result__date text-left">Email</td>
                <td className="game-player-result__vs text-left">Amount</td>
                <td className="game-player-result__character text-center">Date-Time</td>
                <td className="game-player-result__vs text-left">Response</td>
              </tr>
             
            </tbody>
            {console.log("dataaa=>",this.props.list)}
            <tbody>{this.props.list.length !== 0 ? Item : <tr><td colSpan={5}>No Payment Request found</td></tr>}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
