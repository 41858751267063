import React from "react";

export default class TournamentList extends React.Component {
  render() {
    const Item = this.props.values.list.map((el, i) => {
      return (
        <tr key={i}>
          <td>{el.tournamentStart}</td>
          <td>{el.title}</td>
          <td>
            <button
              className="btn-primary-inverse"
              data-id={el._id}
              onClick={this.props.handleUpdate.bind(this, el._id)}
            >
              <i class="fa fa-edit" aria-hidden="true"></i>
            </button>{" "}
            <button
              className="btn-primary-inverse"
              data-id={el._id}
              onClick={this.props.handleDelete.bind(this, el._id)}
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>{" "}
            <button
              className="btn-primary-inverse"
              data-id={el._id}
              onClick={this.props.handleDetails.bind(this, el._id)}
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div className="home-table">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{this.props.content.date}</th>
                <th>{this.props.content.title}</th>
                <th>
                {this.props.content.action}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.values.list.length !== 0 ? (
                Item
              ) : (
                <tr>
                  <td colSpan={3}>No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        </div>
    );
  }
}
