import React, { useEffect, useState } from "react";
import "./tournamentdetails.css";
import Layout from "../layout";
import { Link } from "react-router-dom";
import profilepic from "../../assets/images/home/5.png";
import Table from "react-bootstrap/Table";
import { server } from "../../config/keys.js";
import pongicon from "../../assets/images/home/dod-dark.png";
import poolicon from "../../assets/images/home/pool-dark.png";
import fifaicon from "../../assets/images/home/fifa-dark.png";
import flag from "../../assets/images/home/flag.png";
import { userInstance } from "../../axios/axiosconfig";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
const Tournamentdetails = () => {
  const { t, i18n } = useTranslation();
  const [tournament, setTournament] = useState({});
  const [roomsLevelOne, setRoomsLevelOne] = useState([]);
  const [roomsLevelTwo, setRoomsLevelTwo] = useState([]);
  const [roomsLevelThree, setRoomsLevelThree] = useState([]);
  const [roomsLevelFour, setRoomsLevelFour] = useState([]);
  const [roomsLevelFive, setRoomsLevelFive] = useState([]);
  const [joinPlayer, setJoinPlayer] = useState(null);
  const [playerNumbers, setPlayersNumber] = useState(null);

 
  useEffect(() => {
    let value = getTourId();
     getTournament(value);
  }, []);

  const getTourId = () => {
    const values = queryString.parse(window.location.search);
    console.log("values from query String==>", values);
    return values;
  };

  const getTournament = async (value) => {
    const res = await userInstance.get(`/tournamentdetails/${value.tour_id}`);
       console.log("Response from Tournament: ", res.data.data);
        if (res.data.code === 200) {
          setTournament(res.data.data);
          setRoomsLevelFive(res.data.data.roomsLevelFive);
          setRoomsLevelFour(res.data.data.roomsLevelFour);
          setRoomsLevelThree(res.data.data.roomsLevelThree);
          setRoomsLevelTwo(res.data.data.roomsLevelTwo);
          setRoomsLevelOne(res.data.data.roomsLevelOne);
          setJoinPlayer(res.data.data.joined);
          setPlayersNumber(res.data.data.playerNumbers)
        }
  }
  const {_id,title, tournamentStart}=tournament ? tournament :{};
 
 
   
  
 

  return (
    <Layout>
      {/* {user} */}
      <div className="tournamentdetails">
        <div className="container">
          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              <h3 className="font-weight-bold py-3 mb-0 details-tour">
              {t("tournamentdetails.tournament_details")}
              </h3>

              <div class="row">
                <div class="col-md-12">
  <h4 class="font-weight-bold mb-2 game-name">Title : {title}</h4>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h5 class="font-weight-bold litle-title mb-3">{t("tournamentdetails.details")}</h5>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">
                        {t("tournamentdetails.tournamentid")}
                        </div>
                        <div className="col-md-8">{_id}</div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">{t("tournamentdetails.maxplayers")}</div>
                        <div className="col-md-8">{playerNumbers}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <h5 class="font-weight-bold litle-title mb-3">{t("tournamentdetails.info")}</h5>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">{t("tournamentdetails.start")}</div>
                        <div className="col-md-8">{tournamentStart}</div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">{t("tournamentdetails.joined")}</div>
                          <div className="col-md-8">{joinPlayer}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           {/*  Tournament Brackets */}
           <div className='row'>
              <div className='col-md-3'>
                <div className="card-body">
                  <div className="row mb-3">
                  <h4>Round 1</h4>  
                    {roomsLevelOne.map(el =>
                      <div className='col-md-12'style={{height:'100px', border: '1px solid black', marginBottom:'10px'}}>
                          <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=1?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[0].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[0].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[0].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[0].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 1
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                      <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=2?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[1].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[1].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[1].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[1].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 2
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-md-3'>
                <div className="card-body">
                  <div className="row mb-3">
                  <h4>Round 2</h4>  
                    {roomsLevelTwo.map(el =>
                      <div className='col-md-12'style={{height:'100px', border: '1px solid black', marginBottom:'10px'}}>
                          <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=1?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[0].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[0].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[0].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[0].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 1
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                      <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=2?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[1].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[1].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[1].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[1].Level}
                                  </div>
                                </div> :<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 2
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className='col-md-3'>
                <div className="card-body">
                  <div className="row mb-3">
                             { roomsLevelThree.length === 1 ? <h4>Final Round</h4>: <h4>Round 3</h4> } 
                    {roomsLevelThree.map(el =>
                      <div className='col-md-12'style={{height:'100px', border: '1px solid black', marginBottom:'10px'}}>
                          <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=1?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[0].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[0].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[0].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[0].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 1
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                      <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=2?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[1].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[1].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[1].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[1].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 2
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="card-body">
                  <div className="row mb-3">
                  { roomsLevelFour.length === 1 ? <h4>Final Round</h4>: roomsLevelFour.length === 0 ? "": <h4>Round 4</h4> }   
                    {roomsLevelFour.map(el =>
                      <div className='col-md-12'style={{height:'100px', border: '1px solid black', marginBottom:'10px'}}>
                          <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=1?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[0].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[0].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[0].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[0].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 1
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                      <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=2?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[1].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[1].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[1].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[1].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 2
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="card-body">
                  <div className="row mb-3">
                  { roomsLevelFive.length === 1 ? <h4>Final Round</h4>:"" }   
                    {roomsLevelFive.map(el =>
                      <div className='col-md-12'style={{height:'100px', border: '1px solid black', marginBottom:'10px'}}>
                          <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=1?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[0].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[0].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[0].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[0].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 1
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                      <div className='row'style={{height:'50px', border: '1px solid black', padding: '1px' }}>
                             {el.players.length >=2?<div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  <img src = {`${server}/`+ el.players[1].photo} alt='' style={{height:'25px', width:'25px'}} />
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    {el.players[1].firstName}
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  <img src={`${server}/public/country/` + `${el.players[1].country}.png`}  style={{height:'25px', width:'25px'}}/>
                                  Level :  {el.players[1].Level}
                                  </div>
                                </div> : <div>
                                <div style={{height:'22px', width: '22px', display: 'inline'}}>
                                  
                                </div>
                                <div style={{height:'26px', width: '26px', display: 'inline'}}>    
                                    Name : Player 2
                                </div>
                                <div style={{height:'24px', width: '50px',display: 'inline'}}>
                                  Coutry
                                  Level :  
                                  </div>
                                </div>}
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

              <div className="row">
                <div className="col-md-12">
                  <h5 class="font-weight-bold litle-title mb-3">
                  {t("tournamentdetails.recentduels")}
                  </h5>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="tournament-block">
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>{t("tournamentdetails.tournamentstructure")}</th>
                              <th>{t("tournamentdetails.progress")} </th>
                              <th>{t("tournamentdetails.joinedplayers")}</th>
                              <th>{t("tournamentdetails.tournamentprize")}</th>
                              <th>{t("tournamentdetails.totalrounds")}</th>
                              <th>{t("tournamentdetails.date")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Round 1</td>
                              <td>20 minutes</td>
                              <td>500 </td>
                              <td>€200</td>
                              <td>4</td>
                              <td>
                                07/02/2020<span>09:25AM</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Round 2</td>
                              <td>30 minutes</td>
                              <td>400</td>
                              <td>€200</td>
                              <td>4</td>
                              <td>
                                07/02/2020<span>09:25AM</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Round 3</td>
                              <td>40 minutes</td>
                              <td>500</td>
                              <td>€200</td>
                              <td>2</td>
                              <td>
                                07/02/2020<span>09:25AM</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tournamentdetails;
