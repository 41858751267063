import React, { useEffect, useState } from "react";
import "./style.css";
import Layout from '../layout'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cmsInstance } from '../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";

const Privacy = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({});

    const getContent = async () => {
        try {
            const page = '/privacy'
            let language = i18n.language;
            const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
            if (status == 200) {
                setContent(content);
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        getContent();
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const onLanguageChanged = () => {
        getContent();
    }
 
    return (
        <Layout>
            <div className="legal">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-page legal-title">
                                <ul className="breadcrum">
                                    <li><Link>{content.home}</Link></li>
                                    <li>{content.heading1}</li>
                                </ul>
                                <h2 className="linenew">{content.heading1}</h2>
                                <p>{content.contentfirst}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="legal-content-box">
                                <h2>{content.heading2}</h2>
                                <p></p>
                                <p>{content.para1}</p>
                                <h3>{content.heading3}</h3>
                                <p>{content.para2}</p>
                                <h4>{content.heading4}</h4>
                                <p>{content.para3}</p>
                                <h5>{content.heading5}</h5>
                                <p>{content.para4}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Privacy;