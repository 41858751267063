import React, {useState, useEffect}from 'react';
import {userInstance, cmsInstance} from '../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";
import PlayerList from './components/player-list';
import {Tab, Nav} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import Layout from '../layout';
const Friend = () => {
    const [allPlayers, setAllPlayers] =useState({}); 
    const [fullList, setFullList] = useState({});
    const [key, setKey] = useState('allPlayers');
    const [friends, setFriends] = useState([]);
    const [sendRequest, setSendRequest] = useState([]);
    const [receivedRequest, setReceivedRequest] = useState([]);
    const [filter, setFilter] = useState('nickName');
    const [query, setQuery] = useState('');
    const[disabled, setDisabled] = useState(false);
    const [content, setContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();

    const onLanguageChanged = () => {
      console.log("language change",localStorage.getItem('activeLanguage'))
      getContent();
     }
    const getContent = async () => {
      try {
        // const page = window.location.pathname.replace('/cms/', '');
        const page = '/friend';
         let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
        const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
        if (status == 200) {
          setContent(content);
          setIsLoading(true);
        } else {
          NotificationManager.error(msg, "Message", 10000);
        }
      } catch (error) {
        console.log('Error', error.message);
      }
    }

    useEffect(() => {
      getContent();
      i18n.on('languageChanged', onLanguageChanged);
       getAllPlayers();
    }, [])

    const getAllPlayers = async() => {
        const res = await userInstance.get('/allPlayers');
        if(res.data.code === 200){
            setAllPlayers(res.data);
            setFullList(res.data);
            setFriends(res.data.friend);
            setSendRequest(res.data.send);
            setReceivedRequest(res.data.receive);
        }else{
          window.location.href='/login'
        }
    }
    const handleSearch = async (e) => {
        setQuery(e.target.value);
      let length= e.target.value.length;
       if(filter==="nickName"){
         if(key === 'allPlayers'){
          let arr = fullList.data.filter(ele=>ele.nickName.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
         setAllPlayers({data:arr,friend:fullList.friend, send:fullList.send,receive: fullList.receive });
         }else if(key === 'friends'){
          let arr = friends.filter(ele=>ele.nickName.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
         setAllPlayers({data:fullList.data,friend:arr, send:fullList.send,receive: fullList.receive });
         }else if(key === 'sendRequest'){
          let arr = sendRequest.filter(ele=>ele.nickName.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
         setAllPlayers({data:fullList.data,friend:fullList.friend, send:arr,receive: fullList.receive });
         }else if(key === 'receivedRequest'){
          let arr = receivedRequest.filter(ele=>ele.nickName.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
         setAllPlayers({data:fullList.data,friend:fullList.friend, send:fullList.send,receive:arr});
         }
       }
       if(filter==="country"){
        if(key === 'allPlayers'){
         let arr = fullList.data.filter(ele=>ele.country.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
        setAllPlayers({data:arr,friend:fullList.friend, send:fullList.send,receive: fullList.receive });
        }else if(key === 'friends'){
         let arr = friends.filter(ele=>ele.country.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
        setAllPlayers({data:fullList.data,friend:arr, send:fullList.send,receive: fullList.receive });
        }else if(key === 'sendRequest'){
         let arr = sendRequest.filter(ele=>ele.country.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
        setAllPlayers({data:fullList.data,friend:fullList.friend, send:arr,receive: fullList.receive });
        }else if(key === 'receivedRequest'){
         let arr = receivedRequest.filter(ele=>ele.country.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
        setAllPlayers({data:fullList.data,friend:fullList.friend, send:fullList.send,receive:arr});
        }
      }
       if(query===""){
        setAllPlayers(fullList);
       }
    }
    const handleFilter=(e)=>{
        setFilter(e.target.value);
    }
    
    const handleAddFriend = async(id) =>{
      const payload={
        friendId: id
      }
      setDisabled(true);
      const res = await userInstance.post('/sendRequest', payload);
      if(res.data.code === 200){
        setDisabled(false);
        setAllPlayers(res.data);
          setFullList(res.data);
          NotificationManager.success(content.notification1, 'Message', 4000);
          
      }else{
        NotificationManager.error(content.notification2, 'Message', 4000);
      }
    }
    const handleCancelSendRequest = async(id) => {
      console.log("CLicked Cancel Request");
      const payload = {
        requestid: id
      }
      setDisabled(true);
      const res = await userInstance.post('/cancelSendRequest', payload);
      if(res.data.code === 200){
        setDisabled(false);
        setAllPlayers(res.data);
        setFullList(res.data);
        NotificationManager.success(content.notification3, 'Message', 4000);
      } 
    }

    const handleCancelReceiveRequest = async(id) =>{
      console.log("CLicked CancelReceive Request");
      const payload = {
        requestid: id
      }
      setDisabled(true);
      const res = await userInstance.post('/cancelReceiveRequest', payload);
      if(res.data.code === 200){
        setDisabled(false);
        setAllPlayers(res.data);
        setFullList(res.data);
        NotificationManager.success(content.notification4, 'Message', 4000);
      } 
    }
    const handleAcceptRequest = async(id) =>{
      console.log("CLicked CancelReceive Request");
      const payload = {
        requestid: id
      }
      setDisabled(true);
      const res = await userInstance.post('/acceptReceiveRequest', payload);
      if(res.data.code === 200){
        setDisabled(false);
        setAllPlayers(res.data);
        setFullList(res.data);
        NotificationManager.success(content.notification5, 'Message', 4000);
      } 
    }
    const handleUnFriend = async(id) =>{
      console.log("CLicked CancelReceive Request");
      const payload = {
        requestid: id
      }
      setDisabled(true);
      const res = await userInstance.post('/unfriendRequest', payload);
      if(res.data.code === 200){
        setDisabled(false);
        setAllPlayers(res.data);
        setFullList(res.data);
        NotificationManager.success(content.notification6, 'Message', 4000);
      } 
    }
      return (
        <Layout>

        <section className="player-page">
            <div className="container">
        <div className="card__content">
         {/* {isLoading && (<SectionHeader title={content.player_list}
                   query={query}
                   filter={filter}
                   content = {content}
                   handleFilter={handleFilter}
                    handleSearch={handleSearch} />)}
                    <br/><br/> */}
          <Tab.Container  activeKey={key}
              onSelect={(k) => setKey(k)}
          >
            
              
                <Nav variant="pills" className="">
                <Nav.Item>
                    <Nav.Link eventKey="allPlayers">{content.allplayers}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="friends">{content.friends}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sendRequest">{content.send_request}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="receivedRequest">{content.received_request}</Nav.Link>
                  </Nav.Item>
                </Nav>
             
              
                <Tab.Content>
                <Tab.Pane eventKey="allPlayers">
                {key == 'allPlayers'?<>
                
                  <PlayerList
                     allPlayers = {allPlayers}
                     content = {content}
                     handleAddFriend={handleAddFriend}
                     handleUnFriend = {handleUnFriend}
                     handleCancelSendRequest = {handleCancelSendRequest}
                     handleCancelReceiveRequest = {handleCancelReceiveRequest}
                     handleAcceptRequest = {handleAcceptRequest}
                     disabled = {disabled}
                     tab = 'player'
                  /></>:'' }
                  </Tab.Pane>
                  <Tab.Pane eventKey="friends">
                 {key == 'friends' ?<>
                 
                     <PlayerList
                     allPlayers = {allPlayers}
                     handleUnFriend = {handleUnFriend}
                     content = {content}
                     disabled = {disabled}
                     tab = 'friend'
                  /></> :''}
                  
                  </Tab.Pane>
                  <Tab.Pane eventKey="sendRequest">
                  {key == 'sendRequest' ?<>
                 
                  <PlayerList
                      allPlayers = {allPlayers}
                      handleCancelSendRequest = {handleCancelSendRequest}
                      content = {content}
                      disabled = {disabled}
                      tab = 'send'
                  /></> :''}
                  
                  </Tab.Pane>
                  <Tab.Pane eventKey="receivedRequest">
                  {key == 'receivedRequest' ? <PlayerList
                     allPlayers = {allPlayers}
                     handleCancelReceiveRequest = {handleCancelReceiveRequest}
                     handleAcceptRequest = {handleAcceptRequest}
                     content = {content}
                     disabled = {disabled}
                     tab = 'received'
                  /> :''}
                  
                  </Tab.Pane>
                </Tab.Content>
             
           
          </Tab.Container>
         
        </div>
        </div>
        </section>
        </Layout>
      );
    

   
}

export default Friend;