import React from 'react'
export const InputLabel = ({
    label,
    id,
    required,
    classname,
    type,
    value,
    name,
    placeholder,
    handlechange,
    readonly,
    children
  }) => {
    return (
      <div className="form-group">
        <label htmlFor={id}>
          {label}
          {required && (
            <span className="required" title="required">
              *
            </span>
          )}
        </label>
  
        {!children ? (
          <input
            type={type}
            className={`form-control ${classname}`}
            value={value}
            name={name}
            id={id}
            readOnly={readonly}
            placeholder={placeholder}
            onChange={handlechange}
          />
        ) : (
          <div style={{ position: "relative" }}>
            <input
              type={type}
              className={`form-control ${classname}`}
              value={value}
              name={name}
              id={id}
              readOnly={readonly}
              placeholder={placeholder}
              onChange={handlechange}
            />
            {children}
          </div>
        )}
      </div>
    );
  };
  
  
  
  
  
  export const TextareaLabel = ({
    label,
    id,
    required,
    classname,
    value,
    name,
    placeholder,
    handlechange,
    rows
  }) => {
    return (
      <div className="form-group">
        <label htmlFor={id}>
          {label}
          {required && (
            <abbr className="required" title="required">
              *
            </abbr>
          )}
        </label>
  
        <textarea 
        name={name} 
        id={id} 
        rows={rows}
        required={required}
        value={value}
        placeholder={placeholder}
        onChange={handlechange}
        className={`form-control ${classname}`}></textarea>
      </div>
    );
  };
  