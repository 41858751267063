import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import MatchesList from "./components/matches-list";
import "./style.css";
import { validateData } from '../../../function/function';
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
export default class Matches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      finishMatch:[],
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      player1: "",
      player1_score:"",
      player2: "",
      player2_score:"",
      livepage : 1,
      finishedpage : 1
    };

  }

  componentDidMount = () => {
    this.getMatches(1,10);
    this.getRecentDuals(1,10);
  }
  getRecentDuals = async(pagevalue, paginationvalue) => {
    this.setState({finishedpage : pagevalue});
    const payload ={
      page:pagevalue,
      pagination:paginationvalue
    }
    const res = await adminInstance.post('/recentDuelAdmin',payload);
    if(res.data.code ===200){
      console.log("====>",res.data.duels);
      if(res.data.duels.length !==0){
        const finished = res.data.duels;
        this.setState(() => ({
          finishMatch:finished
        }))
      }
    }
  }
  getMatches = async (pagevalue, paginationvalue) => {
    this.setState({livepage : pagevalue});
    const payload ={
      page:pagevalue,
      pagination:paginationvalue
    }
    const response = await adminInstance.post('/livematches', payload);
    console.log('response of Admin',response);
    if (response.data.code == 200) {
      if(response.data.matches.length !== 0) {
        const res = response.data.matches;
        this.setState(()=>({
          list:res
        }))
    }
  }
  }
  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-12">
              <div className="card__content">
                
                  
                  <MatchesList
                    matchdata={this.state}
                    getMatches={this.getMatches}
                    getRecentDuals={this.getRecentDuals}

                  />
                 
                
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
  }
