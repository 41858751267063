import React, { useEffect, useState } from "react";
import Layout from '../layout'
import "./forget.css";
// import logo from "../../assets/images/header/logo.png";
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { validateData } from "../../function/function";
import { NotificationManager } from "react-notifications";
import { userInstance } from "../../axios/axiosconfig";
import { useTranslation } from "react-i18next";
import {cmsInstance} from '../../axios/axiosconfig';
let url = window.location.href.split('/');
let hex = url[url.length - 1];

const INITIAL_STATE = {
    password: '',
    confirmPassword: '',
    hex: hex
}

const Resetpassword = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState(INITIAL_STATE);
    const [errPassword, setErrPassword] = useState("");
    const [errConfirmPass, setErrConfirmPass] = useState("");
    const [content, setContent] = useState({});
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }
    useEffect(() => {
      
        getContent();
    }, [])
    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/reset';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }
    const handleSubmit = async (event) => {
        event.preventDefault();


        const isValid = validateData([
            values.password,
            values.confirmPassword,
            values.hex
        ]);
        if (isValid) {
            const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8})");
            if((values.password.length<8) ||(!strongRegex.test(values.password))){ 
                setErrPassword(content.errPassword);
            } else if (values.password === "") {
                setErrPassword(content.errPassword);
            } else if((values.confirmPassword === "") ||(values.confirmPassword !== values.password)) {
                setErrConfirmPass(content.errConf1);
                setErrPassword("");
            } else {
                setErrConfirmPass("");
            setErrPassword("");
                const response = await userInstance.post("/forget", values);
                const statusCode = response.data.code;
                const msg = response.data.msg;
                if (statusCode == 200) {
                    setValues(INITIAL_STATE);
                    NotificationManager.success(content.notification1, "Message", 4000);
                    window.location.href ='/login';
                } else {
                    NotificationManager.error(content.notification2, "Message", 4000);
                }
            }
        }else{
            if (values.password === "") {
                setErrPassword(content.errConf);
            }else{
                setErrPassword("");
            }
            if (values.confirmPassword === "") {
                setErrConfirmPass(content.errConf);
            }else{
                setErrConfirmPass("");
            }
        }
    }

    return (
        <div className="forget">
            <div className="forget-section">
                <div className="container">
                    <div classNmae="row">
                        <div className="col-md-12">
                            <div className="forget-form">
                                <h1>{content.heading1} <span>{content.heading2}</span></h1>
                                <div className="forget-box">
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicloginone">
                                            <Form.Label>{content.para1}</Form.Label>
                                           
                                            {errPassword === "" ? <Form.Control
                                                        type="password"
                                                        placeholder={content.placeholder1}
                                                        name="password"
                                                        onChange={handleChange}
                                                        value={values.password} />
                                                        :
                                                        <Form.Control
                                                            type="password"
                                                            className="showError"
                                                            placeholder={content.placeholder1}
                                                            name="password"
                                                            onChange={handleChange}
                                                            value={values.password} />}
                                                    <label className="text-danger">{errPassword}</label>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicloginone">
                                            <Form.Label>{content.para2}</Form.Label>
                                           
                                            {errConfirmPass === "" ?
                                                        <Form.Control
                                                            type="password"
                                                            placeholder={content.placeholder2}
                                                            name="confirmPassword"
                                                            onChange={handleChange}
                                                            value={values.confirmPassword} />
                                                        :
                                                        <Form.Control
                                                            type="password"
                                                            placeholder={content.placeholder2}
                                                            name="confirmPassword"
                                                            className="showError"
                                                            onChange={handleChange}
                                                            value={values.confirmPassword} />}
                                                    <label className="text-danger">{errConfirmPass}</label>
                                        </Form.Group>
                                        <div className="forget-button">
                                            <Button type="submit" className="red-btn" >
                                            {content.para3}
                                        </Button>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Resetpassword;