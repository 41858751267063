import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet'
import { Form, Button } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { validateData } from '../../../function/function';
import { adminInstance } from '../../../axios/axiosconfig';

const AdminCmsLogin = () => {
    const [login, setLogin] = useState({});

    const handleChange = e => {
        const { value, name } = e.target;
        setLogin({ ...login, [name]: value });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const { data: { msg, code } } = await adminInstance.post('/login', login);
        if (code === 200) {
            window.location.href = "/cms/dashboard";
        } else {
            NotificationManager.error(msg, 'Message', 4000)
        }
    }

    return (

        <div className="admin-login">
            <Helmet>
                <body className="admin-view" />
            </Helmet>

            <div className="container">

                <div className="row">
                    <div className="col-md-12">
                        <div className="admin-login-box">
                            <h2>CMS Login</h2>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formBasicloginone">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Username"
                                        name="username"
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />
                                </Form.Group>

                                <div className="login-button">
                                    <Button type="submit" className="l-btn" >
                                        Login
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
};

export default AdminCmsLogin;