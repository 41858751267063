import React from 'react';
const ChatWindow = ({ selectUserChat, admin }) => {
  
  
  return (
    <>
   
  
      {selectUserChat.length > 0 ? selectUserChat.map((ele,i) =>
      <>
         <li className="chat-date " key={`item-${i}`}>
         <div className="chat-date-bg">
                {
                  
                 i > 0 && ele.updatedAt && selectUserChat[i-1].updatedAt.substring(0,10) !== ele.updatedAt.substring(0,10) ?
                  new Date(ele.updatedAt).getDay() - new Date().getDay() === 0 ? 'Today' :
                  new Date(ele.updatedAt).getDay() - new Date().getDay() === 1 ? 'Yesterday':
                      ele.updatedAt.substring(0, 10)
               
                   : 
                   i === 0 ? new Date(ele.updatedAt).getDay() - new Date().getDay() === 0 ? 'Today' :
                   new Date(ele.updatedAt).getDay() - new Date().getDay() === 1 ? 'Yesterday':
                      ele.updatedAt.substring(0,10):''
               } </div>
               </li>
        <li  className={ele.from !== admin ? "" : "clearfix"} >
          <div className={ele.from !== admin ? "message my-message" : "message other-message float-right"}>
              {ele.content}<span>{new Date(ele.updatedAt).toLocaleTimeString()}</span>
          </div>
        </li>
        </>
      ) 
      :
      <li  className={`clearfix`} >
      <div className={`message other-message float-right`}>
        Start chat
      </div>
    </li> }
    </>
  );
}

export default ChatWindow;