import React, { useEffect, useState } from "react";
import "./editprofile.css";
import { Form, Button, DropdownButton, Dropdown, Spinner } from 'react-bootstrap';
import { country } from '../../function/country';
// import Form from 'react-bootstrap/Form';
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { userInstance } from "../../axios/axiosconfig";
import {cmsInstance} from '../../axios/axiosconfig';
const INITIAL_STATE = {
    firstName: '',
    nickName: '',
    country: '',
    password: '',
    email: '',
    cpassword: '',
}
const EditProfile = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState(INITIAL_STATE);
    const [loading, setloading] = useState(false);
    const [content, setContent] = useState({});
    
const [dropdowncountry, setDropdownCountry] = useState('Select Country');
    useEffect(() => {
        getProfile();
        getContent();
    }, [])

    const getProfile = async () => {
        const response = await userInstance.get('/getprofile');
        console.log('response', response);
        if (response.data.code === 200) {
            const { profile_data } = response.data;
            setValues({
                ...values,
                firstName: profile_data.firstName,
                nickName: profile_data.nickName,
                country: profile_data.country,
                email: profile_data.email,
                password: '',
                cpassword: ''
            });
            setDropdownCountry(response.data.profile_data.country);
        }
    }
    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/editprofile';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }
   

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }
    const handleSelect = (i) => {
        setDropdownCountry(i)
        setValues({
            ...values,
            country: i
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setloading(true);
        if(values.password !== values.cpassword) {
            setloading(false)
           return NotificationManager.error('Password must be same', "Message", 5000);
        }
        const payload = {
            firstName: values.firstName,
            nickName: values.nickName,
            country: values.country,
            password: values.password
        }
        const res = await userInstance.post('/updateProfile', payload);
        if (res.data.code === 200) {
            setloading(false)
            NotificationManager.success(content.notification1, "Message", 5000);
            window.location.href='/profile';
        }
        else {
            setloading(false)
            NotificationManager.error(content.notification2, "Message", 5000)
        }
    }

    return (



        <div className="editprofile">

            <div className="container">

                <div className="edit-p">


                    <h2>{content.heading1}</h2>
                    <p>{content.para1}</p>
                    <Form onSubmit={handleSubmit}>

                        <div className="row">


                            <div className="col-md-6">
                                <Form.Group controlId="Name">
                                    <Form.Label>{content.label1}</Form.Label>
                                    <Form.Control type="text" name="firstName" placeholder={content.placeholder1} onChange={handleChange} value={values.firstName} />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="Nickname">
                                    <Form.Label>{content.name}</Form.Label>
                                    <Form.Control type="text" name="nickName" placeholder={content.placeholder2} value={values.nickName} disabled />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Group controlId="formBasicloginone">
                                    <Form.Label>{content.label2}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={content.placeholder3}
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                            </div>

                            <div className="col-md-6">
                                <Form.Group controlId="formBasicloginone">
                                    <Form.Label>{content.label3}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={content.placeholder4}
                                        name="cpassword"
                                        value={values.cpassword}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                            </div>


                            <div className="col-md-6">
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{content.label4}</Form.Label>
                                    <Form.Control type="email" placeholder={content.placeholder5} value={values.email} disabled />

                                </Form.Group>
                            </div>


                            <div className="col-md-6">
                                <Form.Group controlId="formBasicCountry">
                                    <Form.Label>{content.label5}</Form.Label>
                                  
                                    <DropdownButton id="formBasicCountry" title={dropdowncountry}>
                                                        
                                                        {country.map(item => (
                                                                <Dropdown.Item onClick={() => handleSelect(item)}>{item} </Dropdown.Item>
                                                            ))}
                                                       
                                                      
                                                    </DropdownButton>
                                </Form.Group>
                            </div>












                            <div class="col-md-12">
                                <div class="text-center">
                                    <Button type="submit" className="red-btn"  disabled={loading}>{loading ?  
                                    <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      /> : content.submit}</Button>
                                </div>
                            </div>

                        </div>
                    </Form>

                </div>

            </div>

        </div>



    );
};

export default EditProfile;