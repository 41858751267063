import React, { useEffect, useState } from "react";
import FacebookLogin from 'react-facebook-login';
import { Redirect } from 'react-router-dom';
import {FacebookAppID} from '../../config/keys';
import { userInstance } from "../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";


const INITIAL_STATE = {
    email: '',
    password: ''
}

const Facebook = ({btntext}) => {
    const [values, setValues] = useState(INITIAL_STATE);
    const responseFacebook = async (response) => {
        console.log('====>', response)
        if(response.email){
        const guser = await userInstance.post("/fblogin", response);
        if (guser.data.code === 200) {
            localStorage.setItem('token', guser.data.token);
            let payload = tokenparser(guser.data.token);
            localStorage.setItem('Userid', payload.userid);
            window.location.href = '/'
            console.log("rsopnse", guser);
        }
        else if( guser && guser.data.code !== 200) {
            NotificationManager.error("No Email in FB Plz login Manually", "Message", 4000);
        }
    }
    }

    const tokenparser = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    return (
        <FacebookLogin
            appId={FacebookAppID}
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            textButton = {btntext}
            />);
}
export default Facebook

