import React, { useEffect, useState } from "react";
import "./adminchat.css";
import AdminLayout from "../layout";
import { socket } from '../../../config/socket';
import ChatWindow from "./chatwindow";
import { adminInstance, userInstance } from '../../../axios/axiosconfig';
import {Badge} from 'react-bootstrap'
// import profilepic from "../../assets/images/home/5.png";


const AdminToUserChat = () => {
  const [message, setMessage] = useState('');
  const [userlist, setUserList] = useState([]);
  const [selectUserChat, setSelectUserChat] = useState([]);
  const [currentUserId, setCurrentuserId] = useState('');
  const [nickName, setnickName] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [otherMessage, setOtherMessage] = useState([]);
 

  useEffect(() => {
    adminDetail();
    fetchRecentChatUser();
    socket.on('usertoadmin', (data) => {
      newMessage(data);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const newMessage = (data) => {
    console.log("userlist", userlist, data, data.from);
    let currentUser = localStorage.getItem('ChatUser');  
        setUserList((preState) => {
          const find = preState.filter(item => item.from === data.from)
          if(find && find.length === 0)
          return [...preState,{from: data.from, nickName: data.nickName, _id: data.from }]
          else
          return [...preState]
          })
        console.log('current', currentUser, "from", data.from)
      if (currentUser.toString() === data.from.toString()) {
        setTimeout(() => {
          messages();
          ScrollDown();
        },500) 

      }else{
        setOtherMessage((prevState) => {
          let other = [...prevState];
          let index = other.findIndex(item => item.from.toString() === data.from.toString())
        other[index].newMessage = true;
        other[index].count = other[index].count+1;
        return other
        })
        

      }
  }
  const fetchRecentChatUser = async() => {
    const response = await userInstance.post('/fetchRecentChatUser');
    if(response.data.code === 200){
      setUserList(response.data.data);
      let users=[];
      response.data.data.forEach(item => {
        let data = {
          newMessage: false,
          count:0,
          from:item.from
        };
        users.push(data);
      })
      setOtherMessage(users);
    }
  }
  const ScrollDown=()=>{
    setTimeout(() => {

      document.querySelector("#scrollBottom li:last-child").scrollIntoView({
        behavior: "smooth"
      });
    }, 1000);
  }

  const adminDetail = async () => {
    const response = await userInstance.get('/adminDetail');
    if(response.data.code === 200){
      setAdmin(response.data.admin[0]._id);
    }
  
  }

  const handleChange = (e) => {
    setMessage(e.target.value);
  }


  const messages = async (req, res) => {
    let currentUser = localStorage.getItem("ChatUser");
    const payload = {
      userId: currentUser
    }
    const result = await adminInstance.post('/fetchMessageAdmin', payload);
    // console.

    setSelectUserChat(result.data.data);
  }


  const handleSend = async () => {
    let obj = {
      to: currentUserId,
      from: admin,
      content: message,
      isRead: false,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString()
    }
    selectUserChat.push(obj);
    socket.emit("adminUserReply", obj);
    ScrollDown();
    const payload = {
      to: currentUserId,
      content: message,
      type: 'chat'
    }

    const saved = await adminInstance.post('/sendMessageAdmin', payload);
    setMessage('');
  }

  const selectUser = async (id, name) => {
    let other = otherMessage
        let index = other.findIndex(item => item.from.toString() === id.toString())
        other[index].newMessage = false;
        other[index].count = 0;
        setOtherMessage(other)
    setCurrentuserId(id);
    setnickName(name);
    localStorage.setItem('ChatUser', id);

    const payload = {
      userId: id
    }
    const messages = await adminInstance.post('/fetchMessageAdmin', payload);
    setSelectUserChat(messages.data.data);


  }

  return (
    <AdminLayout navpanel={true}>
      <div className="adminchat">
        <div className="container clearfix">
        <div className="chat-userr">
          <div className="people-list" id="people-list">
              <div className="about users-name">
                <h3 className="name">Users</h3>
              </div>
            <ul className="list">
              {userlist.length > 0 ? userlist.map((ele,i) =>
                <li className="clearfix" onClick={() => selectUser(ele._id, ele.nickName)} key={`item-${i}`}>
                  <div className="about">
                    <div className="name">
                      {ele.nickName}
                      {otherMessage.length > 0 ? otherMessage[i].newMessage ? <span> <Badge variant="danger">{otherMessage[i].count}</Badge></span>: '':''}
                    </div>
                  </div>
                </li>
              ) : ""}
            </ul>
          </div>

          <div className="chat">

            {nickName && <div className="chat-header clearfix">
             
              <div className="chat-about">
                <div className="chat-with">{nickName}</div>
               
              </div>
              
            </div>}

            <div className="chat-history">
              <ul id="scrollBottom"> 
                <ChatWindow
                  currentUserId={currentUserId}
                  admin={admin}
                  selectUserChat={selectUserChat}
                  setSelectUserChat={setSelectUserChat}
                />
              </ul>
            </div>

         
          </div>

          </div>
             {currentUserId && <div className="chat-message clearfix">

              <input
                name="message-to-send"
                id="message-to-send"
                placeholder="Type your message"
                onChange={handleChange}
                value={message}
              >
              </input>
              <button onClick={handleSend}>Send</button>

            </div>}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminToUserChat;
