import React from 'react'
import Header from '../header'
import Footer from '../footer'
import NavigationPanel from '../navigation-panel'
import { createBrowserHistory } from 'history';
const history = createBrowserHistory()
export default class AdminLayout extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            menu:false
        }
    }

    handleMobileMenu = (e) =>{
        if(e === 'open'){
            this.setState({menu:true})
        }else if(e === 'close'){
            this.setState({menu:false}
                )
        }else if(e=== 'toggle'){
            this.setState({menu:!this.state.menu}
                )
        }
    }
    render(){
        return(
            <React.Fragment>
            <Header handleMobileMenu={this.handleMobileMenu} menu={this.state.menu}/>
            <NavigationPanel history={history} show={this.props.navpanel} menu={this.state.menu}/>
            <main>
            {this.props.children}
            <Footer/>
            </main>
            </React.Fragment>
        )
    }
}