import axios from "axios";
import {client, server} from '../config/keys.js';

let token = localStorage.getItem('token');
export const userInstance = axios.create({
    baseURL: server+'/user',
    withCredentials: true,
    headers: {'x-custom-header': token}
});

export const adminInstance = axios.create({
    baseURL:server+'/admin',
    withCredentials:true
});

export const cmsInstance = axios.create({
    baseURL: server + '/cms',
    withCredentials: true
});