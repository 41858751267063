import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/header/logonew.png";
import "./style.css";
import { isauth } from "../../../function/function";
import { adminInstance } from '../../../axios/axiosconfig';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false
    };
  }

  componentDidMount = () => {
    const auth = isauth();
    this.setState({
      valid: auth
    });
  }

  handleLogout = async () => {
    localStorage.clear();
    const response = await adminInstance.post("/logout");
    window.location.href = "/admin/login";
  }



  render() {
    return (
      <div className="header admin_header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3  col-xl-3 custom-header-sec1 col-sm-3 col-3">
              <div className="logo">
                <Link to="/admin/dashboard">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-9 col-xl-9 custom-header-sec3 col-sm-9 col-9">
              <div className="menu menu2">
                <div className="custom-loggedin-menu">
                  {!this.state.valid
                    ?
                    <Link to="/admin/login">
                      <h4 className="m-b-0" style={{ color: '#d1d1d1' }}>Admin Login</h4>
                    </Link>
                    :
                    <div className="loggedsett">

                      <Link
                        onClick={this.handleLogout}
                        className="desktop_view">
                        <i
                          className="fa fa-sign-out"
                          title="Logout"
                          aria-hidden="true"
                        />
                      </Link>
                      <button
                        className={`mobile_view`}
                        onClick={() => this.props.handleMobileMenu('toggle')}
                      >
                        {this.props.menu
                          ?
                          <i className="fa fa-times" />
                          :
                          <i className="fa fa-bars" />
                        }

                      </button>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
