import React, {useState, useEffect}from 'react';
import { Table, Button, Form, Spinner } from 'react-bootstrap'
import {server} from '../../../config/keys';
import './friend.css';

const PlayerList = ({
          allPlayers,
          tab,
          handleAddFriend,
          handleCancelSendRequest,
          handleCancelReceiveRequest,
          handleAcceptRequest,
          handleUnFriend,
          disabled,
          content
}) => {
  
  //  setFriend(props.allPlayers.friend);
  //  setSend(props.allPlayers.send);
  //  setReceive(props.allPlayers.receive);
  
  console.log(allPlayers);
    let Item;
   if(tab == 'player'){
         Item = allPlayers.data && allPlayers.data.map((el, i) => {
          {
          return (

              <tr key={i}>
                  <td><img src={`${server}/`+ el.photo}  alt ='' /></td>
                <td className="game-player-result__date">{el.nickName}</td>
                <td className="game-player-result__vs">{el.country}</td>
                <td className="game-player-result__vs">{el.Level}</td>
                <td className="game-player-result__vs">
                  {
                     
                     allPlayers.send && allPlayers.send.filter(send => send._id == el._id).length > 0 ?
                    <>{content.requestPending}
                    {" "}
                    <Button className="blue-btn" disabled={disabled} onClick={() => handleCancelSendRequest(el._id)}>{content.cancel}</Button></> :
                     allPlayers.receive && allPlayers.receive.filter(receive => receive._id == el._id).length > 0 ?
                     <><Button disabled={disabled} onClick={() => handleAcceptRequest(el._id)}> {content.accept}</Button>
                     {" "}
                     <Button  className="blue-btn" disabled={disabled} onClick={() => handleCancelReceiveRequest(el._id)}> {content.cancel}</Button>
                     </>:
                     allPlayers.friend && allPlayers.friend.filter(friend => friend._id == el._id).length === 0 ?
                     <Button  className="blue-btn " disabled={disabled} onClick={() =>  handleAddFriend(el._id)}>{content.add_friend}</Button>:
                     <Button  className="blue-btn" disabled={disabled} onClick={()=> handleUnFriend(el._id)}>{content.unfriend}</Button>
                  }
                 
                </td>
              </tr>
            );
          }
      });
   }else if(tab == 'friend'){
     console.log('friend');
     Item = allPlayers.friend && allPlayers.friend.map((el, i) => {
      {
      return (
          <tr key={i}>
              <td><img src={`${server}/`+ el.photo}  alt ='' /></td>
            <td className="game-player-result__date">{el.nickName}</td>
            <td className="game-player-result__vs">{el.country}</td>
            <td className="game-player-result__vs">{el.Level}</td>
            <td className="game-player-result__vs">
                  
                    
                     <Button className="blue-btn"  disabled={disabled} onClick={()=> handleUnFriend(el._id)}>{content.unfriend}</Button>
                  
                 
                </td>
          </tr>
        );
      }
  });
   }else if(tab == 'send'){
     console.log('send');
    Item = allPlayers.send && allPlayers.send.map((el, i) => {
      {
      return (
          <tr key={i}>
              <td><img src={`${server}/`+ el.photo}  alt ='' /></td>
            <td className="game-player-result__date">{el.nickName}</td>
            <td className="game-player-result__vs">{el.country}</td>
            <td className="game-player-result__vs">{el.Level}</td>
            <td className="game-player-result__vs">
                  
                    
                  <Button className="blue-btn" disabled={disabled} onClick={() => handleCancelSendRequest(el._id)}>{content.cancel_request}</Button>
               
              
             </td>
          </tr>
        );
      }
  });
   }else{
     console.log('received')
    Item = allPlayers.receive && allPlayers.receive.map((el, i) => {
      {
      return (
          <tr key={i}>
              <td><img src={`${server}/`+ el.photo}  alt ='' /></td>
            <td className="game-player-result__date">{el.nickName}</td>
            <td className="game-player-result__vs">{el.country}</td>
            <td className="game-player-result__vs">{el.Level}</td>
            <td className="game-player-result__vs">
                  
                    
            <Button className="blue-btn" onClick={() => handleAcceptRequest(el._id)}>{content.accept}</Button>{" "}
      <Button className="blue-btn" disabled={disabled} onClick={() => handleCancelReceiveRequest(el._id)}>{content.cancel}</Button>
                 
                </td>
          </tr>
        );
      }
  });
   }
      return (
        <div className="player-list">
        {/* <div className="card__content"> */}
            <section className="home-table">
                <div className="table-responsive">
                {console.log(allPlayers.friend, allPlayers.send, allPlayers.receive)}
                    <Table  bordered responsive className="table table-hover game-player-result">
                    <thead>
                        <tr>
                        <th></th>
                        <th className="game-player-result__date text-left">{content.name}</th>
                        <th className="game-player-result__vs text-left">{content.country}</th>
                        <th className="game-player-result__character text-center"> {content.level} </th>
                        <th className="game-player-result__character text-center"> {content.action} </th>
                        </tr>
                    </thead>
                    <tbody>{ tab === 'player'? 
                               allPlayers.data && allPlayers.data.length !== 0 ? Item : <tr><td colSpan={3}>{content.player}</td></tr>
                            :tab === 'friend'? 
                            allPlayers.friend && allPlayers.friend.length !== 0 ? Item : <tr><td colSpan={3}>{content.nofriend}</td></tr>
                            :tab === 'send'? 
                            allPlayers.send && allPlayers.send.length !== 0 ? Item : <tr><td colSpan={3}>{content.nosend}</td></tr>
                            : 
                            allPlayers.receive && allPlayers.receive.length !== 0 ? Item : <tr><td colSpan={3}>{content.noreceive}</td></tr>
                              }</tbody>
                    </Table>
                </div>
          </section>
          <section>
              
          </section>
        {/* </div> */}
        </div>
      );
    

   
}

export default PlayerList;