import React, { useEffect, useState } from "react";
import Layout from '../layout'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cmsInstance } from '../../axios/axiosconfig';
import { NotificationManager } from 'react-notifications';

const Help = () => {
    const { i18n } = useTranslation();
    const [content, setContent] = useState({});

    const getContent = async () => {
        try {
            const page = '/help'
            let language = i18n.language;
            const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
            if (status === 200) {
                setContent(content);
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        getContent();
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const onLanguageChanged = () => {
        getContent();
    }

    return (

        <Layout>
            <div className="legal">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-page help-title">
                                <ul className="breadcrum">
                                    <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.help}</li>
                                </ul>
                                <h2>{content.help}</h2>
                                <p>{content.para1}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="legal-content-box">
                                <h2>{content.para2}</h2>
                                <h3>{content.help}</h3>
                                <p>{content.para3}</p>
                                <h4>{content.help}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Help;