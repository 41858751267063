import React from "react";
import AdminLayout from "../layout";
import UserList from "./components/user-list";
import "./style.css";
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{ id: `hjh` }],
      fullList:"",
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      block: false,
      banned:false,
      query: '',
      filter:"nickName",
      popup:false,
      currentUserId:'',
      page: 1,
      countUser: 0
    };
  }

  componentDidMount = () => {
    this.getUsers(this.state.page, 200);
  }

  getUsers = async (pageValue, paginationValue) => {
    this.setState({
      page:pageValue
    })
    let filterBy = '';
    let p = pageValue ? pageValue : 1;
    
    let payload = {
      data: filterBy,
      page: p,
      pagination: paginationValue
    }
    const response = await adminInstance.post('/getUsers', payload);
    if (response.data.code == 200) {
      const users = response.data.data;
      this.setState({
        list: users, fullList: users,
        countUser: response.data.count
      });
      console.log(users);
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleBlock = async (id, block) => {
    const payload = {
      id: id,
      block: !block,
      query:this.state.query
    }
    const response = await adminInstance.post('/blockUser', payload);
    if (response.data.code == 200) {
     
      NotificationManager.success("Activity Success", "Message", 4000);
      this.setState({
        list: response.data.data
      });
    } else {
      NotificationManager.error(response.data.msg, "Message", 4000);
    }
  }
  handleBanned = async (id, banned) => {
    const payload = {
      id: id,
      banned: !banned,
      query:this.state.query
    }
    if(!banned){
      const notify ={
        userId: id,
        msg:"your account is bannded"
      }
      const response = await adminInstance.post("/notify", notify);
    
    }
    if(!banned){
      this.setState({popup:true,currentUserId:id});
    }
    const response = await adminInstance.post('/banUser', payload);
    if (response.data.code == 200) {
      NotificationManager.success("Activity Success", "Message", 4000);
      this.setState({
        list: response.data.data,
      });
    } else {
      NotificationManager.error(response.data.msg, "Message", 4000);
    }
  }
  handlePopup=()=>{
    this.setState({popup:false})
  }

  handleSearch = async (e) => {
     this.setState({
      query: e.target.value
    });
   let length= e.target.value.length;
    if(this.state.filter==="nickName"){
      let arr = this.state.fullList.filter(ele=>ele.nickName.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
      this.setState({list:arr});
    }
    if(this.state.filter==="email"){
      let arr = this.state.fullList.filter(ele=>ele.email.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
      this.setState({list:arr}); 
    }
    if(this.state.filter==="country"){
      let arr = this.state.fullList.filter(ele=>ele.country.substring(0,length).toUpperCase()===e.target.value.toUpperCase())
      this.setState({list:arr}); 
    }
    if( this.state.query===""){
      this.setState({list:this.state.fullList});
    }
   
    // let query = this.state.query;
    // const payload = {
    //   query: e.target.value
    // };
    // const response = await adminInstance.post('/searchUser', payload);
    // console.log(response);
    // if (response.status == 200) {
    //   this.setState({
    //     list: response.data.users
    //   });
    // } else {
    //   NotificationManager.error(response.data.msg, "Message", 4000);
    // };
  }
  handleFilter=(e)=>{
      this.setState({filter:e.target.value});
  }

  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-12">
              <div className="card__content">
                {/* <div className="card--has-table card">
                  <SectionHeader title="User Search" />
                  <UserSearch
                    {...this.state}
                    handleSearch={this.handleSearch}
                  />
                </div> */}
                <div className="card--has-table card">
                  {/* <SectionHeader title="User list"
                   query={this.state.query}
                   filter={this.state.filter}
                   handleFilter={this.handleFilter}
                    handleSearch={this.handleSearch} /> */}
                  <UserList
                    {...this.state}
                    handleBlock={this.handleBlock}
                    handleBanned={this.handleBanned}
                    handlePopup={this.handlePopup}
                  />
                  <div className="pagination-section">
                 {this.state.page > 1 ? <button onClick={ () => this.getUsers(this.state.page - 1, 200)} className="previous">Previous</button> : ''} 
                    {this.state.countUser > 200 * this.state.page && <button onClick={() => this.getUsers(this.state.page + 1, 200)} className="next">Next</button>}
                  
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
