import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
export default class Layout extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
    return(
        <div className="main-wrapper">
        <Header/>
        <main>
        <div className="main_content">
        {this.props.children}
        </div>
        </main>
        <Footer/>
        </div>)
    }
}