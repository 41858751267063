import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import twitter from "../../assets/images/home/twitter-icon.png";
import youtube from "../../assets/images/home/youtube-icon.png";
import facebook from "../../assets/images/home/facebook-icon.png";
import instagram from "../../assets/images/home/instagram-icon.png";
import chat from "../../assets/images/home/chat-icon.png";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import HometileSlider from "./home-tile-slider";
import Hometiletwo from "./home-tile2-slider";




const HomeTiles = ({content}) => {
    const { t, i18n } = useTranslation();

    return (

        <div className="home-tile-section">



            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="caution-age">
                            <p><span>+18</span> {content && content.para1}</p>
                        </div>
                    </div>

                    <div className="col-md-6 tile-slider-one">
                        <div className="home-tile-box">
                            {/* <div className="home-tile-info">
                                {/* <span>{content && content.now}</span>
                                <h4>{content && content.takefree}</h4>
                                <h2>{content && content.takeprice}</h2> 
                               <HometileSlider />
                            </div> */}
                            <HometileSlider content={content} />
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="home-tile-box  tile-2">
                            <div className="home-tile-info">
                                <span>{content && content.be}</span>
                                <h4>{content && content.champion}</h4>
                               
                            </div>
                        </div>

                        <div className="home-tile-box  tile-3">
                            <div className="home-tile-info">
                                <span>{content && content.onewin}</span>
                                <h4>{content && content.twoprices}</h4>
                               
                            </div>
                        </div>

                        <div className="home-tile-box  tile-4">
                            {/* <div className="home-tile-info">
                                <span>{content && content.letgame}</span>
                                <span>{content && content.withfriends}</span>
                               
                            </div> */}
                            <Hometiletwo content={content} />
                        </div>
                        
                    </div>
                  
                </div>
            </div>
        </div>
    );
};

export default HomeTiles;