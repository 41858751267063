import React, { useEffect, useState } from "react";
import "./registrationSuccess.css";
import { cmsInstance } from "../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
import { Button } from 'react-bootstrap';
const RegistrationSuccess = ({handleRegistrationSuccessClose}) => {
  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getContent();
  },[])
  
  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = '/registersuccess';
       let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
      const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
      if (status == 200) {
        setContent(content);
        setIsLoading(true);
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.log('Error', error.message);
    }
  }
  
  return (

      <div className="withdraw">
        <div className="container">
        {isLoading && (
        <div className="withdraw-content">
         
            <h2>{content.heading1}</h2>
                <label>{content.msg}</label>
                <div class="text-center">
                  <Button type="button" className="red-btn" onClick={e=> {handleRegistrationSuccessClose()}}>{content.submit}</Button>
                </div>   
              
        </div>)}
        </div>
      </div>

  );
};

export default RegistrationSuccess;
