import React, { useEffect, useState } from 'react'
import './deposit.css'
import Layout from '../layout'
import { Link } from 'react-router-dom'
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'
import querString from 'query-string'
import { NotificationManager } from 'react-notifications'
import { server } from '../../config/keys.js'
import Alert from 'react-bootstrap/Alert'
import { useTranslation } from 'react-i18next'
import { cmsInstance } from '../../axios/axiosconfig'
import { userInstance } from '../../axios/axiosconfig'
import { convertAmountToOtherCurrencies } from '../../function/function'

const Deposit = () => {
  const currency = localStorage.getItem('currency');
  const { t, i18n } = useTranslation()
  const [amount, setAmount] = useState(null)
  const [showStatus, setShowStatus] = useState(null)
  const [content, setContent] = useState({})
  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    const paymentStatus = querString.parse(window.location.search)
    console.log('paymentStatus==>', paymentStatus)
    if (
      paymentStatus.payment === 'success' ||
      paymentStatus.payment === 'fail'
    ) {
      setShowStatus(paymentStatus.payment)
      setTimeout(() => {
        window.location.href = '/'
      }, 4000)
    }
    getContent()
  }, [])

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = '/addmoney'
      let language = localStorage.getItem('activeLanguage')
        ? localStorage.getItem('activeLanguage')
        : localStorage.getItem('i18nextLng')
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`)
      if (status == 200) {
        setContent(content)
      } else {
        NotificationManager.error(msg, 'Message', 10000)
      }
    } catch (error) {
      console.log('Error', error.message)
    }
  }

  const payment = async (e) => {
    e.preventDefault()
    console.log('hello');
    if (amount !== null) {
      const result = await convertAmountToOtherCurrencies(amount, currency)
      const payload = {
        totalPrice: result,
      }
      const res = await userInstance.post('/payment', payload)
      if (res.data.code === 200) {
        window.location.href = res.data.data
      }
    } else {
      setErrMsg(content.notification1)
    }
  }

  const handlechange = (e) => {
    setAmount(e.target.value)
  }

  return (
    <div className='deposit'>
      <div className='container'>
        <div className='deposite-content'>
          <h2>{content.heading1}</h2>

          <div className='deposit-p'>
            {showStatus && showStatus === 'success' ? (
              <Alert variant='success' className='succ-box'>
                <Alert.Heading>{content.heading2}</Alert.Heading>
                <p>{content.redirect}</p>
              </Alert>
            ) : (
              ''
            )}
            {showStatus && showStatus === 'fail' ? (
              <Alert variant='danger' className='succ-box'>
                <Alert.Heading>{content.heading3}</Alert.Heading>
                <p>{content.redirect}</p>
              </Alert>
            ) : (
              ''
            )}
          </div>
          <Form onSubmit={payment}>
          <Form.Group>
            <label htmlFor='Amount'>{content.label1}</label>
            <InputGroup className='mb-3'>
              {errMsg === '' ? (
                <Form.Control
                  type='number'
                  autocomplete='off'
                  className='deposit-in'
                  value={amount}
                  id='Amount'
                  onChange={handlechange}
                  min={1}
                />
              ) : (
                <>
                  <Form.Control
                    type='number'
                    autocomplete='off'
                    className='deposit-in'
                    value={amount}
                    id='Amount'
                    onChange={handlechange}
                    min={1}
                  />
                 
                </>
              )}
              <InputGroup.Append>
                <InputGroup.Text id='basic-addon2'>{currency}</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <p style={{ color: 'white' }}>{errMsg}</p>
            {/* <Form.Group controlId='formBasicloginone'>
              <Form.Label htmlFor='Amount'>{content.label1}</Form.Label>
              {errMsg === '' ? (
                <Form.Control
                  type='number'
                  autocomplete='off'
                  className='deposit-in'
                  value={amount}
                  id='Amount'
                  onChange={handlechange}
                  min={1}
                />
              ) : (
                <>
                  <Form.Control
                    type='number'
                    autocomplete='off'
                    className='deposit-in'
                    value={amount}
                    id='Amount'
                    onChange={handlechange}
                    min={1}
                  />
                  <p style={{ color: 'white' }}>{errMsg}</p>
                </>
              )}
            </Form.Group> */}

            <div class='text-center'>
              <Button type='submit' className='red-btn'>
                {content.label2}
              </Button>
            </div>
          </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Deposit
