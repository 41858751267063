import React, { useEffect, useState } from "react";
import "./tournament.css";
import Layout from '../layout'
import { socket } from '../../config/socket';
import { Form, Button, Alert } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import pongicon from "../../assets/images/home/pong.png";
import poolicon from "../../assets/images/home/pool.png";
import fifaicon from "../../assets/images/home/fifa.png";
import { userInstance,cmsInstance } from "../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TournamentPage = () => {
    const { t, i18n } = useTranslation();
    const [tournament, setTournament] = useState([]);
    const [createTournament, setCreateTournament] = useState(false);
    const [content, setContent] = useState({});
    const [page, setPage] = useState(1);
    const [noData, setNoData] = useState(true);
    const [finishTournament, setFinishTournament] = useState([]);
    // const [JoinStatus, setJoinStatus] = useState(true);
    
    useEffect(() => {
        
        getContent();
        getEligible();
        getTournaments(1, 5);
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const getTournaments = async (pageValue, paginationValue) => {
        setPage(pageValue);
        let filterBy = '';
        let p = pageValue ? pageValue : 1;
        
        let payload = {
          data: filterBy,
          page: p,
          pagination: paginationValue
        }
        const response = await userInstance.post("/getTournamentsUser",payload);
        console.log('response', response);
        if (response.data.code === 200) {
            let newData = response.data.data.filter(ele => ele.tournamentEnd);
            let tournament = response.data.data.filter(ele => !ele.tournamentEnd)
            setFinishTournament(newData);
            setTournament(tournament);

        }
    }

    const getEligible = async() => {
        const res = await userInstance.get('/eligible');
        console.log("eligible criteria=>", res.data);
        if (res.data.code === 200) {
            setCreateTournament(res.data.eligible);
        }
    }

    const onLanguageChanged = () => {
        console.log("language change",localStorage.getItem('activeLanguage'))
        getContent();
       }
    const JoinTournament = async (tid) => {
        const payload = {
            tournamentId: tid,
            socketId: socket.id
        }
        const res = await userInstance.post("/joinTournament", payload);
        console.log('response', res);
        if (res.data.code == 200) {
            const result = res.data.joinStatus;
            let Tournament =[...tournament]; 
            let index = tournament.findIndex(ele=>ele._id===tid);
            console.log("check==>",Tournament,tournament,index );
            // let obj = tournament[index];
            //  obj.joinStatus=true;
            Tournament[index].joinStatus=true;
            setTournament(Tournament);
            
            // setBtnStatus(result);
            NotificationManager.success(content.notification1, "Message", 4000)
        } else if(res.data.code===401){
                NotificationManager.error(content.notification2,"Message",4000);
            }
            else if(res.data.code===301){
                NotificationManager.error(content.notification3,"Message",4000);
            }
            else if(res.data.code===302){
                NotificationManager.error(content.notification4,"Message",4000);
            }
            else if(res.data.code===303){
                NotificationManager.error(content.notification5,"Message",4000);
            }
            else if(res.data.code===304){
                NotificationManager.error(content.notification6,"Message",4000);
            }
            else{
            NotificationManager.error("Error in Join", "Message", 4000);}
        
    }

    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/tournament';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
            setContent(content);
            
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }

    const tournamentcreation = () => {
       
        if (createTournament) {
            window.location.href = "/createtournament";
        }
        else {
            NotificationManager.error(content.notification7, "Message", 4000);
        }
    }

    return (
        <Layout>

                    <section className="tournament-page">
                        <div className="container">
                           

                        <div className="row">
                        <div className="col-md-12">
                            <div className="title-page">
                                <ul className="breadcrum">
                                <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.tournament}</li>
                                </ul>
                                <h2>{content.tournament}</h2>
                                {createTournament? <Button className="red-btn" onClick={tournamentcreation}>{content.createtournament}</Button>: ""}
                               </div>
                        </div>
                    </div>



                            <div className="row">



                            <div class="col-md-12 col-lg-12">
                                <div className="home-table">

                                <div className="responsive-table">
                                <h3>{content.upcoming}</h3>
                                        <Table bordered responsive> 
                                            <thead>
                                                <tr>
                                                <th>{content.game}</th>
                                                    <th>{content.date}</th>
                                                    <th>{content.time}</th>
                                                    <th>{content.entryfee}</th>
                                                    <th>{content.winprize}</th>
                                                    <th>{content.players}</th>
                                                    <th>{content.joinnow}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tournament && (
                                                    tournament.map((value, i) => {
                                                        return [<tr key={i}>
                                                            <td>{value.title}</td>
                                                            <td>{value.tournamentStart.substring(0,10)}</td>
                                                            <td>{new Date(value.tournamentStart).toLocaleTimeString()}</td>
                                                        <td>{value.tournamentEntryFee}{" USD"}</td>
                                                            <td>{value.amt.length === 3? <> 1st : {value.amt[0]}{" USD"}, &nbsp; 2nd: {value.amt[1]}{" USD"}, &nbsp; 3rd: {value.amt[2]}{" USD"}</>:
                                                                value.amt.length ===2 ? <>1st : {value.amt[0]}{" USD"}, &nbsp; 2nd: {value.amt[1]}{" USD"}</> :
                                                        <>{value.amt[0]}{" USD"}</> }</td>
                                                            <td>{value.playerNumbers}</td>
                                                            <td>{!value.joinStatus?<button className="btn" onClick={() => JoinTournament(value._id)}>{content.join} <span>&gt;&gt;</span></button>:content.joined}</td>
                                                        </tr>];
                                                    })
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="pagination-section">
                                     { page > 1 ? <button onClick={ () => getTournaments(page - 1, 5)} className="previous">{content.previous}</button> : ''} 
                    {tournament.length === 5 ? <button onClick={() => getTournaments(page + 1, 5)} className="next">{content.next}</button> : ''}
                                       
                                     </div>
                                    </div>
                                </div>



                            <div class="col-md-12 col-lg-12 finished-tournament">
                                <div className="home-table">

                                  <div className="responsive-table">
                                  <h3>{content.finished} </h3>
                                        <Table bordered responsive>
                                            <thead>
                                                <tr>
                                                <th>{content.game}</th>
                                                <th>{content.entryfee}</th>
                                                <th>{content.participents}</th>
                                                    <th>{content.winner}</th>
                                                    <th>{content.prize}</th>
                                                   
                                                    
                                                </tr>
                                            </thead>
                                             <tbody>
                                            {finishTournament && (
                                                    finishTournament.map((value, i) => {
                                                        return [
                                                            <tr key={i}>
                                                    <td><b>{value.title} </b></td>
                                                        <td> {value.tournamentEntryFee}{" USD"}</td>
                                                        <td>{value.playerJoined.length}</td>
                                                    <td className="winner">
                                                            {value.winner.length === 3 ? <>1st : {value.winner[0]}{" USD"}, 2nd : {value.winner[1]}{" USD"}, 3rd : {value.winner[2]}{" USD"} </>: 
                                                            value.winner.length === 2  ?  <>1st : {value.winner[0]}, 2nd : {value.winner[1]}</> :
                                                            value.winner[0]
                                                            }
                                                    </td>
                                                    <td className='prize'>{value.amt.length === 3? <> 1st : {value.amt[0]}{" USD"}, &nbsp; 2nd: {value.amt[1]}{" USD"}, &nbsp; 3rd: {value.amt[2]}{" USD"}</>:
                                                                value.amt.length ===2 ? <>1st : {value.amt[0]}{" USD"}, &nbsp; 2nd: {value.amt[1]}{" USD"}</> :
                                                                <>{value.amt[0]}{" USD"}</> }
                                                    </td>
                                                
                                                </tr>
                                                        ]
                                                    
                                                })
                                            )}
                                           {finishTournament.length === 0 ? <tr style={{textAlign:'center', display:'flex',justifyContent:'center'}}><td></td><td colspan ='4'>{content.noTournament}</td></tr>:""}
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>

                                </div>
                            </div>

                        </div>
                    </section>


        </Layout>

    );
};

export default TournamentPage;