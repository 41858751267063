import React, { useEffect } from "react";
import "../admin.css";
import menu from '../../../assets/images/cms/menu-icon.png'
import admin from '../../../assets/images/cms/user.jpg'


const AdminHeader = ({setMenuToggle, menutoggle}) => {






    return (
        <div className="admin-header">

            <div className="menu-toggle">
                <a href="javaScript:void(0)" onClick={()=>setMenuToggle(!menutoggle)} >
                    <img src={menu} alt="Menu" />
                </a>
            </div>

            <div className="admin-info">
                <img src={admin} alt="Admin" />
                <h3>Admin</h3>
            </div>
   
        </div>
    );
  };
  
export default AdminHeader;
