import React from "react";
import SectionHeader from "../../section-header";

export default class MatchesList extends React.Component {
  render() {
    console.log("=====>",this.props.matchdata);
    return (<>
    <SectionHeader title='Live Matches'/>
      <div className="card__content">
        <div className="table-responsive">
        <table className="table table-hover game-player-result">
            <thead>
            <tr>
                                                        <th>Match Type</th>
                                                        <th>Bet Amount</th>
                                                        <th>Winning Amount</th>
                                                        <th>VS</th>
                                                        <th>Score</th>
                                                        <th>Winner</th>
                                                        
                                                    </tr>
            </thead>
            <tbody>{this.props.matchdata.list && this.props.matchdata.list.map(ele => {
                                                      
                                                      return  ele.matches.map(match => {
                                                         
                                                           return(
                                                           <tr><td>{ele.betAmount ? <>Bet Match</>:<>Friendly Match</>}</td>
                                                           <td>{ele.betAmount}</td>
                                                           <td>{ele.betPot}</td>
                                                           <td>{ele.playersData.length ? ele.playersData[0].name: ""} VS {ele.playersData.length ? ele.playersData[1].name: ""}</td>
                                                               <>{
                                                               match.player_one_score > match.player_two_score ?
                                                               <><td>{match.player_one_score}: {match.player_two_score}</td><td>{ele.playersData[0].name}</td> </>:
                                                               <><td>{match.player_one_score}: {match.player_two_score}</td><td>{ele.playersData[1].name}</td></> 
                                                                }</> </tr>
                                                                )
                                                               
                                                           
                                                       })
                                                   })}</tbody>
          </table>
       <div className="pagination-section">
       { this.props.matchdata.livepage > 1 ? <button onClick={ () => this.props.getMatches(this.props.matchdata.livepage - 1, 10)} className="previous">Previous</button> : ''} 
                    {this.props.matchdata.list.length === 10 ? <button onClick={() => this.props.getMatches(this.props.matchdata.livepage + 1, 10)} className="next">Next</button> : ''}
         
       </div>
        </div>
      </div>
      {/* Finished Matches */}
      <br/><br/><br/>
      <SectionHeader title='Finished Matches List'/>
      <div className="card__content">
        <div className="table-responsive">
        <table className="table table-hover game-player-result">
          <thead>
                                                <tr>
                                                        <th>Match Type</th>
                                                        <th>Bet Amount</th>
                                                        <th>Winning Amount</th>
                                                        <th>VS</th>
                                                        <th>Score</th>
                                                        <th>Winner</th>
                                                        
                                                    </tr>
                                                </thead>
            <tbody>{this.props.matchdata.finishMatch && this.props.matchdata.finishMatch.map(ele => {
                                                      
                                                      return  ele.matches.map(match => {
                                                         
                                                           return(
                                                           <tr><td>{ele.betAmount ? <>Bet Match</>:<>Friendly Match</>}</td>
                                                           <td>{ele.betAmount}</td>
                                                           <td>{ele.betPot}</td>
                                                           <td>{ele.playersData[0].name} VS {ele.playersData[1].name}</td>
                                                               <>{
                                                               match.player_one_score > match.player_two_score ?
                                                               <><td>{match.player_one_score}: {match.player_two_score}</td><td>{ele.playersData[0].name}</td> </>:
                                                               <><td>{match.player_one_score}: {match.player_two_score}</td><td>{ele.playersData[1].name}</td></> 
                                                                }</> </tr>
                                                                )
                                                               
                                                           
                                                       })
                                                   })}</tbody>
          </table>
       <div className="pagination-section">
       { this.props.matchdata.finishedpage > 1 ? <button onClick={ () => this.props.getRecentDuals(this.props.matchdata.finishedpage - 1, 10)} className="previous">Previous</button> : ''} 
                    {this.props.matchdata.finishMatch.length === 10 ? <button onClick={() => this.props.getRecentDuals(this.props.matchdata.finishedpage + 1, 10)} className="next">Next</button> : ''}
         
       </div>
        </div>
      </div></>
    );
  }
}
