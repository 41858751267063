import React, { useEffect, useState, useRef } from "react";
import Layout from "../layout";
import SectionHeader from "../admin/section-header";
import {
    InputLabel,
    TextareaLabel
} from "../admin/input-label";
import TournamentList from "./tournament-list";
import "./createtournament.css";
import { validateData } from '../../function/function';
import { userInstance, cmsInstance } from "../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
const INITIAL_VALUE = {
    list: [{ id: `hjh` }],
        subtitle: "",
        content: "",
        editid: "",
        update: false,
        editorHtml: "",
        theme: "snow",
        placeholder: "Write something....",
        Hashtags: "",
        title: "",
        tournamentStart: "",
        playerNumbers: "",
        tournamentType: "",
        tournamentEntryFee: "",
        tournamentPrizeMoney: "",
        tournamentGameScore:"",
        btnDisable:false,
        playedropdown: 'Select No of Players',
        typeDropdown: 'Select Tournament type',
        gametypeDropdown: 'Select Game',
        gameType: '',
};
const CreateTournament = () => {
    const {t,i18n} = useTranslation();
    
    const [values,setValues] = useState(INITIAL_VALUE);
    const [content, setContent] = useState({});


    useEffect(() => {
        getContent();
        getTournaments();
        i18n.on('languageChanged', onLanguageChanged);
    },[])

    
    
    const onLanguageChanged = () => {
        console.log("language change",localStorage.getItem('activeLanguage'))
        getContent();
       }

    const getContent = async () => {
        try {
          // const page = window.location.pathname.replace('/cms/', '');
          const page = '/createtournament';
           let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
          const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
          if (status == 200) {
           setValues({...values,
            playedropdown: content.select,
            gametypeDropdown:content.selectGame,
            typeDropdown: content.select1}
            );
            setContent(content);

          
          } else {
            NotificationManager.error(msg, "Message", 10000);
          }
        } catch (error) {
          console.log('Error', error.message);
        }
      }

    const getTournaments = async (req, res) => {
        let filterBy = '';
        let payload = {
            data: filterBy
        }
        const response = await userInstance.post('/getTournamentsByUser', payload);
        if (response.data.code == 200) {
            const tournaments = response.data.data;
           setValues({...values,
                list: tournaments
            });
        }else if(response.data.code === 400){ 
            localStorage.removeItem('token');
            localStorage.removeItem('Userid');
            localStorage.removeItem('nickName');
            window.location.href = '/login';
        }
    }

   const handleChange = (e) => {
        let { name, value } = e.target;
       setValues({...values, [name]: value });
    }

   const handledropdown = (i, ttt) => {
        if(ttt === 'player'){
            setValues({...values,playerNumbers:i,playedropdown:i })
        }
        else if(ttt === 'game'){
            setValues({
                ...values, gameType:i,gametypeDropdown:i
            })
        }
        else{
            setValues({...values,tournamentType: i,typeDropdown: i})
        }
        
        
    }

    const createTournament = async (e) => {
        const payload = {
            title: values.title,
            tournamentStart: values.tournamentStart,
            playerNumbers: values.playerNumbers,
            tournamentType: values.tournamentType,
            tournamentEntryFee: values.tournamentEntryFee,
            tournamentPrizeMoney: values.tournamentPrizeMoney,
            tournamentGameScore:values.tournamentGameScore,
            gameType : values.gameType
        };
        console.log("=====>",payload,values);
 
        const isValid = validateData([
            payload.title,
            payload.tournamentStart,
            payload.playerNumbers,
            payload.tournamentType,
            payload.tournamentEntryFee,
            payload.tournamentPrizeMoney,
            payload.gameType
        ]);

                    //     if(payload.tournamentGameScore<0 || payload.tournamentGameScore>11){
                    //         NotificationManager.error("Please enter vailid Score","Message",4000);
                    //         return null;
                    //     }
                    //     let res =checkTime(payload.tournamentStart);
                    //     if(res){
                    //         return null;
                    //     }
                    // if(payload.tournamentEntryFee<0 ){
                    //     NotificationManager.error("Please enter valid fees","Message",4000)
                    //     return null;
                    // }
                    
                    // if(payload.tournamentPrizeMoney<0) {
                    //     NotificationManager.error("Please enter valid PrizeMoney ","Message",4000)
                    //     return null;
                    // }
                    // if(payload.tournamentPrizeMoney<=payload.tournamentEntryFee){
                    //     NotificationManager.error("Please enter greter PrizeMoney than tournamet fees ","Message",4000)
                    //     return null;  
                    // }


        if (isValid) {
                if(payload.tournamentType==="Quick")
                    { 
                        if(payload.tournamentGameScore<0 || payload.tournamentGameScore>11){
                        NotificationManager.error(content.notification1,"Message",4000);
                        return null;
                        }
                    }
                    let res = checkTime(payload.tournamentStart);
                    if(res){
                      return null;
                     }
                    if(payload.tournamentEntryFee<0 ){
                        NotificationManager.error(content.notification2,"Message",4000)
                        return null;
                    }
                    
                    if(payload.tournamentPrizeMoney<0) {
                        NotificationManager.error(content.notification3,"Message",4000)
                        return null;
                    }
                    // if(payload.tournamentPrizeMoney<payload.tournamentEntryFee){
                    //     NotificationManager.error("Please enter greater PrizeMoney than tournamet fees ","Message",4000)
                    //     return null;  
                    // }
                    if(payload.tournamentType===""){
                        NotificationManager.error(content.notification4,"Message",5000);
                        return null;
                    }
            
            const response = await userInstance.post('/createTournamentUser', payload);
           setValues({...values,btnDisable:true});
            if (response.data.code == 200) {
               setValues({...values,
                    title: '',
                    tournamentStart: '',
                    playerNumbers: '',
                    tournamentType: '',
                    tournamentEntryFee: '',
                    tournamentPrizeMoney: '',
                    editid: '',
                    gameType:'',
                    update: false,
                    list: response.data.data
                });
               setValues({...values,btnDisable:false});
                //NotificationManager.success("Tournament created successfully!", "Message", 4000);
                const payload = {
                    tournamentId: response.data.id
                }
                console.log("tournamentID: ", response.data.id);
                const res = await userInstance.post('/joinTournament', payload);
                if(res.data.code === 200){
                    NotificationManager.success(content.notification5, "Message", 4000);
                }
            }else if(response.data.code === 400){
                localStorage.removeItem('token');
                localStorage.removeItem('Userid');
                localStorage.removeItem('nickName');
                window.location.href = '/login';

            }else if(res.data.code===401){
                NotificationManager.error(content.notification6,"Message",4000);
               setValues({...values,btnDisable:false});
            }
            else if(res.data.code===301){
                NotificationManager.error(content.notification7,"Message",4000);
               setValues({...values,btnDisable:false});
            }
            else if(res.data.code===302){
                NotificationManager.error(content.notification8,"Message",4000);
               setValues({...values,btnDisable:false});
            }
            else if(res.data.code===303){
                NotificationManager.error(content.notification9,"Message",4000);
               setValues({...values,btnDisable:false});
            }
            else if(res.data.code===304){
                NotificationManager.error(content.notification10,"Message",4000);
               setValues({...values,btnDisable:false});
            }
            else{
               NotificationManager.error(response.data.msg, "Message", 4000);
              setValues({...values,btnDisable:false});
            }
        } else {
           setValues({...values,btnDisable:false});
            NotificationManager.error(content.notification12, "Message", 4000);
        }
    }

    const handleDelete = async (id) => {
        const payload = {
            id: id
        }
        const response = await userInstance.post('/deleteTournamentUser', payload);
       setValues({...values,btnDisable:true});
        if (response.data.code == 200) {
           setValues({...values,
                list: response.data.data
            });
           setValues({...values,btnDisable:false});
        NotificationManager.success(content.notification13, "Message", 4000);
        } else if(response.data.code === 400){
            localStorage.removeItem('token');
                localStorage.removeItem('Userid');
                localStorage.removeItem('nickName');
            window.location.href = '/login';
        }else {
           setValues({...values,btnDisable:false});
            NotificationManager.error(content.notification14, "Message", 4000);
        }
    }

    const handleDetails = async(id) => {
        window.location.href = `/tournamentdetails?tour_id=${id}`;
    }

   const  handleUpdate = async (id) => {
       
        // const id = e.target.getAttribute('data-id');
        const payload = {
            id: id
        }
        const response = await userInstance.post('/getTournamentSingleUser', payload);
       setValues({...values,btnDisable:true});
        if (response.data.code == 200) {
           setValues({...values,
                title: response.data.data.title,
                tournamentStart: response.data.data.tournamentStart,
                playerNumbers: response.data.data.playerNumbers,
                tournamentType: response.data.data.tournamentType,
                tournamentEntryFee: response.data.data.tournamentEntryFee,
                tournamentPrizeMoney: response.data.data.tournamentPrizeMoney,
                gameType: response.data.data.gameType,
                editid: id,
                update: true,
                btnDisable:false
            });
        }else if(response.data.code === 400){
            localStorage.removeItem('token');
            localStorage.removeItem('Userid');
            localStorage.removeItem('nickName');
            window.location.href = '/login';
        } else {
           setValues({...values,btnDisable:false});
            NotificationManager.error(content.notification15, "Message", 4000);
        }
    }

   const  handleUpdateSingle = async () => {
        const payload = {
            _id: values.editid,
            title: values.title,
            tournamentStart: values.tournamentStart,
            playerNumbers: values.playerNumbers,
            tournamentType: values.tournamentType,
            tournamentEntryFee: values.tournamentEntryFee,
            tournamentPrizeMoney: values.tournamentPrizeMoney,
            gameType: values.gameType
        };

        const isValid = validateData([
            payload._id,
            payload.title,
            payload.tournamentStart,
            payload.playerNumbers,
            payload.tournamentType,
            payload.tournamentEntryFee,
            payload.tournamentPrizeMoney,
            payload.gameType
        ]);

        if (isValid) {
            const response = await userInstance.post('/updatettournamentUser', payload);
           setValues({...values,btnDisable:true});
            if (response.data.code == 200) {
               setValues({...values,
                    title: '',
                    tournamentStart: '',
                    playerNumbers: '',
                    tournamentType: '',
                    tournamentEntryFee: '',
                    tournamentPrizeMoney: '',
                    gameType: '',
                    editid: '',
                    update: false,
                    list: response.data.data,
                    btnDisable:false
                });
                NotificationManager.success(content.notification16, "Message", 4000);
            }
        else if(response.data.code == 400) {
            localStorage.removeItem('token');
            localStorage.removeItem('Userid');
            localStorage.removeItem('nickName');
            window.location.href = '/login';
        }
        else{
            NotificationManager.error(content.notification12, "Message", 4000);
           setValues({...values,btnDisable:false});
        }}
    }

   const handleCancel = () => {
       setValues({...values,
            title: '',
            tournamentStart: '',
            playerNumbers: '',
            tournamentType: '',
            tournamentEntryFee: '',
            tournamentPrizeMoney: '',
            gameType:'',
            editid: '',
            update: false
        });
    }

    const checkTime=(schedule)=>{
        
        if(new Date().getTime()+24*60*60>new Date(schedule).getTime()){
            NotificationManager.error(content.notification17,"Message",4000);
            return true;
        }
        else{
            return null
        }
   }

  
   
        return (
            <Layout navpanel={true}>

     
            <section className="tournament-page create-tounament">
                <div className="container">



                <div className="row">
                        <div className="col-md-12">
                            <div className="title-page">
                                <ul className="breadcrum">
                                    <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.createTournament}</li>
                                </ul>
                                <h2>{content.createTournament}</h2>
                            </div>
                        </div>
                    </div>

     
                              
                                
                                    <div className="create-tournament-box">
                                    <div className="row">
                                   
                                      <div className="col-md-6">
                                        <InputLabel
                                            label={content.title}
                                            id={`title-input-field`}
                                            required={true}
                                            type={`text`}
                                            placeholder={content.place1}
                                            name={"title"}
                                            value={values.title}
                                            handlechange={handleChange}
                                        />
                                         </div>

                                         <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="tournamentStart">{content.starttime}</label>
                                            <input
                                                type="datetime-local"
                                                id="tournamentStart"
                                                name="tournamentStart"
                                                className="form-control"
                                                value={values.tournamentStart}
                                                onChange={handleChange}
                                                min={new Date().toISOString().substring(0, 16)}
                                                // max={new Date().toISOString().substring(0, 16)}
                                            />
                                        </div>
                                        </div>
                                        <div className="col-md-3">  
                                        <div className="form-group">
                                        <label htmlFor="datetime">{content.selectGame}</label>
                                        <DropdownButton  title={values.gametypeDropdown}>
                                        <Dropdown.Item onClick={() => handledropdown('pong', 'game')}>{content.pong}</Dropdown.Item>
                                        <Dropdown.Item onClick ={() =>handledropdown('wintergame', 'game')}>{content.winter}</Dropdown.Item>
                                               </DropdownButton>
                                        </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="datetime">{content.playernum}</label>
                                            {/* <select name="playerNumbers"  className="form-control" onChange={handleChange} value={values.playerNumbers}>
                                                <option key="" value="" selected disabled>{content.select}</option>
                                                <option key="8" value="8">{content.pl8}</option>
                                                <option key="16" value="16">{content.pl16}</option>
                                                <option key="32" value="32">{content.pl32}</option>
                                            </select> */}
                                               <DropdownButton  title={values.playedropdown}>
                                        <Dropdown.Item onClick={() => handledropdown(8, 'player')}>8</Dropdown.Item>
                                        <Dropdown.Item onClick ={() =>handledropdown(16, 'player')}>16</Dropdown.Item>
                                        <Dropdown.Item onClick ={() =>handledropdown(32, 'player')}>32</Dropdown.Item>
                                               </DropdownButton>
                                        </div>
                                        </div>

                                        <div className="col-md-6">
                                        <div className="form-group">
                                        <label htmlFor="tournamentType">{content.type}</label>
                                            {/* <select name="tournamentType"  className="form-control" onChange={handleChange} value={values.tournamentType}>
                                                <option key="" value="" selected disabled>{content.select1}</option>
                                                <option key="quick" value="quick">{content.quick}</option>
                                                <option key="regular" value="regular">{content.regular}</option>
                                            </select> */}
                                            <DropdownButton  title={values.typeDropdown}>
                                        <Dropdown.Item onClick={() => handledropdown(content.quick, 'type')}>{content.quick}</Dropdown.Item>
                                        <Dropdown.Item onClick ={() =>handledropdown(content.regular, 'type')}>{content.regular}</Dropdown.Item>
                                        
                                               </DropdownButton>
                                        </div>
                                        </div>

                                        <div className="col-md-12">
                                        {values.tournamentType==="Quick" &&<> 
                                        {/* <label>TournamentGameScore</label>
                                        <input type="number" placeholder={`Please enter tournament Game Score`}
                                        value={values.tournamentGameScore}
                                        handlechange={handleChange}
                                        max={11}
                                        /> */}
                                        <InputLabel
                                        label={content.gamescore}
                                        id={`title-input-field`}
                                        required={true}
                                        type={`number`}
                                        placeholder={content.place2}
                                        name={"tournamentGameScore"}
                                        value={values.tournamentGameScore}
                                        handlechange={handleChange}
                                        />
                                         </>
                                        }

                                        </div>

                                        <div className="col-md-6">
                                        <InputLabel
                                            label={content.fee}
                                            id={`title-input-field`}
                                            required={true}
                                            type={`number`}
                                            placeholder={content.place3}
                                            name={"tournamentEntryFee"}
                                            value={values.tournamentEntryFee}
                                            handlechange={handleChange}
                                        />
                                        </div>

                                        <div className="col-md-6">
                                        <InputLabel
                                            label={content.prize}
                                            id={`title-input-field`}
                                            required={true}
                                            type={`number`}
                                            placeholder={content.place4}
                                            name={"tournamentPrizeMoney"}
                                            value={values.tournamentPrizeMoney}
                                            handlechange={handleChange}
                                        />
                                        </div>

                                        <div className="col-md-12">
                                        <div className="form-group mb-0 text-right">
                                            {!values.update ? (
                                                <Button
                                                    className="btn blue-btn"
                                                    onClick={createTournament}
                                                    disabled={values.btnDisable}
                                                >
                                                    {content.create}
                      </Button>
                                            ) : (
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            className="btn blue-btn"
                                                            onClick={handleUpdateSingle}
                                                            disabled={values.btnDisable}
                                                        >
                                                            {content.update}
                        </Button>
                                                        <Button
                                                            type="submit"
                                                            className="btn red-btn"
                                                            onClick={handleCancel}
                                                            disabled={values.btnDisable}
                                                        >
                                                            {content.cancel}
                        </Button>
                                </div>
                                    )}
                                    </div>

                                    </div>
                                    </div>
                                   
                                    </div>
                
                       
                        <div className="row">
                         <div className="col-md-12 ">
                          <div className="create-tournament-table">
                          {values.list && values.list.length>0? 

                                <TournamentList
                                   values = {values}
                                    content = {content}
                                    handleDelete={handleDelete}
                                    handleUpdate={handleUpdate}
                                    handleDetails= {handleDetails}
                                />
                                :<h3>{content.notour}</h3>}
                          </div>
                            </div>
                        </div>
                        </div>
                        </section>
                      
                    </Layout>
                    );
                
                }

                export default CreateTournament;