import React, { useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import { Badge } from 'react-bootstrap'
import './header.css'
import logo from '../../assets/images/header/logonew.png'
import { Link, NavLink } from 'react-router-dom'
import { getParamByParam } from 'iso-country-currency'
import money from '../../assets/images/header/add-money-icon.png'
import { isauth, convertToOtherCurrencies } from '../../function/function'
import { userInstance } from '../../axios/axiosconfig'
import { NotificationManager } from 'react-notifications'
import { Modal } from 'react-bootstrap'
import staricon from '../../assets/images/header/star-icon.png'
import { server } from '../../config/keys'
import { socket } from '../../config/socket'
import Login from '../login/login'
import Signup from '../signup/signup'
import { cmsInstance } from '../../axios/axiosconfig'
import InviteFriends from '../choooseduel/invitefriends'
import WithdrawMoney from '../withdrawMoney/withdrawMoney'
import Deposit from '../deposit/deposit'
import ChangePassword from '../changepassword/changepassword'
import { useTranslation } from 'react-i18next'
import RegistrationSuccess from '../registrationSuccess/registrationSuccess'
import friend from '../../assets/images/home/user-profile.png'

const Header = () => {
  const { t, i18n } = useTranslation()
  const changeLanguage = (event) => {
    localStorage.setItem('activeLanguage', event)
    i18n.changeLanguage(event)
    getContent()
  }
  const [content, setContent] = useState({})
  const [user, setUser] = useState([])
  const [pic, setPic] = useState(null)
  const [eligible, setEligible] = useState(false)
  const [deposit, setDeposit] = useState(false)
  const [bonus, setBonus] = useState(false)
  const [unread, setUnread] = useState(null)
  const [banned, setBanned] = useState(null)
  const [depositAmount, setDepositAmount] = useState(null)
  const [bonusAmt, setBonusAmt] = useState(null)
  const [showButton, setShowButton] = useState(false)
  const [language, setlanguages] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [currencyConvert, setCurrencyConvert] = useState(0)
  const [bonusConvert, setBonusConvert] = useState(0)
  let userLoggedIn = isauth()
  // const socket = io(server);
  const checkEligible = async () => {
    const res = await userInstance.get('/eligible')
    if (res.data.code === 200) {
      setEligible(true)
    } else if (res.data.code === 400) {
      localStorage.removeItem('token')
      localStorage.removeItem('Userid')
      localStorage.removeItem('nickName')
    }
  }
  const getUserProfile = async () => {
    const response = await userInstance.get('/getprofile')
    if (response.data.code === 200) {
      setPic(response.data.profile_data.photo)
      localStorage.setItem('photo', response.data.profile_data.photo)
      setShowButton(true)
      setUser(response.data.profile_data)
      setDepositAmount(response.data.profile_data.deposit[0].USD)
      setBonusAmt(response.data.profile_data.bonus[0].USD)
      setDeposit(response.data.profile_data.deposit[0].isActive)
      console.log(
        'response.data.profile_data.country',
        response.data.profile_data.country
      )
      const currencyCode = getParamByParam(
        'countryName',
        response.data.profile_data.country,
        'currency'
      )
      setCurrency(currencyCode)
      localStorage.setItem('currency', currencyCode);
      const { result, bonusResult } = await convertToOtherCurrencies(
        response.data.profile_data.deposit[0].USD,
        response.data.profile_data.bonus[0].USD,
        currencyCode
      )
      setCurrencyConvert(result)
      setBonusConvert(bonusResult)
      // setBonus(response.data.profile_data.bonus[0].isActive);
      // setBanned(response.data.profile_data.isbanned);
      localStorage.setItem('nickName', response.data.profile_data.firstName)
      localStorage.setItem('Userid', response.data.profile_data._id)
    } else if (response.data.code === 400) {
      localStorage.removeItem('token')
      localStorage.removeItem('Userid')
      localStorage.removeItem('nickName')
    }
  }
  useEffect(() => {
    checkEligible()
    getTotalLanguages()
    getContent()
    getUserProfile()
    getReadStatus()
    localStorage.setItem('lastlocation', window.location.pathname)
  }, [showButton])

  const getReadStatus = async () => {
    const res = await userInstance.post('/readStatusUser')
    if (res.data.code === 200) {
      setUnread(!res.data.data)
    } else if (res.data.code === 400) {
      setShowButton(false)
      localStorage.removeItem('token')
      localStorage.removeItem('Userid')
      localStorage.removeItem('nickName')
    }
  }
  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = '/header'
      let language = localStorage.getItem('activeLanguage')
        ? localStorage.getItem('activeLanguage')
        : localStorage.getItem('i18nextLng')
      const {
        data: { content, msg },
        status,
      } = await cmsInstance.get(`/content${page}/${language}`)
      if (status == 200) {
        setContent(content)
      } else {
        NotificationManager.error(msg, 'Message', 10000)
      }
    } catch (error) {
      console.log('Error', error.message)
    }
  }
  const getTotalLanguages = async () => {
    const res = await cmsInstance.get('/getlanguage')
    if (res.data.code === 200) {
      setlanguages(res.data.language)
      localStorage.setItem('activeLanguage', localStorage.getItem('i18nextLng'))
      console.log(res)
    }
  }

  useEffect(() => {
    socket.on('tournamentFeeDeducted', (data) => {
      console.log('=====>', data)
      setDepositAmount(data.USD)
    })
    socket.on('betAmountDeposit', (data) => {
      setDepositAmount(data.USD)
    })
    socket.on('betAmountBonus', (data) => {
      setBonusAmt(data.USD)
    })
  }, [depositAmount])

  let logout = async () => {
    const response = await userInstance.post('/logout')
    const statusCode = response.data.code
    const msg = response.data.msg
    if (statusCode == 200) {
      localStorage.removeItem('token')
      localStorage.removeItem('Userid')
      localStorage.removeItem('nickName')
      NotificationManager.success(content.notification3, 'Message', 4000)
      setTimeout(() => {
        window.location.href = `/`
      }, 1000)
    } else {
      NotificationManager.error(content.notification4, 'Message', 4000)
    }
  }

  let profile = async () => {
    window.location.href = `/profile`
  }

  let handlecheck = async (e) => {
    console.log('CLicked:', e)
    let payload
    if (e === 'deposit') {
      setDeposit(true)
      setBonus(false)
      payload = { deposit: true, bonus: false }
    } else {
      setBonus(true)
      setDeposit(false)
      payload = { deposit: false, bonus: true }
    }
    const res = await userInstance.post('/isActive', payload)
    if (res.data.code === 200) {
      NotificationManager.success(content.notification1, 'Message', 4000)
    } else {
      NotificationManager.error(content.notification2, 'Message', 4000)
    }
  }
  const [registrationSuccessShow, setRegistrationSuccessShow] = useState(false)
  const handleRegistrationSuccessShow = () => {
    setRegistrationSuccessShow(true)
  }
  const handleRegistrationSuccessClose = () => {
    setRegistrationSuccessShow(false)
  }
  const [loginshow, setLoginShow] = useState(false)
  const handleLoginShow = () => setLoginShow(true)
  const handleLoginClose = () => setLoginShow(false)
  const [signupshow, setSignupShow] = useState(false)
  const handleSignupShow = () => setSignupShow(true)
  const handleSignupClose = () => setSignupShow(false)
  const [inviteshow, setInviteShow] = useState(false)
  const handleInviteShow = () => setInviteShow(true)
  const handleInviteClose = () => setInviteShow(false)
  const [depositshow, setDepositShow] = useState(false)
  const handleDepositShow = () => setDepositShow(true)
  const handleDepositClose = () => setDepositShow(false)
  const [withdrawshow, setWithdrawShow] = useState(false)
  const handleWithdrawShow = () => setWithdrawShow(true)
  const handleWithdrawClose = () => setWithdrawShow(false)
  const [changepassshow, setChangepassShow] = useState(false)
  const handleChangepassShow = () => setChangepassShow(true)
  const handleChangepassClose = () => setChangepassShow(false)

  const SetBothSates = () => {
    handleSignupClose(false)
    handleRegistrationSuccessShow(true)
  }
  return (
    <header>
      <div className='header'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <Navbar collapseOnSelect expand='lg'>
                <Navbar.Brand href='/'>
                  <div className='logo'>
                    <img src={logo} alt='logo' />
                  </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>
                  <Nav>
                    <NavLink exact className='nav-link' to='/'>
                      {content.menu1}
                    </NavLink>
                    <NavLink className='nav-link' to='/populargames'>
                      {content.menu2}
                    </NavLink>
                    <NavLink className='nav-link' to='/howitworks'>
                      {content.menu3}
                    </NavLink>
                    <NavLink className='nav-link' to='/tournament'>
                      {content.menu4}
                    </NavLink>
                    <NavLink className='nav-link' to='/faqs'>
                      {content.menu5}
                    </NavLink>
                    {/* <NavLink className="nav-link" to="/features">{content.menu6")}</NavLink> */}
                  </Nav>
                </Navbar.Collapse>
                {!showButton && (
                  <div className='head-button'>
                    <Link to='' className='login-btn' onClick={handleLoginShow}>
                      <i className='fa fa-user'></i> {content.login}
                    </Link>
                    <Link to='' className='signup-btn' onClick={handleSignupShow}>
                      <i className='fa fa-user'></i> {content.signup}
                    </Link>
                  </div>
                )}
                {user && user.length !== 0 && (
                  <div className='head-user-info'>
                    <div className='my-profile-menu'>
                      <div className='p-pic'>
                        <img
                          src={`${server}/` + pic}
                          alt={localStorage.getItem('nickName') + '_pic'}
                          className='profile-image'
                        />

                        {eligible ? (
                          <img src={staricon} alt='' className='star-icon' />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='p-name'>
                        <h5>{user.firstName}</h5>
                        {banned ? (
                          <>
                            <Badge className='ac-banned' variant='danger'>
                              <span className='text-light'>
                                {content.banner}
                              </span>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant='default'
                                  id='dropdown-basic'
                                ></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Link
                                    className='logout-btn dropdown-item'
                                    onClick={() =>
                                      (window.location.href =
                                        '/notificationPage')
                                    }
                                  >
                                    {content.link1}
                                  </Link>
                                  <Link
                                    className='logout-btn dropdown-item'
                                    onClick={() => (window.location.href = '/')}
                                  >
                                    {content.link2}
                                  </Link>
                                  <Link
                                    className='logout-btn dropdown-item'
                                    onClick={() => (window.location.href = '/')}
                                  >
                                    {content.link3}
                                  </Link>
                                  <Link
                                    className='logout-btn dropdown-item'
                                    onClick={logout}
                                  >
                                    {content.link4}
                                  </Link>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Badge>
                          </>
                        ) : (
                          <>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='default'
                                id='dropdown-basic'
                              ></Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={profile}>
                                  {content.link5}
                                </Dropdown.Item>
                                <Link
                                to=''
                                  className='logout-btn dropdown-item'
                                  onClick={() =>
                                    (window.location.href = '/transactions')
                                  }
                                >
                                  {content.link6}
                                </Link>

                                <Link
                                to=''
                                  className='logout-btn dropdown-item'
                                  onClick={() =>
                                    (window.location.href = '/notificationPage')
                                  }
                                >
                                  {content.link1}
                                </Link>

                                {eligible ? (
                                  <Link to=''
                                    className='logout-btn dropdown-item'
                                    onClick={() =>
                                      (window.location.href =
                                        '/createtournament')
                                    }
                                  >
                                    {content.link7}
                                  </Link>
                                ) : (
                                  ''
                                )}
                                <Link to=''
                                  className='logout-btn dropdown-item'
                                  onClick={() =>
                                    (window.location.href = '/kyc')
                                  }
                                >
                                  {content.kyc}
                                </Link>

                                <Link to=''
                                  className='logout-btn dropdown-item'
                                  onClick={handleInviteShow}
                                >
                                  {content.link8}
                                </Link>
                                {/* <Link 
                                      className="logout-btn dropdown-item" 
                                      onClick={() => window.location.href = "/admin-chat"}
                                      >
                                  {content.link3")}
                                  </Link> */}
                                <Link to=''
                                  className='logout-btn dropdown-item'
                                  onClick={logout}
                                >
                                  {content.link4}
                                </Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        )}
                      </div>
                    </div>

                    <Badge
                      className='ac-notification'
                      variant='primary'
                      onClick={() =>
                        (window.location.href = '/notificationPage')
                      }
                    >
                      <i className='fa fa-bell'></i>
                      {unread && (
                        <>
                          <Badge variant='info'>{unread}</Badge>
                          <span className='sr-only'>{content.message1}</span>
                        </>
                      )}
                    </Badge>

                    <div className='wallet-container'>
                      <h4>{content.message2}</h4>

                      <Dropdown id='dropdown-wallet'>
                        <Dropdown.Toggle
                          variant='default'
                          id='dropdown-basic-wallet'
                        >
                          <div className='wallet-box'>
                            {deposit ? (
                              <div className='money-wallet'>
                                <h5>{content.message3}</h5>
                                <div className='money-wallet-box'>
                                  {depositAmount ? (
                                    <span>
                                      {depositAmount.toFixed(2)} <i>USD</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00 <i>USD</i>
                                    </span>
                                  )}
                                </div>
                                <div className='money-wallet-box'>
                                  {depositAmount ? (
                                    <span>
                                      {currencyConvert.toFixed(2)}{' '}
                                      <i>{currency}</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00 <i>{currency}</i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className='money-wallet'>
                                <h5>{content.bonus}</h5>
                                <div className='money-wallet-box'>
                                  {bonusAmt ? (
                                    <span>
                                      {bonusAmt.toFixed(2)} <i>USD</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00 <i>USD</i>
                                    </span>
                                  )}
                                </div>
                                <div className='money-wallet-box'>
                                  {bonusAmt ? (
                                    <span>
                                      {bonusConvert.toFixed(2)}{' '}
                                      <i>{currency}</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00 <i>{currency}</i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={handlecheck.bind(this, 'deposit')}
                          >
                            <div className='wallet-box'>
                              <h5>{content.message3}</h5>
                              <div className='money-wallet'>
                                <div className='money-wallet-box'>
                                  {depositAmount ? (
                                    <span>
                                      {depositAmount.toFixed(2)} <i>USD</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00 <i>USD</i>
                                    </span>
                                  )}
                                </div>
                                <div className='money-wallet-box'>
                                  {depositAmount ? (
                                    <span>
                                      {currencyConvert.toFixed(2)}{' '}
                                      <i>{currency}</i>
                                    </span>
                                  ) : (
                                    <span>
                                      0.00 <i>{currency}</i>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={handlecheck.bind(this, 'bonus')}
                          >
                            <div className='wallet-box'>
                              <h5>{content.bonus}</h5>
                              <div className='money-wallet-box'>
                                {bonusAmt ? (
                                  <span>
                                    {bonusAmt.toFixed(2)} <i>USD</i>
                                  </span>
                                ) : (
                                  <span>
                                    0.00 <i>USD</i>
                                  </span>
                                )}
                              </div>
                              <div className='money-wallet-box'>
                                {bonusAmt ? (
                                  <span>
                                    {bonusConvert.toFixed(2)} <i>{currency}</i>
                                  </span>
                                ) : (
                                  <span>
                                    0.00 <i>{currency}</i>
                                  </span>
                                )}
                              </div>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className='add-money'>
                      <Link to='/friend'>
                        <img src={friend} alt='' />
                      </Link>
                    </div>
                    <div className='add-money'>
                      <img src={money} alt='' onClick={handleDepositShow} />
                    </div>
                  </div>
                )}

                <div className='language-dropdown'>
                  <Dropdown id='dropdown-language'>
                    <Dropdown.Toggle variant='default' id='dropdown-basic'>
                      {localStorage.getItem('i18nextLng')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {language &&
                        language.map((el) => {
                          return [
                            <Dropdown.Item
                              onClick={changeLanguage.bind(this, el)}
                            >
                              {el.toUpperCase()}
                            </Dropdown.Item>,
                          ]
                        })}
                      {/* <Dropdown.Item onClick={changeLanguage.bind(this, "en")}>EN</Dropdown.Item>
                        <Dropdown.Item onClick={changeLanguage.bind(this, "pl")}>PL</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Navbar>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName='dod-popup'
        show={loginshow}
        onHide={handleLoginClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Login />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName='dod-popup'
        show={signupshow}
        onHide={handleSignupClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Signup SetBothSates={SetBothSates} />
        </Modal.Body>
      </Modal>
      <Modal
        dialogClassName='dod-popup'
        show={registrationSuccessShow}
        onHide={handleRegistrationSuccessClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <RegistrationSuccess
            handleRegistrationSuccessClose={handleRegistrationSuccessClose}
          />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName='dod-popup'
        show={inviteshow}
        onHide={handleInviteClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <InviteFriends />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName='dod-popup'
        show={depositshow}
        onHide={handleDepositClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Deposit currency = {currency}/>
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName='dod-popup'
        show={withdrawshow}
        onHide={handleWithdrawClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <WithdrawMoney />
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName='dod-popup'
        show={changepassshow}
        onHide={handleChangepassClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ChangePassword />
        </Modal.Body>
      </Modal>
    </header>
  )
}

export default Header
