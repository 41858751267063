import React from "react";
import AdminLayout from "../layout";
import SectionHeader from "../section-header";
import {
  InputLabel,
  TextareaLabel
} from "../input-label";
import CommissionList from "./components/commission-list";
import "./style.css";
import { validateData } from '../../../function/function';
import { adminInstance } from "../../../axios/axiosconfig";
import { NotificationManager } from "react-notifications";
export default class Turnover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{ id: `hjh` }],
      subtitle: "",
      content: "",
      editid: "",
      update: false,
      editorHtml: "",
      theme: "snow",
      placeholder: "Write something....",
      Hashtags: "",
      turnoverAmount: '',
      bonusAmtTOLimit: '',
      bonusPercentFirstTransaction: ''
    };
  }

  componentDidMount = () => {
    this.getTurnover();
  }

  getTurnover = async (req, res) => {
    let filterBy = '';
    let payload = {
      data: filterBy
    }
    const response = await adminInstance.post('/getTurnOver', payload);
    console.log('response',response);
    if (response.data.code == 200) {
      const amount = response.data.data.turnoverlimit;
      this.setState({
        turnoverAmount: amount,
        bonusPercentFirstTransaction : response.data.data.bonusPercentFirstTransaction,
        bonusAmtTOLimit : response.data.data.bonusAmtTOLimit
      });
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  createTurnover = async (e) => {
    const payload = {
      turnoverAmount: this.state.turnoverAmount
    };
    if(this.state.turnoverAmount<0){
      NotificationManager.error("Please enter valid entery","Message",4000);
      return null;
    }
    const isValid = validateData([
      payload.turnoverAmount
    ]); 

    if (isValid) {
      const response = await adminInstance.post('/setTurnover', payload);
      
      if (response.data.code == 200) {
            this.setState({
          turnoverAmount:response.data.amount
        });
        NotificationManager.success("Turnover limit Updated!", "Message", 4000);
      }
    } else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
  }

  handleBonusLimit = async (e) => {
    const payload = {
      bonusAmtTOLimit: this.state.bonusAmtTOLimit
    };
    if(this.state.bonusAmtTOLimit<0){
      NotificationManager.error("Please enter valid entery","Message",4000);
      return null;
    }
    const isValid = validateData([
      payload.bonusAmtTOLimit
    ]); 

    if (isValid) {
      const response = await adminInstance.post('/setBonusAmtTOLimit', payload);
      
      if (response.data.code == 200) {
            this.setState({
              bonusAmtTOLimit:response.data.bonusAmtTOLimit
        });
        NotificationManager.success("Bonus Amount limit Updated!", "Message", 4000);
      }
    } else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
  }

  handleFirstTransactionPercentage = async (e) => {
    const payload = {
      bonusPercentFirstTransaction: this.state.bonusPercentFirstTransaction
    };
    if(this.state.bonusPercentFirstTransaction<0){
      NotificationManager.error("Please enter valid entery","Message",4000);
      return null;
    }
    const isValid = validateData([
      payload.bonusPercentFirstTransaction
    ]); 

    if (isValid) {
      const response = await adminInstance.post('/setFirstTransactionPercentage', payload);
      
      if (response.data.code == 200) {
            this.setState({
              bonusPercentFirstTransaction:response.data.bonusPercentFirstTransaction
        });
        NotificationManager.success("Bonus % of First Transaction  Updated!", "Message", 4000);
      }
    } else {
      NotificationManager.error("Some fields are empty", "Message", 4000);
    }
  }

  render() {
    return (
      <AdminLayout navpanel={true}>
        <div className="admindashboard col-lg-12">
          <div className="row">
            <div className="col-md-6">
              <div className="card__content">
                <SectionHeader title="Turnover limit" />
                <div className="card">
                  <div className="card__content">
                    <InputLabel
                      label={`Turn-over Limit Amount`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please enter Turnover limit `}
                      name={"turnoverAmount"}
                      value={this.state.turnoverAmount}
                      handlechange={this.handleChange}
                    />
                    <div className="form-group mb-0 text-right">
                      <button className="btn btn-primary-inverse" onClick={this.createTurnover}>
                        Update
                      </button>
                    </div>
                    <p style={{color:"white", marginTop:"10px"}}> Turnover Amount: {this.state.turnoverAmount} USD</p>
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card__content">
                <SectionHeader title="Bonus Amount when Player Cross Turnover limit" />
                <div className="card">
                  <div className="card__content">
                    <InputLabel
                      label={`Bonus Amount`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please enter Bonus Amount `}
                      name={"bonusAmtTOLimit"}
                      value={this.state.bonusAmtTOLimit}
                      handlechange={this.handleChange}
                    />
                    <div className="form-group mb-0 text-right">
                      <button className="btn btn-primary-inverse" onClick={this.handleBonusLimit}>
                        Update
                      </button>
                    </div>
                    <p style={{color:"white", marginTop:"10px"}}> Player will get  {this.state.bonusAmtTOLimit} USD when cross monthly turnover limit</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
          <div className="col-md-6">
              <div className="card__content">
                <SectionHeader title="First Transaction Bonus Percentage" />
                <div className="card">
                  <div className="card__content">
                    <InputLabel
                      label={`Bonus Percentage`}
                      id={`title-input-field`}
                      required={true}
                      type={`number`}
                      placeholder={`Please enter Bonus Percentage`}
                      name={"bonusPercentFirstTransaction"}
                      value={this.state.bonusPercentFirstTransaction}
                      handlechange={this.handleChange}
                    />
                    <div className="form-group mb-0 text-right">
                      <button className="btn btn-primary-inverse" onClick={this.handleFirstTransactionPercentage}>
                        Update
                      </button>
                    </div>
                    <p style={{color:"white", marginTop:"10px"}}> {this.state.bonusPercentFirstTransaction} % of First Deposit Transaction will be added in Player's Bonus Wallet
                    </p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
