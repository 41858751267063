import React, { useState, useEffect } from "react";
import "./aboutus.css";
import Layout from '../layout'
import about from "../../assets/images/home/about.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cmsInstance } from '../../axios/axiosconfig';
import { NotificationManager } from 'react-notifications';
 

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    const [content, setContent] = useState({});

    const getContent = async () => {
        try {
            const page = window.location.pathname.replace('/cms/', '');
            let language = i18n.language;
            console.log('page', page);
            console.log('language', language);
            const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
            if (status == 200) {
                setContent(content);
            } else {
                NotificationManager.error(msg, "Message", 10000);
            }
        } catch (error) {
            console.log('Error', error.message);
        }
    }

    useEffect(() => {
        getContent();
        i18n.on('languageChanged', onLanguageChanged);
    }, []);

    const onLanguageChanged = () => {
        getContent();
    }

    return (

        <Layout>

            <div className="aboutus">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-page">
                                <ul className="breadcrum">
                                    <li><Link to='/'>{content.home}</Link></li>
                                    <li>{content.aboutus}</li>
                                </ul>
                                <h2>{content.aboutus}</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-6">
                            <div className="aboutus-descp">
                                <h4>{content.subheading}</h4>
                                <p>{content.aboutheading}</p>
                            </div>
                            <div className="about-list">
                                <ul className="about-f">
                                    <li>{content.stringcreate}</li>
                                    <li>{content.stringdescription}</li>
                                </ul>
                            </div>

                        </div>

                        <div className="col-md-6">
                            <img className="about-image" src={about} alt="aboutprofile" />
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AboutUs;