import React, { useEffect,useState } from "react";
import "./notificationpage.css";
import Layout from "../layout";
import Table from "react-bootstrap/Table";
import { userInstance } from "../../axios/axiosconfig";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {cmsInstance} from '../../axios/axiosconfig';
import { NotificationManager } from "react-notifications";


const Notificationpage = () => {
  const { t, i18n } = useTranslation();
  const [notificationMsg, setNotificationMsg] = useState(null);
  const [content, setContent] = useState({});
  useEffect(()=>{
    const getNotificationMsg = async()=>{
        const res = await userInstance.post('/getNotification');
        if(res.data.code===200){
          console.log("res",res);
          setNotificationMsg(res.data.notification);
        }
    }
    getNotificationMsg();
    const msgRead = async()=>{
      const res = await userInstance.post('/readStatusUser');
      if(res.data.code===200){
        console.log("res",res);
      }
    }
    setTimeout(()=>{
      msgRead();
    },5000);
    getContent();
    i18n.on('languageChanged', onLanguageChanged);  
  },[])

  const onLanguageChanged = () => {
    console.log("language change",localStorage.getItem('activeLanguage'))
    getContent();
   }

  const getContent = async () => {
    try {
      // const page = window.location.pathname.replace('/cms/', '');
      const page = '/notification';
       let language = localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') : localStorage.getItem('i18nextLng');
      const { data: { content, msg }, status } = await cmsInstance.get(`/content${page}/${language}`);
      if (status == 200) {
        setContent(content);
        
      } else {
        NotificationManager.error(msg, "Message", 10000);
      }
    } catch (error) {
      console.log('Error', error.message);
    }
  }
  return (
    <Layout>

      <div className="notificationpage">
        <div className="container">




        <div className="row">
              <div className="col-md-12">
                  <div className="title-page">
                      <ul className="breadcrum">
                          <li><Link to='/'>{content.home}</Link></li>
                          <li>{content.notification}</li>
                      </ul>
                      <h2>{content.notification}</h2>
                     
                    </div>
              </div>
          </div>




          <div className="row">
            <div className="col-md-12">

            <div className="home-table">

              <div className="responsive-table">
                  {notificationMsg &&notificationMsg?  <Table bordered>
                      <thead>
                        <tr>
                          <th>{content.message} </th>
                          <th>{content.time}</th>
                          <th>{content.date}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notificationMsg && notificationMsg.map(ele=>
                          <tr key={ele._id}>
                            <td className="text-truncate">{ele.msg}</td>
                        <td>{ele.createdAt.substring(11,16)}</td>
                        <td>{ele.createdAt.substring(0,10)}</td>
                          </tr>
                          )}
                        
                      </tbody>
                    </Table>:
                    <h3>{content.nonotification}</h3>}
                  </div>
                </div>
                </div>
          </div>



        </div>
     </div>
    </Layout>
  );
};

export default Notificationpage;
